<template>
	<div class="download">
		<div class="flex alce">
			<div class="inline-block mr10 back" @click="$router.back()">
				<i class="el-icon-back"></i>{{$t('Back')}}
			</div>
			<h1 class="title">{{agreementInfo.title}}</h1>
		</div>
		<el-divider class="bg-black"></el-divider>
		<div class="agreement-box">
			<!-- <div class="greement-title"><h2 class="ftitle">“智慧云广播”客户端软件许可及服务协议</h2></div> -->
			<div class="agreement-text">
				<HelpCom :id="agreementInfo.id" :showHeader="false" v-if="agreementInfo.id"></HelpCom>
			</div>
		</div>
	</div>
</template>

<script>
	import { typeList } from '@/request/api/homecontent'
	import HelpCom from '@/views/platform/components/HelpCom/HelpCom'
	export default {
		name: "Agreement",
		components: {
			HelpCom,
		},
		data() {
		  return {
			agreementInfo: {},
		  }
		},
		methods: {
			async getHomeContentList(pageNum) {
			  try {
			    const { code, data } = await typeList({type: '3'})
				if(code == 200){
					if(data && data.length){
						this.agreementInfo = data[0]
					}
				}
			  } catch (error) {
			    console.log('error', error)
			  }
			},
			init() {
			  this.getHomeContentList(1)
			}
		},
		mounted() {
		  this.init()
		},
	}
</script>

<style lang="less" scoped>
	.download {
		.back {
			cursor: pointer;
		}

		margin: 30px 120px 0 120px;

		.title {
			padding: 5px 0;
			color: #02a7f0;
			font-size: 23px;
		}
	}
	.greement-title{
		.ftitle{
			text-align: center;
			font-size: 30px;
			color: #333;
		}
	}
	.ptime{
		text-align: right;
	}
	.agreement-box{
		margin: 0 90px;
	}
	.agreement-text{
		font-size: 16px;
		color: #333;
		line-height: 1.8;
		padding-bottom: 30px;
		.bold{
			font-weight: bold;
		}
		/deep/ .help-box{
			width: 100%;
		}
	}
</style>
