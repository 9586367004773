import { secondaryPackagingRequest } from '@/request'
//获取腾讯云临时秘钥
export const cosTempToken = params => secondaryPackagingRequest({
    url: '/sys/file/cosTempToken',
    params,
    isLoading: false,
    needToken: true
})
//获取文件存储配置信息
export const fileStorageInfo = params => secondaryPackagingRequest({
    url: '/sys/fileStorage/info',
    params,
    isLoading: false,
    needToken: true
})
//保存文件存储配置信息
export const fileStorageSave = data => secondaryPackagingRequest({
    url: '/sys/fileStorage/save',
    method: 'POST',
    headers: {
        'Content-Type': 'application/json'
    },
    data,
    isLoading: false,
    needToken: true
})