//ai预警模块
import Content from '@/components/Content/Content'
export default [
    {
        name: 'ai',
        path: '/ai',
        component: Content,
        redirect: '/ai/device',
        meta: {
            title: 'aiWarning'
        },
        children: [
            // 监控设备
            {
                path: 'device',
				name: 'device',
                component: () => import(/*webpackChunkName: 'devicedemand'*/'@/views/ai/Device'),
                meta: {
                    title: 'aiDevice',
					showBread: true,
					showNav: true,
					showLeftMenu: true,
					module: 'ai',
					needKeep: true,
                },
            },
			// 分屏预览
			{
			    path: 'screenview',
			    component: () => import(/*webpackChunkName: 'devicedemand'*/'@/views/ai/ScreenView'),
			    meta: {
			        title: 'screenView',
					showBread: true,
					showNav: false,
					showLeftMenu: false,
					module: 'ai',
					isLargeFullScreen: true,
			    },
			},
			// 电子地图
			{
			    path: 'gismap',
			    component: () => import(/*webpackChunkName: 'devicedemand'*/'@/views/ai/GisMap'),
			    meta: {
			        title: 'aiMap',
					showBread: true,
					showNav: true,
					showLeftMenu: true,
					module: 'ai'
			    },
			},
			// 报警记录
			{
			    path: 'alarmrecord',
			    component: () => import(/*webpackChunkName: 'devicedemand'*/'@/views/ai/AlarmRecord'),
			    meta: {
			        title: 'aiAlarm',
					showBread: true,
					showNav: true,
					showLeftMenu: true,
					module: 'ai'
			    },
			},
			// 报警记录
			{
			    path: 'broadcastlinkage',
			    component: () => import(/*webpackChunkName: 'devicedemand'*/'@/views/ai/broadcastlinkage'),
			    meta: {
			        title: 'broadcastlinkage',
					showBread: true,
					showNav: true,
					showLeftMenu: true,
					module: 'ai'
			    },
			},
        ]
    }
]