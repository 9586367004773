import axios from 'axios'
import { cancelBus } from '@/configs'
import { secondaryPackagingRequest } from '@/request'

//获取应用类型
export const applicationTypeList = () => secondaryPackagingRequest({
    url: '/sys/application/typeList',
    isLoading: false,
    needToken: true
})
//获取应用程序列表
export const applicationList = params => secondaryPackagingRequest({
    url: '/sys/application/list',
    params,
    isLoading: false,
    needToken: true
})
//新增应用类型
export const applicationTypeSave = data => secondaryPackagingRequest({
    url: '/sys/application/saveType',
    method: 'POST',
    headers: {
        'Content-Type': 'application/json'
    },
    data,
    isLoading: false,
    needToken: true,
})
//删除应用类型
export const deleteType = params => secondaryPackagingRequest({
    url: '/sys/application/deleteType',
    method: 'POST',
    headers: {
        'Content-Type': 'application/json'
    },
    params,
    isLoading: false,
    needToken: true,
})
//删除应用程序
export const deleteApp = params => secondaryPackagingRequest({
    url: '/sys/application/delete',
    method: 'POST',
    headers: {
        'Content-Type': 'application/json'
    },
    params,
    isLoading: false,
    needToken: true,
})
//应用程序上传
export const applicationSave = (data, params, onUploadProgress) => secondaryPackagingRequest({
    url: '/sys/application/save',
    method: 'POST',
    headers: {
        'Content-Type': 'application/json'
    },
    data,
	params,
    timeout: 200000,
    isLoading: false,
    needToken: true,
    onUploadProgress,
})
//根据应用类型分组获取最新的应用包
export const selectLatestAppGrouping = params => secondaryPackagingRequest({
    url: '/common/selectLatestAppGrouping',
    params,
    isLoading: false,
    needToken: false
})
//根据类型关键字获取最新的应用包
export const selectLatestApp = params => secondaryPackagingRequest({
    url: '/common/selectLatestApp',
    params,
    isLoading: false,
    needToken: false
})