import axios from 'axios'
import { secondaryPackagingRequest } from '@/request'
//获取文案列表
export const homeContentList = params => secondaryPackagingRequest({
    url: '/sys/homeContent/list',
    params,
    isLoading: false,
    needToken: true
})
//根据类型获取文案列表
export const typeList = params => secondaryPackagingRequest({
    url: '/sys/homeContent/type/list',
    params,
    isLoading: false,
    needToken: true
})
//新增文案
export const homeContentSave = data => secondaryPackagingRequest({
    url: '/sys/homeContent/save',
    method: 'POST',
    headers: {
        'Content-Type': 'application/json'
    },
    data,
    isLoading: false,
    needToken: true
})
//更新文案
export const homeContentUpdate = data => secondaryPackagingRequest({
    url: '/sys/homeContent/update',
    method: 'POST',
    headers: {
        'Content-Type': 'application/json'
    },
    data,
    isLoading: false,
    needToken: true
})
//删除文案
export const homeContentDelete = params => secondaryPackagingRequest({
    url: '/sys/homeContent/delete',
    method: 'POST',
    headers: {
        'Content-Type': 'application/json'
    },
    params,
    isLoading: false,
    needToken: true
})
//获取详情
export const homeContentUnfo = params => secondaryPackagingRequest({
    url: '/sys/homeContent/query/info',
    params,
    isLoading: false,
    needToken: true
})