// 首页路由模块
import Content from '@/components/Content/Content'
import i18n from '@/i18n'
export default [
    {
        path: '/home',
        component: Content,
        redirect: '/home/audiohome',
        children: [
            {
                name: 'audiohome',
                path: 'audiohome',
                component: () => import(/*webpackChunkName: 'audiohome'*/'@/views/home/AudioHome'),
                meta: {
                    title: 'home',
                    // 不显示面包屑
                    showBread: false,
                    showNav: true,
                    showLeftMenu: true,
                    module: 'home'
                }
            }
        ]
    }
]