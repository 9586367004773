// 平台管理路由模块
import Content from '@/components/Content/Content'
export default [
    {
        name: 'platformManagement',
        path: '/platform',
        component: Content,
        redirect: '/platform/accountmanagement/personalsettings',
        meta: {
            title: 'platformManagement'
        },
        children: [
            // 账户管理
            {
                name: 'accountManagement',
                path: 'accountmanagement',
                component: Content,
                redirect: '/platform/accountmanagement/personalsettings',
                meta: {
                    title: 'accountManagement',
                },
                children: [
                    {
                        name: 'personalSettings',
                        path: 'personalsettings',
                        component: () => import(/*webpackChunkName: 'personalsettings'*/'@/views/platform/AccountManagement/PersonalSettings'),
                        meta: {
                            title: 'personalSettings',
                            showBread: true,
                            showNav: true,
                            showLeftMenu: true,
                            module: 'platform'
                        }
                    },
                    {
                        name: 'userGroupManagement',
                        path: 'usergroup',
                        component: () => import(/*webpackChunkName: 'usergroup'*/'@/views/platform/AccountManagement/UserGroup'),
                        meta: {
                            title: 'userGroupManagement',
                            showBread: true,
                            showNav: true,
                            showLeftMenu: true,
                            module: 'platform'
                        }
                    },
                    {
                        name: 'userManagement',
                        path: 'user',
                        component: () => import(/*webpackChunkName: 'user'*/'@/views/platform/AccountManagement/User'),
                        meta: {
                            title: 'userManagement',
                            showBread: true,
                            showNav: true,
                            showLeftMenu: true,
                            module: 'platform'
                        }
                    },
                    {
                        name: 'orderlist',
                        path: 'orderlist',
                        component: () => import(/*webpackChunkName: 'user'*/'@/views/platform/AccountManagement/OrderList'),
                        meta: {
                            title: 'orderlist',
                            showBread: true,
                            showNav: true,
                            showLeftMenu: true,
                            module: 'platform'
                        }
                    },
                ]
            },

            //运维管理
            {
                name: 'mochaITOM',
                path: 'mochaitom',
                component: Content,
                redirect: '/platform/mochaitom/databasebackup',
                meta: {
                    title: 'mochaITOM',
                },
                children: [
                    {
                        name: 'dataBaseBackup',
                        path: 'databasebackup',
                        component: () => import(/*webpackChunkName: 'databasebackup'*/'@/views/platform/MochaITOM/DatabaseBackup'),
                        meta: {
                            title: 'dataBaseBackup',
                            showBread: true,
                            showNav: true,
                            showLeftMenu: true,
                            module: 'platform'
                        }
                    },
                    {
                        name: 'mediaServiceManagement',
                        path: 'mediamanagement',
                        component: () => import(/*webpackChunkName: 'mediamanagement'*/'@/views/platform/MochaITOM/MediaManagement'),
                        meta: {
                            title: 'mediaServiceManagement',
                            showBread: true,
                            showNav: true,
                            showLeftMenu: true,
                            module: 'platform'
                        }
                    },
                    {
                        name: 'onlineService',
                        path: 'onlineservice',
                        component: () => import(/*webpackChunkName: 'onlineservice'*/'@/views/platform/MochaITOM/OnlineService'),
                        meta: {
                            title: 'onlineService',
                            showBread: true,
                            showNav: true,
                            showLeftMenu: true,
                            module: 'platform'
                        }
                    },
                    {
                        name: 'operationMonitoring',
                        path: 'operationmonitoring',
                        component: () => import(/*webpackChunkName: 'operationmonitoring'*/'@/views/platform/MochaITOM/OperationMonitoring'),
                        meta: {
                            title: 'operationMonitoring',
                            showBread: true,
                            showNav: true,
                            showLeftMenu: true,
                            module: 'platform'
                        }
                    },
                    {
                        name: 'systemLog',
                        path: 'systemlog',
                        component: () => import(/*webpackChunkName: 'systemlog'*/'@/views/platform/MochaITOM/SystemLog'),
                        meta: {
                            title: 'systemLog',
                            showBread: true,
                            showNav: true,
                            showLeftMenu: true,
                            module: 'platform'
                        }
                    },
                    {
                        name: 'systemMessageRelease',
                        path: 'systemmessage',
                        component: () => import(/*webpackChunkName: 'systemmessage'*/'@/views/platform/MochaITOM/SystemMessage'),
                        meta: {
                            title: 'systemMessageRelease',
                            showBread: true,
                            showNav: true,
                            showLeftMenu: true,
                            module: 'platform'
                        }
                    },
                    {
                        name: 'filestorage',
                        path: 'filestorage',
                        component: () => import(/*webpackChunkName: 'systemmessage'*/'@/views/platform/MochaITOM/FileStorage'),
                        meta: {
                            title: 'filestorage',
                            showBread: true,
                            showNav: true,
                            showLeftMenu: true,
                            module: 'platform'
                        }
                    },
                    {
                        name: 'servicerenewal',
                        path: 'servicerenewal',
                        component: () => import(/*webpackChunkName: 'systemmessage'*/'@/views/platform/MochaITOM/ServeContinue'),
                        meta: {
                            title: 'servicerenewal',
                            showBread: true,
                            showNav: true,
                            showLeftMenu: true,
                            module: 'platform'
                        }
                    },
                    {
                        name: 'billingmode',
                        path: 'billingmode',
                        component: () => import(/*webpackChunkName: 'user'*/'@/views/platform/MochaITOM/BillingMode'),
                        meta: {
                            title: 'billingmode',
                            showBread: true,
                            showNav: true,
                            showLeftMenu: true,
                            module: 'platform'
                        }
                    },
                    {
                        name: 'ordermanagement',
                        path: 'ordermanagement',
                        component: () => import(/*webpackChunkName: 'user'*/'@/views/platform/MochaITOM/OrderManagement'),
                        meta: {
                            title: 'ordermanagement',
                            showBread: true,
                            showNav: true,
                            showLeftMenu: true,
                            module: 'platform'
                        }
                    },
                    {
                        name: 'invoicingmanagement',
                        path: 'invoicingmanagement',
                        component: () => import(/*webpackChunkName: 'user'*/'@/views/platform/MochaITOM/InvoicingManagement.vue'),
                        meta: {
                            title: 'invoicingmanagement',
                            showBread: true,
                            showNav: true,
                            showLeftMenu: true,
                            module: 'platform'
                        }
                    },
                    {
                        name: 'smsservice',
                        path: 'smsservice',
                        component: () => import(/*webpackChunkName: 'user'*/'@/views/platform/MochaITOM/SmsService.vue'),
                        meta: {
                            title: 'smsservice',
                            showBread: true,
                            showNav: true,
                            showLeftMenu: true,
                            module: 'platform'
                        }
                    },
                ]
            },

            // 系统管理
            {
                name: 'systemManagement',
                path: 'systemmanagement',
                component: Content,
                redirect: '/platform/systemmanagement/aboutplatform',
                meta: {
                    title: 'systemManagement',
                },
                children: [
                    {
                        name: 'aboutPlatform',
                        path: 'aboutplatform',
                        component: () => import(/*webpackChunkName: 'aboutplatform'*/'@/views/platform/SystemManagement/AboutPlatform'),
                        meta: {
                            title: 'aboutPlatform',
                            showBread: true,
                            showNav: true,
                            showLeftMenu: true,
                            module: 'platform'
                        }
                    },
                    {
                        name: 'firmwareManagement',
                        path: 'firmware',
                        component: () => import(/*webpackChunkName: 'firmware'*/'@/views/platform/SystemManagement/Firmware'),
                        meta: {
                            title: 'firmwareManagement',
                            showBread: true,
                            showNav: true,
                            showLeftMenu: true,
                            module: 'platform'
                        }
                    },
                    {
                        name: 'menuManagement',
                        path: 'menu',
                        component: () => import(/*webpackChunkName: 'menu'*/'@/views/platform/SystemManagement/Menu'),
                        meta: {
                            title: 'menuManagement',
                            showBread: true,
                            showNav: true,
                            showLeftMenu: true,
                            module: 'platform'
                        }
                    },
                    {
                        name: 'operationLog',
                        path: 'operationlog',
                        component: () => import(/*webpackChunkName: 'operationlog'*/'@/views/platform/SystemManagement/OperationLog'),
                        meta: {
                            title: 'operationLog',
                            showBread: true,
                            showNav: true,
                            showLeftMenu: true,
                            module: 'platform'
                        }
                    },
                    {
                        name: 'regionalManagement',
                        path: 'regional',
                        component: () => import(/*webpackChunkName: 'regional'*/'@/views/platform/SystemManagement/Regional'),
                        meta: {
                            title: 'regionalManagement',
                            showBread: true,
                            showNav: true,
                            showLeftMenu: true,
                            module: 'platform'
                        }
                    },
                    {
                        name: 'roleManagement',
                        path: 'role',
                        component: () => import(/*webpackChunkName: 'role'*/'@/views/platform/SystemManagement/Role'),
                        meta: {
                            title: 'roleManagement',
                            showBread: true,
                            showNav: true,
                            showLeftMenu: true,
                            module: 'platform'
                        }
                    },
                    {
                        name: 'upgradeManagement',
                        path: 'upgrade',
                        component: () => import(/*webpackChunkName: 'upgrade'*/'@/views/platform/SystemManagement/Upgrade'),
                        meta: {
                            title: 'upgradeManagement',
                            showBread: true,
                            showNav: true,
                            showLeftMenu: true,
                            module: 'platform'
                        }
                    },
                    {
                        name: 'application',
                        path: 'application',
                        component: () => import(/*webpackChunkName: 'upgrade'*/'@/views/platform/SystemManagement/Application'),
                        meta: {
                            title: 'applicationmanagement',
                            showBread: true,
                            showNav: true,
                            showLeftMenu: true,
                            module: 'platform'
                        }
                    },
                    {
                        name: 'homecontent',
                        path: 'homecontent',
                        component: () => import(/*webpackChunkName: 'upgrade'*/'@/views/platform/SystemManagement/Homecontent'),
                        meta: {
                            title: 'homeContent',
                            showBread: true,
                            showNav: true,
                            showLeftMenu: true,
                            module: 'platform'
                        }
                    },
                ]
            },
			
			// 运营商管理
			{
			    name: 'operator',
			    path: 'operator',
			    component: Content,
			    redirect: '/platform/systemmanagement/aboutplatform',
			    meta: {
			        title: 'operatorManage',
			    },
			    children: [
			        {
			            name: 'custom',
			            path: 'custom',
			            component: () => import(/*webpackChunkName: 'aboutplatform'*/'@/views/platform/operator/Custom'),
			            meta: {
			                title: 'customManage',
			                showBread: true,
			                showNav: true,
			                showLeftMenu: true,
			                module: 'operator'
			            }
			        },
			        {
			            name: 'receivingterminal',
			            path: 'receivingterminal',
			            component: () => import(/*webpackChunkName: 'aboutplatform'*/'@/views/platform/operator/ReceivingTerminal'),
			            meta: {
			                title: 'receivingManage',
			                showBread: true,
			                showNav: true,
			                showLeftMenu: true,
			                module: 'operator'
			            }
			        },
			        {
			            name: 'collectingdevice',
			            path: 'collectingdevice',
			            component: () => import(/*webpackChunkName: 'aboutplatform'*/'@/views/platform/operator/Collectingdevice'),
			            meta: {
			                title: 'collectingManage',
			                showBread: true,
			                showNav: true,
			                showLeftMenu: true,
			                module: 'operator'
			            }
			        },
			        {
			            name: 'talkingterminal',
			            path: 'talkingterminal',
			            component: () => import(/*webpackChunkName: 'aboutplatform'*/'@/views/platform/operator/Talkingterminal'),
			            meta: {
			                title: 'talkingManage',
			                showBread: true,
			                showNav: true,
			                showLeftMenu: true,
			                module: 'operator'
			            }
			        },
			        {
			            name: 'smartscreen2',
			            path: 'smartscreen',
			            component: () => import(/*webpackChunkName: 'aboutplatform'*/'@/views/platform/operator/SmartScreen'),
			            meta: {
			                title: 'smartScreenManage',
			                showBread: true,
			                showNav: true,
			                showLeftMenu: true,
			                module: 'operator'
			            }
			        },
			    ]
			},

            {
                name: 'thirdPartyAccess',
                path: 'thirdparty',
                component: () => import(/*webpackChunkName: 'thirdparty'*/'@/views/platform/ThirdParty'),
                meta: {
                    title: 'thirdPartyAccess',
                    showBread: true,
                    showNav: true,
                    showLeftMenu: true,
                    module: 'platform'
                }
            },
            {
                name: 'serviceRenewal',
                path: 'serverrenew',
                component: () => import(/*webpackChunkName: 'serverrenew'*/'@/views/platform/serverRenew'),
                meta: {
                    title: 'serviceRenewal',
                    showBread: true,
                    showNav: true,
                    showLeftMenu: true,
                    module: 'platform'
                }
            }
        ]
    }
]