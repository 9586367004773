import { localGet } from '@/configs/storage'
import { Message } from 'element-ui'
import i18n from '@/i18n'
const { origin } = location
const isDevelopment = process.env.NODE_ENV === 'development'
export const useEventSource = ({ onMessage, onLogList, onLogFile, onError, onOpen, onScreenshot, systemMsg }) => {
	let url = ''
	let userId = localGet('userId')
	let token = localGet('token')
	if(isDevelopment){
		url = `http://1.14.19.123/mysse/sse/connect?userId=${userId}&token=${token}`
	}else{
		url = `${origin}/mysse/sse/connect?userId=${userId}&token=${token}`
	}
	let source = new EventSource(url);
	source.addEventListener('open', (e) => {
		console.log('屏幕已连接')
		if(typeof onOpen == 'function') onOpen(e)
	}, false);
	// source.onmessage = function (e) {
	// 	console.log("message", e.data, e);
	// }
	source.addEventListener('message', (e) => {
		// console.log('message', e)
		if(typeof onMessage == 'function') onMessage(e)
	    // setMessageInnerHTML(e.data);
	});
	// 监听指定事件
	source.addEventListener('screenshot', (e) => {
		console.log('screenshot', e)
		if(typeof onScreenshot == 'function') onScreenshot(e)
	});
	// 监听日志列表事件
	source.addEventListener('logList', (e) => {
		console.log('logList', e)
		if(typeof onLogList == 'function') onLogList(e)
	});
	// 监听下载事件
	source.addEventListener('logFile', (e) => {
		console.log('onLogFile', e)
		if(typeof onLogFile == 'function') onLogFile(e)
	});
	// 监听下载事件
	source.addEventListener('systemMsg', (e) => {
		if(typeof systemMsg == 'function') systemMsg(e)
	});
	source.addEventListener('error', (e) => {
		console.log('error', e.readyState,e.data, e)
		if(e.data == 401){
			Message.error(i18n.t('dlsx'))
			source.close()
			closeEventSource()
		}
		if(typeof onError == 'function') onError(e)
	}, false);
	
	return source
}
export const closeEventSource = () => {
	const httpRequest = new XMLHttpRequest();
	let url = ''
	let userId = localGet('userId')
	let token = localGet('token')
	if(isDevelopment){
		url = `http://1.14.19.123/ystms/sse/close?userId=${userId}&token=${token}`
	}else{
		url = `${origin}/ystms/sse/close?userId=${userId}&token=${token}`
	}
	httpRequest.open('GET', url, true);
	httpRequest.send();
	console.log("close");
}