import axios from 'axios'
import { cancelBus } from '@/configs'
import { secondaryPackagingRequest } from '@/request'
//获取用户列表(分页)
export const userList = params => secondaryPackagingRequest({
    url: '/sys/user/list',
    params,
    isLoading: false,
    needToken: true,
    cancelToken: new axios.CancelToken(function executor(c) {
        cancelBus.cancelUserList = c
    })
})
//获取个人信息
export const personalInfo = () => secondaryPackagingRequest({
    url: '/sys/user/personalInfo',
    isLoading: false,
    needToken: true
})
//根据用户账号模糊查询
export const userQuery = params => secondaryPackagingRequest({
    url: '/sys/user/query',
    params,
    isLoading: false,
    needToken: true
})
//根据用户账号查询(用户组查询)
export const userForGroup = params => secondaryPackagingRequest({
    url: '/sys/user/selectForGroup',
    params,
    isLoading: false,
    needToken: true
})
//新增用户
export const userSave = data => secondaryPackagingRequest({
    url: '/sys/user/save',
    method: 'POST',
    headers: {
        'Content-Type': 'application/json'
    },
    data,
    isLoading: true,
    needToken: true,
    loadingText: '注册中...'
})
//注册
export const userRegister = data => secondaryPackagingRequest({
    url: '/sys/user/register',
    method: 'POST',
    headers: {
        'Content-Type': 'application/json'
    },
    data,
    isLoading: true,
    needToken: false,
    loadingText: '注册中...'
})
//修改用户
export const userUpdate = data => secondaryPackagingRequest({
    url: '/sys/user/update',
    method: 'POST',
    headers: {
        'Content-Type': 'application/json'
    },
    data,
    isLoading: false,
    needToken: true
})
//删除用户
export const userDelete = params => secondaryPackagingRequest({
    url: '/sys/user/delete',
    method: 'POST',
    params,
    isLoading: false,
    needToken: true
})
//修改个人资料（用户头像）
export const updateAvatar = data => secondaryPackagingRequest({
    url: '/sys/user/updateAvatar',
    method: 'POST',
    headers: {
        'Content-Type': 'multipart/form-data'
    },
    data,
    isLoading: false,
    needToken: true
})
//修改密码
export const updatePassword = data => secondaryPackagingRequest({
    url: '/sys/user/updateMyPwd',
    method: 'POST',
    headers: {
        'Content-Type': 'application/json'
    },
    data,
    isLoading: false,
    needToken: true
})
//修改用户角色
export const updateUserRole = data => secondaryPackagingRequest({
    url: '/sys/user/updateUserRole',
    method: 'POST',
    headers: {
        'Content-Type': 'application/json'
    },
    data,
    isLoading: false,
    needToken: true
})
//修改角色定位信息
export const updateUserPosition = data => secondaryPackagingRequest({
    url: '/sys/user/position',
    method: 'POST',
    headers: {
        'Content-Type': 'application/json'
    },
    data,
    isLoading: false,
    needToken: true
})
//修改密码
export const updateUserPassword = data => secondaryPackagingRequest({
    url: '/sys/user/updatePassword',
    method: 'POST',
    headers: {
        'Content-Type': 'application/json'
    },
    data,
    isLoading: false,
    needToken: true
})