<template>
  <el-menu :class="themeClass" v-loading="menuLoading" :element-loading-text="`${$t('MenuLoading')}...`" element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.4)" :default-active="$route.path" :collapse="isCollapse" :default-openeds="defaultOpeneds" class="left-menu">
	<a href="/#/home/audiohome" class="a-title text-over">
		<span slot="title" class="title" :title="platformInfo.systemName">
		  {{platformInfo.systemName}}
		</span>
	</a>
    <MenuTree :menuData="menuData" />
	<div class="bot-footer">
		<p class="pitem text-over" :title="platformInfo.softVer">{{$t('softwareVersion')}}: {{platformInfo.softVer}}</p>
		<p class="pitem text-over" :title="platformInfo.serviceProvider">{{platformInfo.serviceProvider}}</p>
	</div>
    <el-empty class="empty" v-if="showEmpty && isCollapse === false">
      <el-button size="small" type="success" @click="getLeftMenu">重 试</el-button>
    </el-empty>
  </el-menu>
</template>

<script>
import '@/assets/css/resetElementMenu.css'
import MenuTree from "@/components/MenuTree/MenuTree"
import { getMenu } from "@/request/api/auth"
import { mapState } from "vuex"
export default {
  name: "LeftMenu", //左侧菜单组件
  components: {
    MenuTree,
  },
  data() {
    return {
      showEmpty: false, //请求错误时的空盒子
      menuLoading: false,//菜单加载动画
      menuData: [] //菜单列表
    }
  },
  computed: {
    ...mapState("publicOptions", ["token", "platformInfo"]),
    ...mapState("publicOptions", ["themeClass"]),
    ...mapState("publicOptions", ["isCollapse"]),
	defaultOpeneds(){
		if(this.menuData.length){
			return this.menuData.map((v, i) => {
				return v.path
			})
		}else{
			return []
		}
	}
  },
  methods: {
    // 获取左侧菜单
    async getLeftMenu() {
      try {
        this.menuLoading = true
        let { code, data } = await getMenu()
		this.menuLoading = false
		if(code == 200){
			data = data.map((v) => {
				v.icon = v.icon.replace(/ fa-/, ' icon-')
				if(v.children && v.children.length){
					v.children = v.children.map((item) => {
						item.icon = item.icon.replace(/ fa-/, ' icon-')
						return item
					})
				}
				return v
			})
			this.menuData = data
			if (this.menuData.length === 0) this.showEmpty = true
			else this.showEmpty = false
		}
      } catch (error) {
        this.showEmpty = true
        setTimeout(() => { this.menuLoading = false }, 0)
        error?.status && console.error(error?.status)
      }
    },
    // 页面初始化
    init() {
      this.getLeftMenu()
    }
  },
  watch: {
    // 有token再请求左侧菜单
    token: {
      immediate: true,
      handler(val) {
        if (val) this.init()
      }
    }
  }
}
</script>
<style lang="less" scoped>
.a-title{
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	display: block;
	line-height: 74px;
	height: 74px;
	text-align: center;
}
.bot-footer{
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	font-size: 12px;
	color: #fff;
	line-height: 20px;
	border-top: 1px solid rgba(255, 255, 255, 0.3);
	.pitem{
		text-align: center;
		height: 20px;
	}
}
.menu-title{
	position: fixed;
	left: 0;
	width: 240px;
	top: 0;
	padding-top: 30px;
	background-color: #5383EF;
	z-index: 1;
}
::-webkit-scrollbar {
  width: 0;
}
.left-menu {
  background: linear-gradient(-4deg, #5C4BD7 0%, #5383EF 100%);
  padding-top: 74px;
  padding-bottom: 40px;
  .empty {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  // z-index: 2001;
  overflow: hidden;
  .el-menu-item{
	  text-align: center;
	  line-height: 1;
	  padding: 30px 0 44px 0!important;
	  background: #5383EF!important;
	  &.is-active{
		  color: #fff;
	  }
  }
  .ft-icon {
    vertical-align: middle;
    margin-right: 5px;
    width: 24px;
    text-align: center;
    font-size: 18px;
    color: skyblue;
  }
  .title {
    font-size: 24px;
    color: #fff !important;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
    display: inline-block;
  }
  /deep/ .el-menu{
	background: none;
  }
  /deep/ .el-submenu .el-menu-item{
	font-size: 14px;
  	color: #fff;
	&:hover{
		background: rgba(255,255,255,.3);
	}
  }
  
}
.menu-tree{
	height: 100%;
	overflow-y: auto;
	overflow-x: hidden;
	/deep/ .el-submenu__title{
		font-size: 18px;
		color: #fff;
		&:hover{
			background: rgba(255,255,255,.3);
		}
	}
}
.left-menu:not(.el-menu--collapse) {
  width: 240px;
}
.menublack{
	background: rgb(84, 92, 100);
	.menu-title{
		background: rgb(84, 92, 100)!important;
	}
}
.menugray{
	background: rgb(119, 136, 153);
	.menu-title{
		background: rgb(119, 136, 153)!important;
	}
}
@media screen and (max-width: 1200px){
	.left-menu:not(.el-menu--collapse),.menu-title{
		width: 140px;
	}
	.left-menu{
		.title{
			font-size: 16px;
		}
	}
	/deep/ .el-menu-item{
		font-size: 14px;
		padding: 0 10px!important;
	}
	/deep/ .el-submenu__title{
		padding: 0 10px!important;
	}
	/deep/ .menu-tree{
		.ft-icon{
			margin-right: 10px;
		}
		.el-submenu__title{
			font-size: 14px;
		}
		.textspan{
			max-width: 50%;
		}
		.menu-tree .menu-tree .el-menu-item{
			padding-left: 57px!important;
		}
	}
	/deep/ .label-item{
		.menu-tree label .el-submenu__title, .menu-tree label .el-menu-item{
			padding-left: 30px!important;
		}
	}
}
</style>