import i18n from '@/i18n'
const reg2 = /^(?![\d]+$)(?![a-zA-Z]+$)(?![\W]+$)[\d\Wa-zA-z]{8,20}$/ //输入的数字、字母、符号其中两种组合
const reg4 = /^[\W|_|a-zA-Z]/ //以字母符号开头
const reg5 = /[\W|_|a-zA-Z]$/ //以字母符号结尾
const reg6 = /[\W_a-zA-Z]/g //字母符号
const reg7 = /\d{5,}/g //输入连续5个 及以上数字
// 输入是否为手机号
export function isvalidPhone(value) {
	const reg = /^[1][3,5,7,8][0-9]{9}$/
	return reg.test(value)
}
// 输入是否为名字规则（包含中英文和数字）
export function isvalidName(value) {
	const reg = /^[\d|A-z|\u4E00-\u9FA5]+$/
	return reg.test(value)
}
// 输入是否为数字
export function isvalidNumber(value) {
	const reg = /^\d+$/
	return reg.test(value)
}
// 输入是否符合经纬度
export function isvalidLngLat(value) {
	const reg = /^([+|-]?)\d*\.{0,1}\d{1,}$/
	return reg.test(value)
}
//返回汉字
export function isvalidChinese(value) {
	const reg = /[\u4E00-\u9FA5]/g
	return value.match(reg)
}
//校验只包含数字和字母
export function isvalidLetNum(value) {
	const reg = /^[\d|A-z]+$/
	return reg.test(value)
}
//密码校验-复杂版本
export function validPasswordOld(value, callback) {
	if(!value){
		callback(new Error(i18n.t('Pleaseinputapassword')));
	}else{
		if(reg2.test(value)){
			let matchs = value.match(reg6)
			if(matchs.length <= 1 && (reg4.test(value) || reg5.test(value))){
				callback(new Error('单字符不能在首尾'));
			}else if(value.match(reg7)){
				callback('不能连续5个及以上数字');
			}else{
				callback();
			}
		}else{
			callback(new Error('请输入8-20位数字、字母或特殊符号三种组合的密码'));
		}
	}
}
// 密码校验-新
export function validPassword(value, callback) {
	let reg = / /
	if(!value){
		callback(new Error(i18n.t('Pleaseinputapassword')));
	}else{
		if(reg.test(value)){
			callback(new Error(i18n.t('bnsykg')));
		}else if(value.length < 8 || value.length > 20){
			callback(new Error(i18n.t('qsrmm')));
		}else{
			callback();
		}
	}
}
// 账号校验
export function accountValid(value, callback) {
	if(!value){
		callback(new Error(i18n.t('pleaseEnterTheAccountNumber')));
	}else{
		if(!isvalidName(value)){
			callback(new Error(i18n.t('qsrszzm')));
		}else{
			callback()
		}
	}
}
//激活设备名称校验
export function DeviceNameValid(value, callback, message, conut, require = false) {
	if(!value && require){
		callback(new Error(message))
	}else{
		var matchChinese = isvalidChinese(value)
		if(matchChinese && matchChinese.length > conut){
			callback(new Error(`不能超过${conut}个汉字`));
		}else{
			callback()
		}
	}
}
//物理地址校验
export function addDeviceValid(value, callback, message, require = false) {
	if(!value){
		callback(new Error(message));
	}else{
		if(!isvalidLetNum(value)){
			callback(new Error('请输入数字或者字母'));
		}else{
			callback()
		}
	}
}
//遍历选中的节点数据
export function getParentList(data) {
	return new Promise(async (resolve, reject) => {
		let originList = [...data]
		let resultList = [...data]
		for( let key in data){
			deleteItem(data[key])
		}
		originList = originList.filter(item => item)
		resolve(originList)
		
		function deleteItem (item) {
			for( let key in originList){
				if(originList[key] != item && String(originList[key]).indexOf(item) == 0){
					delete originList[key]
				}
			}
		}
	})
}
//遍历选中的节点数据(通过targets)
export function getParentListByTargets(data) {
	return new Promise(async (resolve, reject) => {
		let originList = [...data]
		let resultList = [...data]
		for( let key in data){
			deleteItem(data[key])
		}
		originList = originList.filter(item => item)
		resolve(originList)
		
		function deleteItem (item) {
			for( let key in originList){
				if(originList[key].id != item.id && String(originList[key].id).indexOf(item.id) == 0){
					delete originList[key]
				}
			}
		}
	})
}