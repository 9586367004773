import Vue from "vue"
import Element from 'element-ui'
import messages from './lang'
import VueI18n from 'vue-i18n'
import { localGet } from '@/configs/storage'
Vue.use(VueI18n)
const i18n = new VueI18n({
    locale: localGet('lang') || 'zh',
    messages,
    silentTranslationWarn: true
})

Vue.use(Element, {
    i18n: (key, value) => i18n.t(key, value)
})
export default i18n