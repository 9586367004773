<template>
  <div class="code-box">
	  <div class="code-img" :style="`width: ${size}px; height: ${size}px`" v-loading="false">
		  <!-- <img src="https://www.1212.com/data/thumbimg/20220805/16596637558510.jpg" alt=""> -->
		<vue-qr ref="images" :text="serviceData" :size="size" v-if="startCode" :callback="codeSuccess"></vue-qr>
	  </div>
	  <div class="code-text">{{$t(text)}}</div>
  </div>
</template>

<script>
import VueQr from 'vue-qr'
import { Download } from '@/configs/download'
import { selectLatestApp } from "@/request/api/application"
import { number } from 'echarts'
export default {
  props: {
	  size: {
		  type: Number,
		  default: 250
	  }
  },
  components: {VueQr},
  data() {
    return {
		serviceData: {}, //分享信息
		loading: true, //加载中
		startCode: false, //显示二维码图片
		imgUrl: '', //生成的二维码图片地址
		download : new Download(),
		text: 'azapph'
    }
  },
  methods: {
	onCopy(){
		console.log(1111)
	},
	onError(e){
		console.log(222,e)
	},
	// 生成二维码成功后的回调
	codeSuccess(dataUrl, id){
		if(dataUrl){
			this.imgUrl = dataUrl
		}
	},
	// 点击分享
	async toShare(){
		this.download.downloadFile('二维码',this.imgUrl);
		// const data = await fetch(this.imgUrl);
		// const blob = await data.blob();
		// console.log(333,navigator.clipboard)
		// await navigator.clipboard.write([
		//   new ClipboardItem({
		// 	[blob.type]: blob,
		//   }),
		// ]);
	},
	// 登录页的二维码
	defaultCode(){
		let { host, port, protocol } = location
		port = port ? `:${port}` : ''
		const serviceData = {
			url: `${location.host}`,
			ssl: protocol == 'https:'
		}
		this.serviceData = JSON.stringify(serviceData)
		this.startCode = true
	},
	// 获取最新app包
	getAppData(){
		let { origin } = location
		this.serviceData = `${origin}/#/index/downloaddetail/BroadcastApp-Android`
		this.startCode = true
		this.text = 'syllq'
	},
	//初始化
	init(){
		if(this.$route.name == 'login'){
			this.defaultCode()
		}else{
			this.getAppData()
		}
	},
  },
  mounted() {
	this.init()
  },
}
</script>
<style lang="less" scoped>
	.code-img{
		margin: 0 auto;
	}
	.code-text{
		text-align: center;
	}
</style>