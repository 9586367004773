import axios from 'axios'
import { cancelBus } from '@/configs'
import { secondaryPackagingRequest } from '@/request'
//获取设备列表
export const wareList = params => secondaryPackagingRequest({
    url: '/sm/warehousing/list',
    params,
    isLoading: false,
    needToken: true,
    cancelToken: new axios.CancelToken(function executor(c) {
        cancelBus.cancelWareList = c
    })
})
//新增设备
export const wareSave = data => secondaryPackagingRequest({
    url: '/sm/warehousing/save',
    method: 'POST',
    headers: {
        'Content-Type': 'application/json'
    },
    data,
    isLoading: false,
    needToken: true
})
//删除设备
export const wareDelete = params => secondaryPackagingRequest({
    url: '/sm/warehousing/delete',
    method: 'POST',
    params,
    isLoading: false,
    needToken: true
})
//导入设备
export const wareImportDevice = data => secondaryPackagingRequest({
    url: '/sm/warehousing/importDevice',
    method: 'POST',
    headers: {
        'Content-Type': 'multipart/form-data'
    },
    data,
    isLoading: false,
    needToken: true
})
//获取特殊配置(终端、采集设备等通用接口)
export const specialInfo = params => secondaryPackagingRequest({
    url: '/sm/warehousing/specialInfo',
    params,
    isLoading: false,
    needToken: true
})
//修改特殊配置
export const updateSpecialInfo = data => secondaryPackagingRequest({
    url: '/sm/warehousing/updateSpecialInfo',
    method: 'POST',
    headers: {
        'Content-Type': 'application/json'
    },
    data,
    isLoading: false,
    needToken: true
})
//指令控制(终端、采集设备等通用接口)
export const commandControl = data => secondaryPackagingRequest({
    url: '/sm/warehousing/commandControl',
    method: 'POST',
    headers: {
        'Content-Type': 'application/json'
    },
    data,
    isLoading: false,
    needToken: true
})
//审核入库
export const wareHouse = data => secondaryPackagingRequest({
    url: '/sm/warehousing/warehouse',
    method: 'POST',
    headers: {
        'Content-Type': 'application/json'
    },
    data,
    isLoading: false,
    needToken: true
})
//上线记录(终端、采集设备等通用接口)
export const onlineRecord = params => secondaryPackagingRequest({
    url: '/sm/warehousing/onlineRecord',
    params,
    isLoading: false,
    needToken: true
})
//获取呼叫对象列表（已添加）
export const wareCalledList = params => secondaryPackagingRequest({
    url: '/sm/warehousing/calledList',
    params,
    isLoading: false,
    needToken: true
})
//获取呼叫对象（未添加）
export const wareCallableList = params => secondaryPackagingRequest({
    url: '/sm/warehousing/callableList',
    params,
    isLoading: false,
    needToken: true
})
//删除呼叫对象
export const deleteCallObject = data => secondaryPackagingRequest({
    url: '/sm/warehousing/deleteCallObject',
    method: 'POST',
    headers: {
        'Content-Type': 'application/json'
    },
    data,
    isLoading: false,
    needToken: true
})
//添加呼叫对象
export const addCallObject = data => secondaryPackagingRequest({
    url: '/sm/warehousing/addCallObject',
    method: 'POST',
    headers: {
        'Content-Type': 'application/json'
    },
    data,
    isLoading: false,
    needToken: true
})
//交换呼叫顺序
export const exchangeCallSort = data => secondaryPackagingRequest({
    url: '/sm/warehousing/exchangeCallSort',
    method: 'POST',
    headers: {
        'Content-Type': 'application/json'
    },
    data,
    isLoading: false,
    needToken: true
})
//获取设备广播记录
export const broadcastRecord = params => secondaryPackagingRequest({
    url: '/sm/warehousing/broadcastRecord',
    params,
    isLoading: false,
    needToken: true
})
//设备4g信号记录
export const signalRecord = params => secondaryPackagingRequest({
    url: '/sm/warehousing/signal/record',
    params,
    isLoading: false,
    needToken: true
})
//批量删除设备
export const batchDelete = data => secondaryPackagingRequest({
    url: '/sm/warehousing/batchDelete',
    method: 'POST',
	headers: {
	    'Content-Type': 'application/json'
	},
    data,
    isLoading: false,
    needToken: true
})
//批量审核设备
export const batchWarehouse = data => secondaryPackagingRequest({
    url: '/sm/warehousing/batchWarehouse',
    method: 'POST',
	headers: {
	    'Content-Type': 'application/json'
	},
    data,
    isLoading: false,
    needToken: true
})
//批量出库
export const ExWarehouse = data => secondaryPackagingRequest({
    url: '/sm/warehousing/ExWarehouse',
    method: 'POST',
	headers: {
	    'Content-Type': 'application/json'
	},
    data,
    isLoading: false,
    needToken: true
})
//导出接收终端
export const deviceExport = params => secondaryPackagingRequest({
    url: '/sm/talkingTerminal/export',
    params,
    isLoading: false,
    needToken: true,
	timeout: 200000,
    responseType: 'arraybuffer'
})
//运营商导出接收终端
export const deviceExport2 = params => secondaryPackagingRequest({
    url: '/sys/talkingTerminal/export',
    params,
    isLoading: false,
    needToken: true,
	timeout: 200000,
    responseType: 'arraybuffer'
})
//导出入库设备
export const warehousingDeviceExport = params => secondaryPackagingRequest({
    url: '/sm/warehousing/export',
    params,
    isLoading: false,
    needToken: true,
	timeout: 200000,
    responseType: 'arraybuffer'
})