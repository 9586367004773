//广播路由模块
import Content from '@/components/Content/Content'
export default [
    {
        name: 'info',
        path: '/info',
        component: Content,
        redirect: '/info/screenrelease',
        meta: {
            title: 'info'
        },
        children: [
            // 广播发布
            {
                path: 'delivery',
                component: () => import(/*webpackChunkName: 'devicedemand'*/'@/views/Information/Delivery'),
                meta: {
                    title: 'delivery',
					showBread: true,
					showNav: true,
					showLeftMenu: true,
					module: 'information'
                },
            },
			// 媒资管理
            {
                path: 'mediaassets',
                component: () => import(/*webpackChunkName: 'devicedemand'*/'@/views/Information/Mediaassets'),
                meta: {
                    title: 'mediaassets',
					showBread: true,
					showNav: true,
					showLeftMenu: true,
					module: 'info'
                },
            },
			// 智慧屏管理
			{
			    name: 'smartscreen',
			    path: 'smartscreen',
			    component: () => import(/*webpackChunkName: 'releaseinfo'*/'@/views/Information/SmartScreen'),
			    meta: {
			        title: 'smartscreen',
			        showBread: true,
			        showNav: true,
			        showLeftMenu: true,
			        module: 'info'
			    }
			},
			// 上屏发布
			{
			    name: 'screenrelease',
			    path: 'screenrelease',
			    component: () => import(/*webpackChunkName: 'releaseinfo'*/'@/views/Information/ScreenRelease'),
			    meta: {
			        title: 'screenrelease',
			        showBread: true,
			        showNav: true,
			        showLeftMenu: true,
			        module: 'info'
			    }
			},
			// 发布记录
			{
			    name: 'releasehistory',
			    path: 'releasehistory',
			    component: () => import(/*webpackChunkName: 'releaseinfo'*/'@/views/Information/ReleaseHistory'),
			    meta: {
			        title: 'releasehistory',
			        showBread: true,
			        showNav: true,
			        showLeftMenu: true,
			        module: 'info'
			    }
			},
        ]
    }
]