//广播路由模块
import Content from '@/components/Content/Content'
export default [
    {
        name: 'broadcast',
        path: '/audio',
        component: Content,
        redirect: '/audio/broadcastrelease/realtime',
        meta: {
            title: 'broadcast'
        },
        children: [
            // 广播发布
            {
                path: 'broadcastRelease',
                component: Content,
                redirect: '/audio/broadcastrelease/realtime',
                meta: {
                    title: 'broadcastRelease'
                },
                children: [
                    {
                        name: 'deviceOnDemand',
                        path: 'devicedemand',
                        component: () => import(/*webpackChunkName: 'devicedemand'*/'@/views/audio/BroadcastRelease/DeviceDemand'),
                        meta: {
                            title: 'deviceOnDemand',
                            showBread: true,
                            showNav: true,
                            showLeftMenu: true,
                            module: 'audio'
                        }
                    },
                    {
                        name: 'offline',
                        path: 'offline',
                        component: () => import(/*webpackChunkName: 'offline'*/'@/views/audio/BroadcastRelease/Offline'),
                        meta: {
                            title: '离线广播',
                            showBread: true,
                            showNav: true,
                            showLeftMenu: true,
                            module: 'audio'
                        }
                    },
                    {
                        name: 'realTimeBroadcast',
                        path: 'realtime',
                        component: () => import(/*webpackChunkName: 'realtime'*/'@/views/audio/BroadcastRelease/RealTime'),
                        meta: {
                            title: 'realTimeBroadcast',
                            showBread: true,
                            showNav: true,
                            showLeftMenu: true,
                            module: 'audio'
                        }
                    },
                    {
                        name: 'timedBroadcast',
                        path: 'timed',
                        component: () => import(/*webpackChunkName: 'timed'*/'@/views/audio/BroadcastRelease/Timed'),
                        meta: {
                            title: 'timedBroadcast',
                            showBread: true,
                            showNav: true,
                            showLeftMenu: true,
                            module: 'audio'
                        }
                    },
                    {
                        name: 'timinglive',
                        path: 'timinglive',
                        component: () => import(/*webpackChunkName: 'timed'*/'@/views/audio/BroadcastRelease/TimingLive'),
                        meta: {
                            title: 'timingLive',
                            showBread: true,
                            showNav: true,
                            showLeftMenu: true,
                            module: 'audio'
                        }
                    },
                    {
                        name: 'visualBroadcasting',
                        path: 'visual',
                        component: () => import(/*webpackChunkName: 'visual'*/'@/views/audio/BroadcastRelease/Visual'),
                        meta: {
                            title: 'visualBroadcasting',
                            showBread: true,
                            showNav: true,
                            showLeftMenu: true,
                            module: 'audio'
                        }
                    },
                    {
                        name: 'musicbroadcast',
                        path: 'musicbroadcast',
                        component: () => import(/*webpackChunkName: 'visual'*/'@/views/audio/BroadcastRelease/MusicBroadcast'),
                        meta: {
                            title: 'musicbroadcast',
                            showBread: true,
                            showNav: true,
                            showLeftMenu: true,
                            module: 'audio'
                        }
                    },
                    {
                        name: 'aitask',
                        path: 'aitask',
                        component: () => import(/*webpackChunkName: 'visual'*/'@/views/audio/BroadcastRelease/Aitask'),
                        meta: {
                            title: 'aitask',
                            showBread: true,
                            showNav: true,
                            showLeftMenu: true,
                            module: 'audio'
                        }
                    },
                ]
            },
            // 媒体库
            {
                name: 'mediaLibrary',
                path: 'medialibrary',
                component: Content,
                redirect: '/audio/medialibrary/audiomedia',
                meta: {
                    title: 'mediaLibrary'
                },
                children: [

                    {
                        name: 'audioMedia',
                        path: 'audiomedia',
                        component: () => import(/*webpackChunkName: 'audiomedia'*/'@/views/audio/MediaLibrary/AudioMedia'),
                        meta: {
                            title: 'audioMedia',
                            showBread: true,
                            showNav: true,
                            showLeftMenu: true,
                            module: 'audio'
                        }
                    },
                    {
                        name: 'mediaAudit',
                        path: 'mediaaudit',
                        component: () => import(/*webpackChunkName: 'mediaaudit'*/'@/views/audio/MediaLibrary/MediaAudit'),
                        meta: {
                            title: 'mediaAudit',
                            showBread: true,
                            showNav: true,
                            showLeftMenu: true,
                            module: 'audio'
                        }
                    },
                    {
                        name: 'sensitiveVocabulary',
                        path: 'sensitivevocabulary',
                        component: () => import(/*webpackChunkName: 'sensitivevocabulary'*/'@/views/audio/MediaLibrary/SensitiveVocabulary'),
                        meta: {
                            title: 'sensitiveVocabulary',
                            showBread: true,
                            showNav: true,
                            showLeftMenu: true,
                            module: 'audio'
                        }
                    },
                    {
                        name: 'speechSynthesis',
                        path: 'speechsynthesis',
                        component: () => import(/*webpackChunkName: 'speechsynthesis'*/'@/views/audio/MediaLibrary/SpeechSynthesis'),
                        meta: {
                            title: 'speechSynthesis',
                            showBread: true,
                            showNav: true,
                            showLeftMenu: true,
                            module: 'audio'
                        }
                    },
                    {
                        name: 'audiorecord',
                        path: 'audiorecord',
                        component: () => import(/*webpackChunkName: 'speechsynthesis'*/'@/views/audio/MediaLibrary/AudioRecord'),
                        meta: {
                            title: 'audioRecord',
                            showBread: true,
                            showNav: true,
                            showLeftMenu: true,
                            module: 'audio'
                        }
                    },
                ]
            },
            // 设备管理
            {
                name: 'deviceManagement',
                path: 'devicemanagement',
                component: Content,
                redirect: '/audio/devicemanagement/receivingterminal',
                meta: {
                    title: 'deviceManagement'
                },
                children: [
                    {
                        name: 'acquisitionEquipment',
                        path: 'acquisitionequipment',
                        component: () => import(/*webpackChunkName: 'acquisitionequipment'*/'@/views/audio/DeviceManagement/AcquisitionEquipment'),
                        meta: {
                            title: 'acquisitionEquipment',
                            showBread: true,
                            showNav: true,
                            showLeftMenu: true,
							needKeep: true,
                            module: 'audio'
                        }
                    },
                    {
                        name: 'intercomTerminal',
                        path: 'intercomterminal',
                        component: () => import(/*webpackChunkName: 'intercomterminal'*/'@/views/audio/DeviceManagement/IntercomTerminal'),
                        meta: {
                            title: 'intercomTerminal',
                            showBread: true,
                            showNav: true,
                            showLeftMenu: true,
							needKeep: true,
                            module: 'audio'
                        }
                    },
                    {
                        name: 'ipAdapter',
                        path: 'ipadapter',
                        component: () => import(/*webpackChunkName: 'ipadapter'*/'@/views/audio/DeviceManagement/IPAdapter'),
                        meta: {
                            title: 'ipAdapter',
                            showBread: true,
                            showNav: true,
                            showLeftMenu: true,
							needKeep: true,
                            module: 'audio'
                        }
                    },
                    {
                        name: 'receivingTerminal',
                        path: 'receivingterminal',
                        component: () => import(/*webpackChunkName: 'receivingterminal'*/'@/views/audio/DeviceManagement/ReceivingTerminal'),
                        meta: {
                            title: 'receivingTerminal',
                            showBread: true,
                            showNav: true,
                            showLeftMenu: true,
							needKeep: true,
                            module: 'audio'
                        }
                    },
                    {
                        name: 'equipmentWarehousing',
                        path: 'equipmentwarehousing',
                        component: () => import(/*webpackChunkName: 'equipmentwarehousing'*/'@/views/audio/DeviceManagement/EquipmentWarehousing'),
                        meta: {
                            title: 'equipmentWarehousing',
                            showBread: true,
                            showNav: true,
                            showLeftMenu: true,
							needKeep: true,
                            module: 'audio'
                        }
                    },
                    {
                        name: 'deviceGroup',
                        path: 'devicegroup',
                        component: () => import(/*webpackChunkName: 'devicegroup'*/'@/views/audio/DeviceManagement/DeviceGroup'),
                        meta: {
                            title: 'deviceGroup',
                            showBread: true,
                            showNav: true,
                            showLeftMenu: true,
							needKeep: true,
                            module: 'audio'
                        }
                    },
                ]
            },
            // 监测评估
            {
                name: 'monitoringEvaluation',
                path: 'monitoringevaluation',
                component: Content,
                redirect: '/audio/monitoringevaluation/broadcastrecord',
                meta: {
                    title: 'monitoringEvaluation'
                },
                children: [
                    {
                        name: 'broadcastRecord',
                        path: 'broadcastrecord',
                        component: () => import(/*webpackChunkName: 'broadcastrecord'*/'@/views/audio/MonitoringEvaluation/BroadcastRecord'),
                        meta: {
                            title: 'broadcastRecord',
                            showBread: true,
                            showNav: true,
                            showLeftMenu: true,
                            module: 'audio'
                        }
                    },
                    {
                        name: 'broadcastStatistics',
                        path: 'broadcaststatistics',
                        component: () => import(/*webpackChunkName: 'broadcaststatistics'*/'@/views/audio/MonitoringEvaluation/BroadcastStatistics'),
                        meta: {
                            title: 'broadcastStatistics',
                            showBread: true,
                            showNav: true,
                            showLeftMenu: true,
                            module: 'audio'
                        }
                    },
                    {
                        name: 'largedataScreen',
                        path: 'largedatascreen',
                        component: () => import(/*webpackChunkName: 'largedatascreen'*/'@/views/audio/MonitoringEvaluation/LargedataScreen'),
                        meta: {
                            title: 'largedataScreen',
                            showBread: false,
                            showNav: false,
                            showLeftMenu: false,
                            module: 'audio',
							isLargeFullScreen: true,
                        }
                    },
                    {
                        name: 'offlineTerminal',
                        path: 'offlineterminal',
                        component: () => import(/*webpackChunkName: 'offlineterminal'*/'@/views/audio/MonitoringEvaluation/OfflineTerminal'),
                        meta: {
                            title: 'offlineTerminal',
                            showBread: true,
                            showNav: true,
                            showLeftMenu: true,
                            module: 'audio'
                        }
                    },
                    {
                        name: 'resources',
                        path: 'resources',
                        component: () => import(/*webpackChunkName: 'resources'*/'@/views/audio/MonitoringEvaluation/Resources'),
                        meta: {
                            title: 'resources',
                            showBread: true,
                            showNav: true,
                            showLeftMenu: true,
                            module: 'audio'
                        }
                    },
                    {
                        name: 'serviceAlarm',
                        path: 'servicealarm',
                        component: () => import(/*webpackChunkName: 'servicealarm'*/'@/views/audio/MonitoringEvaluation/ServiceAlarm'),
                        meta: {
                            title: 'serviceAlarm',
                            showBread: true,
                            showNav: true,
                            showLeftMenu: true,
                            module: 'audio'
                        }
                    },
                ]
            },

            {
                name: 'callIntercom',
                path: 'callintercom',
                component: () => import(/*webpackChunkName: 'callintercom'*/'@/views/audio/CallIntercom'),
                meta: {
                    title: 'callIntercom',
                    showBread: true,
                    showNav: true,
                    showLeftMenu: true,
                    module: 'audio'
                }
            },
            {
                name: 'emergencyPlan',
                path: 'emergencyplan',
                component: () => import(/*webpackChunkName: 'emergencyplan'*/'@/views/audio/EmergencyPlan'),
                meta: {
                    title: 'emergencyPlan',
                    showBread: true,
                    showNav: true,
                    showLeftMenu: true,
                    module: 'audio'
                }
            },
            {
                name: 'fireLinkage',
                path: 'firelinkage',
                component: () => import(/*webpackChunkName: 'firelinkage'*/'@/views/audio/FireLinkage'),
                meta: {
                    title: 'fireLinkage',
                    showBread: true,
                    showNav: true,
                    showLeftMenu: true,
                    module: 'audio'
                }
            },
            {
                name: 'gisMap',
                path: 'gismap',
                component: () => import(/*webpackChunkName: 'gismap'*/'@/views/audio/GisMap'),
                meta: {
                    title: 'gisMap',
                    showBread: true,
                    showNav: true,
                    showLeftMenu: true,
                    module: 'audio'
                }
            },
            {
                name: 'gis',
                path: 'gismaps',
                component: () => import(/*webpackChunkName: 'gismap'*/'@/views/audio/GisMapChanging'),
                meta: {
                    title: 'gisMap',
                    showBread: true,
                    showNav: true,
                    showLeftMenu: true,
                    module: 'audio'
                }
            }
        ]
    }
]