// 英文
import enLocale from 'element-ui/lib/locale/lang/en'
export default {
    smartCloudBroadcastingSystem: 'Smart Cloud Broadcasting System',
	switchLanguage: 'Switch Language',
	register: 'Register',
	verificationCode: 'Verification Code',
	secureLogin: 'Secure Login',
	returnToLogin: 'Return To Login',
	login: 'Login',
	autologin: 'Login',
	error: 'Error',
	downloadCenter: 'Download Center',
	downloaddetail: 'Download Center',
	commonProblem: 'Common Problem',
	freeRegistration: 'Free Registration',
	agreement: 'Agreement',
	findPassword: 'Find Password',
	loginOut: 'Login Out',
	switchTheme: 'Switch Theme',
	black: 'Black',
	blue: 'Blue',
	gray: 'Gray',
	home: 'Home',
	paste: 'Paste',
	forever: 'Forever',
	broadcast: 'Broadcast',
	broadcastRelease: 'Broadcast Release',
	realTimeBroadcast: 'RealTime Broadcast',
	musicbroadcast: 'Music Broadcast',
	timedBroadcast: 'Timed Broadcast',
	deviceOnDemand: 'Device On Demand',
	deviceBroadcastd: 'Device Broadcastd',
	visualBroadcasting: 'Visual Broadcasting',
	shoutingbroadcast: 'Shouting broadcast',
	mediaLibrary: 'Media Library',
	deviceManagement: 'Device Management',
	monitoringEvaluation: 'Monitoring Evaluation',
	platformManagement: 'Platform Management',
	accountManagement: 'Account Management',
	systemManagement: 'System Management',
	mochaITOM: 'Mocha ITOM',
	thirdPartyAccess: 'Third Party Access',
	serviceRenewal: 'Service Renewal',
	personalSettings: 'Personal Settings',
	userManagement: 'User Management',
	userGroupManagement: 'UseGroup Management',
	orderlist: 'Order Record',
	ordernumber: 'Order Number',
	Ordercontent: 'Order content',
	Orderdetails: 'Order Details',
	drawee: 'Drawee',
	amount: 'Amount Of Money',
	Paymentmethod: 'Payment Method',
	Billingcenter: 'Billing center',
	Toinvoice: 'To invoice',
	Electronicinvoice: 'Electronic Invoice',
	servicerenewal: 'Service Renewal',
	regionalManagement: 'Regional Management',
	roleManagement: 'Role Management',
	menuManagement: 'Menu Management',
	firmwareManagement: 'Firmware Management',
	upgradeManagement: 'Upgrade Management',
	operationLog: 'Operation Log',
	aboutPlatform: 'About Platform',
	dataBaseBackup: 'Data Base Backup',
	mediaServiceManagement: 'Media Service Management',
	systemLog: 'System Log',
	operationMonitoring: 'Operation Monitoring',
	onlineService: 'Online Service',
	systemMessageRelease: 'System Message Release',
	filestorage: 'File Storage Service',
	fileStorageMethod: 'File storage method',
	emergencyPlan: 'Emergency Plan',
	callIntercom: 'CallIntercom',
	fireLinkage: 'Fire Linkage',
	gisMap: 'Gis Map',
	audioMedia: 'Audio Media',
	audio: 'Audio',
	speechSynthesis: 'Speech Synthesis',
	acquisitionEquipment: 'Acquisition Equipment',
	acquisitionEquipment2: 'Acquisition Equipment（Collector+Ip Microphone）',
	intercomTerminal: 'Intercom Terminal',
	ipAdapter: 'Ip Adapter',
	ipMicrophone: 'Ip Microphone',
	collector: 'Collector',
	mfr: 'MFR',
	receivingTerminal: 'Receiving Terminal',
	equipmentWarehousing: 'Equipment Warehousing',
	deviceGroup: 'Device Group',
	broadcastRecord: 'Broadcast Record',
	audioHome: {
		broadcastRecord: 'Broadcast Record',
	},
	broadcastStatistics: 'Broadcast Statistics',
	largedataScreen: 'Largedata Screen',
	offlineTerminal: 'Offline Terminal',
	resources: 'Resources',
	serviceAlarm: 'Service Alarm',
	offlineBroadcast: 'Offline Broadcast',
	mediaAudit: 'Media Audit',
	sensitiveWords: 'Sensitive Words',
	sensitiveVocabulary: 'Sensitive Vocabulary',
	newSensitiveWords: 'New Sensitive Words',
	textContent: 'Text Content',
	textName: 'Text Name',
	informant: 'Informant',
	speedOfSpeech: 'Speed Of Speech',
	intonation: 'Intonation',
	delay: 'Delay',
	content: 'Content',
	oneTouchScreenProjection: 'One Touch Screen Projection',
	systemMessage: 'System Message',
	deviceStatistics: 'Device Statistics',
	totalCount: 'Total Count',
	online: 'Online',
	offline: 'Offline',
	menuShortcutentry: 'Menu Shortcut entry',
	planAlarm: 'Plan Alarm',
	fire: 'Fire',
	earthquake: 'Earthquake',
	airDefense: 'Air Defense',
	broadcasting: 'Broadcasting',
	broadcastPlan: 'Broadcast Plan',
	broadcastMonitoring: 'Broadcast Monitoring',
	turnOffBroadcast: 'Turn Off Broadcast',
	detail: 'Detail',
	theDayBefore: 'The Day Before',
	theDayAfter: 'The Day After',
	close: 'Close',
	broadcastFrequencyStatistics: 'Broadcast Frequency Statistics',
	broadcastStatusStatistics: 'Broadcast Status Statistics',
	terminalDistribution: 'Terminal Distribution',
	quantity: 'Quantity',
	many: 'Many',
	equipmentType: 'Equipment Type',
	equipmentTypeStatistics: 'Equipment Type Statistics',
	terminalOnlineStatistics: 'Terminal Online Statistics',
	activateIntercomTerminal: 'Activate Intercom Terminal',
	activateCollectionDevice: 'Activate Collection Device',
	modifyCollectionDevice: 'Modify Collection Device',
	modifyIntercomTerminal: 'Modify Intercom Terminal',
	modifyTerminal: 'Modify Terminal',
	broadcastSuccessful: 'Broadcast Successful',
	broadcastFailure: 'Broadcast Failure',
	onlineRate: 'OnlineRate',
	task: 'Task',
	taskName: 'TaskName',
	priority: 'Priority',
	broadcastTarget: 'Broadcast Target',
	startTime: 'Start Time',
	endTime: 'End Time',
	volume: 'Volume',
	operation: 'Operation',
	newTask: 'New Task',
	sameDay: 'Same Day',
	sevenDay: 'Seven Day',
	oneMonth: 'One Month',
	startDate: 'Start Date',
	endDate: 'End Date',
	search: 'Search',
	query: 'Query',
	number: 'Number',
	serialNumber: 'Serial Number',
	broadcastMode: 'Broadcast Mode',
	broadcastStatus: 'Broadcast Status',
	operator: 'Operator',
	offBroadcastTime: 'Off Broadcast Time',
	cycle: 'Cycle',
	enable: 'Enable',
	disable: 'Disable',
	creationTime: 'Creation Time',
	whole: 'Whole',
	date: 'Date',
	currentlyEnabledScheme: 'Currently Enabled Scheme',
	newScheme: 'New Scheme',
	removeScheme: 'Remove Scheme',
	enableScheme: 'Enable Scheme',
	copyScheme: 'Copy Scheme',
	updateTask: 'Update Task',
	delete: 'Delete',
	refresh: 'Refresh',
	to: 'To',
	audioUpload: 'Audio Upload',
	mediaCategory: 'Media Category',
	mediaDetails: 'Media Details',
	fileName: 'File Name',
	duration: 'Duration',
	uploader: 'Uploader',
	uploadTime: 'Upload Time',
	audit: 'Audit',
	auditStatus: 'Audit Status',
	reviewer: 'Reviewer',
	auditTime: 'Audit Time',
	audition: 'Audition',
	audioMediaName: 'Audio Media Name',
	textView: 'Text View',
	reviewComments: 'Review Comments',
	newlyAdded: 'Newly Added',
	newlyAdded2: 'Newly Added ',
	categoryName: 'Category Name',
	category: 'Category',
	edit: 'Edit',
	mediaLevel: 'Media Level',
	uploadFile: 'Upload File',
	clickUpload: 'Click Upload',
	determine: 'Determine',
	cancel: 'Cancel',
	createText: 'Create Text',
	textSpeechSynthesis: 'Text Speech Synthesis',
	findingsOfAudit: 'Findings Of Audit',
	mediaType: 'Media Type',
	activation: 'Activation',
	activateTerminal: 'Activate Terminal',
	terminal: 'Terminal',
	terminalName: 'Terminal Name',
	equipment: 'Equipment',
	name: 'Name',
	groupName: 'Group Name',
	terminalNumber: 'Channel number',
	groupingName: 'Grouping Name',
	status: 'Status',
	activatePersonnel: 'Activate Personnel',
	activateTime: 'Activate Time',
	servicePeriod: 'Service Period',
	specialSettings: 'Special Settings',
	quit: 'quit',
	monitoringAndMonitoring: 'Monitoring And Monitoring',
	callObjectSettings: 'Call Object Settings',
	commandControl: 'Command Control',
	onlineRecord: 'Online Record',
	physicalAddress: 'PhysicalAddress',
	warehousingStatus: 'Warehousing Status',
	newEquipmentGrouping: 'New Equipment Grouping',
	newEquipmentGrouping2: '新增设备分组',
	modifyEquipmentGrouping: 'Modify Equipment Grouping',
	groupNumber: 'Group Number',
	type: 'Type',
	coverage: 'Coverage',
	timeFrame: 'Time Frame',
	broadcastStatisticalMethod: 'Broadcast Statistical Method',
	broadcastType: 'Broadcast Type',
	user: 'User',
	area: 'Area',
	playbackTimes: 'Playback Times',
	startplay: 'Start Broadcasting',
	seconds: 'Second',
	download: 'Download',
	lineChart: 'Line Chart',
	barChart: 'Bar Chart',
	lastOfflineTime: 'Last Offline Time',
	offlineDays: 'Offline Days',
	exportTerminalInformation: 'Export Terminal Information',
	securitySetting: 'Security Setting',
	personalData: 'Personal Data',
	orderRecord: 'Order Record',
	account: 'Account',
	addTo: 'Add To',
	moveUp: 'Move Up',
	moveDown: 'Move Down',
	callObjectList: 'Call Object List',
	callObjectAddition: 'Call Object Addition',
	accountNumber: 'Account Number',
	accountLevel: 'Account Level',
	removeUserGroup: 'Remove User Group',
	fullName: 'Full Name',
	mailbox: 'Mailbox',
	mobilePhone: 'Mobile Phone',
	requeirPhone: 'Please enter phonenumber',
	sex: 'Sex',
	role: 'Role',
	userGroup: 'User Group',
	addToUserGroup: 'Add To User Group',
	addUserGroupMembers: 'Add User Group Members',
	managementArea: 'Management Area',
	mapPositioning: 'Map Positioning',
	registrationTime: 'Registration Time',
	yourBasicInformation: 'Basic Information',
	yourSecurityServices: 'Security Services',
	safetyLevel: 'Safety Level',
	loginPassword: 'Login Password',
	bindMailbox: 'Bind Mailbox',
	bindingMobilePhone: 'Binding Mobile Phone',
	changePassword: 'Change Password',
	modifyMobilePhone: 'Modify Mobile Phone',
	addEmail: 'Add Email',
	replaceMailbox: 'Replace Mailbox',
	replacementArea: 'Replacement Area',
	low: 'Low',
	middle: 'Middle',
	high: 'High',
	male: 'Male',
	female: 'Female',
	currentAvatar: 'Current Avatar',
	changeTheAvatar: 'Change The Avatar',
	other: 'Other',
	preservation: 'Preservation',
	registeredAccount: 'Registered Account',
	roleAuthorization: 'Role Authorization',
	fixedLocation: 'Fixed Location',
	createUserGroup: 'Create User Group',
	userGroupName: 'User Group Name',
	creator: 'Creator',
	groupMembers: 'Group Members',
	addInitialArea: 'Add Initial Area',
	areaCode: 'Area Code',
	areaCoding: 'Area Coding',
	Superior: 'Superior ',
	areaName: 'Area Name',
	regional: {
		fullName: 'Full Name',
	},
	areaFullName: 'Area Full Name',
	longitudeAndLatitude: 'Longitude And Latitude',
	longitude: 'Longitude',
	currentLongitude: 'Current Longitude',
	latitude: 'Latitude',
	currentLatitude: 'Current Latitude',
	addChildRegion: 'Add Child Region',
	newRole: 'New Role',
	modifyRole: 'Modify Role',
	rolePermissions: 'Role Permissions',
	roleID: 'Role ID',
	roleName: 'Role Name',
	describe: 'Describe',
	firmwareUpload: 'Firmware Upload',
	firmwareFile: 'Firmware File',
	versionCode: 'Version Code',
	versionName: 'Version Name',
	equipmentUpgrade: 'Equipment Upgrade',
	upgradeRecord: 'UpgradeRecord',
	upgradeTarget: 'Upgrade Target',
	equipmentName: 'Equipment Name',
	firmwareVersion: 'Firmware Version',
	firmwarePackageName: 'Firmware Package Name',
	forcedUpgrade: 'Forced Upgrade',
	upgradeNow: 'Upgrade Now',
	anyPointInTime: 'Any PointIn Time',
	taskCreationTime: 'Task Creation Time',
	upgradeAccordingToTheSpecifiedTime: 'Upgrade According To The Specified Time',
	deviceSoftwareVersion: 'Device Software Version',
	force: 'Force',
	upgradeTime: 'Upgrade Time',
	upgradeProgress: 'Upgrade Progress',
	cancelExecution: 'Cancel Execution',
	logLevel: 'Log Level',
	logContent: 'Log Content',
	logTime: 'Log Time',
	news: 'News',
	warning: 'Warning',
	systemName: 'System Name',
	softwareAbbreviation: 'Software Abbreviation',
	systemAbbreviation: 'System Abbreviation',
	softwareVersion: 'Software Version',
	SoftwareName: 'Software Name',
	copyright: 'Copyright',
	serviceProviderAddress: 'Service Provider Address',
	serviceProvider: 'Service Provider',
	modifyPlatformParameters: 'Modify Platform Parameters',
	retry: 'Retry',
	password: 'Password',
	onTheAir: 'On The Air',
	notbroadcast: 'Not broadcast',
	auditReceipt: 'AuditReceipt',
	inIntercom: 'InIntercom',
	notWarehoused: 'Not Warehoused',
	warehoused: 'Warehoused',
	auditFree: 'Audit Free',
	notApproved: 'Not Approved',
	Approved: 'Approved',
	passTheAudit: 'Pass TheAudit',
	failedToPassTheReview: 'Failed To Pass The Review',
	schemeName: 'Scheme Name',
	playbackMode: 'Playback Mode',
	broadcastAudio: 'Broadcast Audio',
	broadcastNumbers: 'Broadcast Numbers',
	numberOfCycles: 'Number Of Cycles',
	grouping: 'Grouping',
	partition: 'Partition',
	airDate: 'Air Date',
	broadcastTimes: 'Broadcast Times',
	broadcastCycle: 'Broadcast Cycle',
	Monday: 'Monday',
	Tuesday: 'Tuesday',
	Wednesday: 'Wednesday',
	Thursday: 'Thursday',
	Friday: 'Thursday',
	Saturday: 'Saturday',
	Sunday: 'Sunday',
	writtenWords: 'Written Words',
	audioFile: 'Audio File',
	radioStation: 'Radio Station',
	radioRelay: 'Radio Relay',
	streamingAddress: 'Streaming Address',
	streamingAddressRelay: 'Streaming Address Relay',
	triggerEvent: 'Trigger Event',
	stopBroadcastingMode: 'Stop Broadcasting Mode',
	fault: 'Fault',
	numberOfTerminals: 'Number Of Terminals',
	terminalList: 'Terminal List',
	singleCycle: 'Single Play',
	loopPlayback: 'Loop Playback',
	remark: 'Remark',
	time: 'Time',
	addMediaCategory: 'Add Media Category',
	modifyMediaCategory: 'Modify Media Category',
	modifyText: 'Modify Text',
	modifyUserGroup: 'Modify UserGroup',
	modifyArea: 'Modify Area',
	pleaseEnterYourName: 'Please Enter Your Name',
	pleaseEnterTheAccountNumber: 'Please Enter The Account Number',
	service: 'Service',
	playing: 'Playing',
	playbackSuccessful: 'Playback Successful',
	playbackFailed: 'Playback Failed',
	Selectedequipment: 'Selected Equipment',
	applicationmanagement: 'Application Management',
	Versionnumber: 'Version Number',
	yes: 'Yes',
		billingmode: 'Service billing mode',
		ordermanagement: 'order management',
		Daterange: 'Date Range',
		invoice: 'Invoice',
		Gocheck: 'Go Check',
		second: 'Second',
		Price: 'Price',
		invoicingmanagement: 'Invoicing Management',
		Invoicingrequisition: 'Invoicing Requisition',
		Medialist: 'Media List',
		Broadcastcontrol: 'Broadcast Control',
		Stopbroadcasting: 'Stop Broadcasting',
		Broadcastcontent: 'Broadcast Content',
		Oldpassword: 'Old Password',
		NewPassword: 'New Password',
	account2: 'Account',
		smsservice: 'SMS service',
		Reset: 'Reset',
		info: 'Release of Information',
		mediaassets: 'Media Assets Management',
		picture: 'Picture',
		video: 'Video',
		music: 'Music',
		smartscreenname: 'Smart screen',
		smartscreen: 'Smart Screen Management',
		ljqy: 'Logical region',
		equipmentdetail: 'Equipment Details',
		screenrelease: 'Publishing on screen',
	realtimerelease: 'Instant publishing',
	timedrelease: 'Scheduled release',
		no: 'No',
		releasehistory: 'Release record',
		zdai: 'Terminal AI task',
		Pleaseinputapassword: 'Please input a password',
		Pleaseentertheverificationcode: 'Please enter the verification code',
		ServerAddress: 'Server Address',
		smdl: 'Scan the two-dimensional code to log in',
		zcjbsty: 'To register is to give consent',
		Theserviceagreement: 'The service agreement',
		Privacypolicy: 'Privacy policy',
		Legalnotices: 'Legal notices',
		Clientserviceprotocol: 'Client service protocol',
		ji: 'and',
		gxxy: 'Please check that you have read and agree to the above agreement',
		azapph: 'After installing the mobile APP, scan the QR code with the APP to obtain the server address',
		syllq: 'Please use the mobile browser, Alipay, Taobao, QQ and other apps to scan the code to download the mobile client',
		Downloadthepicture: 'Download the picture',
		zwkf: 'This feature is not yet enabled',
		bnsykg: "You can't use Spaces",
		qsrmm: 'Please enter a password of 8-20 digits',
		qsryzh: 'Please enter the account name you need to retrieve your login password',
		Existingaccount: 'Existing account',
		Loginimmediately: 'Login immediately',
		hqyzm: 'Obtaining the Verification Code',
		yzdshj: 'Verified the mobile phone number',
		Resend: 'Resend',
		Pleaseenteranewpassword: 'Please enter a new password',
		qsrszzm: 'Please enter numbers, letters',
		Aboutus: 'About us',
		qsrzcxm: 'Please enter your registration name',
		aitask: 'Terminal AI task',
		createGroup: 'createGroup',
		广播用户权限: 'Broadcast User permissions',
		超级管理员权限: 'Super administrator permissions',
		运维: 'operations',
		超管: 'Super administrator',
		系统维护人员: 'System maintenance Personnel',
		播音员: 'Announcer',
		广播用户权限: 'Broadcast User permissions',
		aiWarning: 'AI Warning',
		aiDevice: 'AI Device',
		screenView: 'Screen View',
		aiMap: 'AI Map',
		aiAlarm: 'AI Alarm',
		Adddevice: 'Add Equipment',
		EquipmentNo: 'Equipment No',
		channel: 'passageway',
		aiLinkage: 'Broadcast linkage configuration',
		operatorManage: 'Operator Management',
		customManage: 'customer management',
		receivingManage: 'Receiving Terminal Management',
		collectingManage: 'Collection device management',
		talkingManage: 'Intercom terminal management',
		smartScreenManage: 'Smart screen management',
		homeContent: 'Propaganda copy',
		timingLive: 'Scheduled live streaming',
		Preview: 'preview',
		create: 'New',
		helpTitle: 'Help Documents',
		appSlideTitle: 'APP rotation document',
		title: 'title',
		author: 'author',
		release: 'release',
		cover: 'cover',
		abstract: 'abstract',
		fams: 'Scheme Description',
		modify: 'modify',
		scheme: 'programme',
		jrsd: 'Festival settings',
		holidayName: 'Festival Name',
		rwlb: 'task list',
		broadcastlinkage: 'Broadcast linkage configuration',
		fullscreen: 'Full screen',
		mobileterminal: 'Mobile end',
		Listeningbackup: 'Listening backup',
		yjgb: 'Emergency broadcasting',
		wjtsgb: 'File Push Broadcast',
		dsbfgb: 'Scheduled broadcast playback',
		gisdtgb: 'GIS map broadcasting',
		tjyabj: 'Add emergency plan alarm',
		yjbj: 'Emergency alarm',
		cybj: 'Common alarms',
		zdybj: 'Custom alarms',
		qxzyjbj: 'Please select an emergency alarm',
		qxztb: 'Please select an icon',
		Icon: 'icon',
		zdytb: 'Custom Icon',
		xzwj: 'Select File',
		yajc: 'Plan abbreviation',
		bdyjya: 'Bind emergency plans',
		qsryajc: 'Please enter the plan abbreviation',
		qxzyjya: 'Please select an emergency plan',
		qsrgjz: 'Please enter keywords',
		tpcj: 'KJNova Clipper ',
		amplify: 'enlarge',
		reduce: 'narrow',
		leftrotate: 'Left rotation',
		rightrotate: 'Right rotation',
		jingjie: 'Alert',
		fangdao: 'Anti theft',
		hongzai: 'flood',
		gongong: 'public health',
		tongyong: 'General icons',
		yjyglb: 'Emergency Plan List',
		huozai: 'fire',
		dizhen: 'earthquake',
		shusan: 'evacuate',
		fangkong: 'air defense',
		kongxi: 'Air raid',
		dizhenyanlian: 'Earthquake drill',
		longjuanfeng: 'tornado',
		jiechu: 'Release Alert',
		help: 'help',
		rmwl: 'Hot Issues',
		linqi: 'Due/Expired',
		scyabj: 'Delete Plan Alarm',
		qdscya: 'Are you sure you want to delete this plan alarm',
		zdzxs: 'Number of online terminals',
		zdzs: 'Total number of terminals',
		qdqdya: 'Are you sure you want to activate this contingency plan alarm',
		xtts: 'System prompt',
		gbcs: 'Number of broadcasts',
		zwsj: 'There is currently no data available',
		tpgsyq: 'Uploading images can only be in JPG or PNG format',
		loop: 'loop',
		qsrnr: 'Please enter content',
		qbzd: 'All terminals',
		qxzbcyp: 'Please select the audio to broadcast',
		qgxbcmb: 'Please check the broadcast target',
		qiege: 'Cut Song',
		shangyiqu: 'Previous song',
		xiayiqu: 'Next song',
		zanting: 'suspend',
		qsrrwmc: 'Please enter a task name',
		qsrxhcs: 'Please enter the number of cycles',
		qsryxj: 'Please enter a priority',
		qsryl: 'Please enter the volume',
		qxzbcrq: 'Please select a broadcast date',
		qgxbczq: 'Please check the broadcast period',
		qsrldz: 'Please enter the stream address',
		qsrwz: 'Please enter text',
		qxzcjsb: 'Please select a collection device',
		qxzyytd: 'Please select a sound source channel',
		szyd: 'The larger the number, the higher the priority for broadcasting',
		yxgq: 'Selected Song',
		gbmbqbzd: 'Broadcast target is all terminals',
		fzxdzdlb: 'List of terminals under grouping',
		yytd: 'Sound source channel',
		upan: 'USB flash drive',
		none: 'None',
		ydjc: 'Movement detection',
		rqqy: 'Intrusion Zone',
		rxjc: 'Human form detection',
		tljc: 'Stay detection',
		zdtb: 'Automatic stop broadcasting',
		sdtb: 'Manual stop broadcasting',
		qsrmc: 'Please enter a name',
		sxtj: 'Effective conditions',
		ryzt: 'Any state',
		zxzt: 'Online status',
		cxsc: 'Duration',
		xgssfz: 'Modify Group',
		qxzbcsj: 'Please select the broadcast time',
		jssjbnxykssj: 'The end time cannot be less than the start time',
		bcscbnxyypsc: 'The broadcast duration cannot be less than the audio duration',
		qdgbgb: 'Are you sure you want to turn off the broadcast',
		canceled: 'Canceled',
		zdwjsl: 'You can only select up to 50 audio files',
		sbcj: 'Device Collection',
		Hour: 'Hour',
		rwxq: 'Task Details',
		gblb: 'Broadcast List',
		bcz: 'Saving',
		plsc: 'Batch deletion',
		qdsczxrwm: 'Are you sure you want to delete these tasks',
		qxzyscdrw: 'Please select the task to delete',
		qdyscgbm: 'Are you sure you want to delete the broadcast',
		qsrfamc: 'Please enter a scheme name',
		qxxzfa: 'Please select a solution first',
		scfahscrw: 'When deleting a scheme, all tasks under the scheme will be deleted. Are you sure you want to delete the scheme',
		sxsj: 'entry-into-force time',
		qdyscrwm: 'Are you sure you want to delete the task',
		qdyqdgbm: 'Are you sure you want to start broadcasting',
		bcmb: 'Broadcast target',
		qidong: 'start-up',
		sftcdl: 'Do you want to log out',
		xxbcz: 'Message does not exist',
		ptjb: 'Normal level',
		aqjb: 'Security level',
		mtwjzctips: 'Only supports MP3/wav format, and the size of a single file uploaded cannot exceed {a}MB. Please do not upload illegal, illegal, or unhealthy content, otherwise you will bear necessary legal responsibilities',
		mtwjzctips: 'Only supports MP3/wav format, Please do not upload illegal, illegal, or unhealthy content, otherwise you will bear necessary legal responsibilities',
		qxmtlb: 'Please select a media category',
		qxzmtjb: 'Please select a media level',
		qscypwj: 'Please upload an audio file',
		share: 'sharing',
		scypz: 'Uploading audio',
		xiaofeng: 'Male voice - Xiaofeng',
		xiaoyan: 'Female Voice - Xiaoyan',
		mypzcos: 'Tencent COS information not configured',
		qxzyscdmtwj: 'Please select the media file to delete',
		qdysczxmtwjm: 'Are you sure you want to delete these media files',
		qdycmtwj: 'Confirm removal',
		scdypwjgs: 'The uploaded audio file format can only be MP3/wav format',
		cfscwj: 'The file already exists, please do not upload it again',
		scddgwjdx: 'Uploaded audio files cannot exceed {a}MB per file',
		qdscmtwj: 'Are you sure you want to delete {a}',
		aqez: 'Note: Please do not upload illegal, irregular, or unhealthy content, otherwise you will bear necessary legal responsibilities',
		qsrwbmc: 'Please enter a text name',
		qxzyscdwj: 'Please select the file to delete',
		qdysczxwjm: 'Are you sure you want to delete these files',
		nqdyscwbwjm: 'Are you sure you want to delete the text file',
		qxzyscdmtlb: 'Please select the media category to delete',
		xtmrlbbyxsc: 'The system default category cannot be deleted',
		qrscmtlb: 'Are you sure you want to delete media category {a}',
		qxxzmtlbzjxxg: 'Please select a media category before making any modifications',
		xtmrlbbyxxg: 'The system default category cannot be modified',
		lbmcbt: 'Category name is required',
		sftgsh: 'Approved or not',
		sfbtgsh: 'Does it fail the audit',
		mgchh: 'Note: One word per line, use Enter to wrap',
		qsrmgc: 'Please enter sensitive words',
		nqdyscmgcm: 'Are you sure you want to delete the sensitive word {a}',
		hjjg: 'Call Results',
		hjcg: 'Call successful',
		hjsb: 'call failed',
		hjflx: 'Caller Type',
		hjf: 'Caller',
		bhjflx: 'Callee type',
		bhjf: 'Callee',
		hjsj: 'Call time',
		thsc: 'Talk time ',
		hjcs: 'Call timeout',
		dfjj: 'Rejected by the other party',
		dfzm: 'The other party is busy',
		qxhj: 'cancel call',
		wjt: 'Not connected',
		xfxhy: 'Fire signal source',
		xfxhysz: 'Fire signal source setting',
		SignalSourceDescription: 'Signal source description',
		SignalSourceEquipment: 'Signal source equipment',
		SignalNumber: 'Signal number',
		PleaseSelectAChannel: 'Please select a channel',
		unexpired: 'Unexpired',
		Expired: 'Expired',
		Advent: 'Dying period',
		periodOfValidity: 'Validity period',
		Map: 'atlas',
		DefaultVolume: 'Default Volume',
		BroadcastVolume: 'Broadcast volume',
		InstallationPosition: 'Installation position',
		IntercomVolume: 'Intercom volume',
		TaskPriority: 'Task Priority',
		FrequencySetting: 'Frequency setting',
		Frequency: 'frequency',
		GSignal: '4G signal',
		qsrssgjc: 'Please enter search keywords',
		qsrwldz: 'Please enter a physical address',
		qsrzdmc: 'Please enter the terminal name',
		qxztdbh: 'Please select a channel number',
		qxzzt: 'Please select a status',
		qsrdjyl: 'Please input the intercom volume',
		qxzrwyxj: 'Please select a task priority',
		UploadSuccessful: 'Upload successful',
		ReuploadImages: 'Click to upload the image again',
		GoBack: 'Return to the previous level',
		OptionalReceivingTerminal: 'Optional receiving terminal',
		OptionalReceivingDevice: 'Optional receiving device',
		SelectedReceivingTerminal: 'Selected receiving terminal',
		SelectedReceivingDevice: 'Selected receiving device',
		qsrfzmc: 'Please enter a group name',
		qsrfzbh: 'Please enter the group number',
		PleaseSelectTheReceivingTerminal: 'Please select the receiving terminal',
		PleaseSelectTheReceivingDevice: 'Please select the receiving device',
		nqdyscfzm: 'Are you sure you want to delete group {a}',
		SDcardCapacity: 'SD card capacity',
		SDcardStatus: 'SD card status',
		NetworkMode: 'Network mode',
		Normal: 'Normal',
		Bbnormal: 'Bbnormal',
		Unrecognized: 'Unrecognized',
		FormatSDCard: 'Format SD card',
		Record: 'record',
		Wired: 'Wired',
		yunyingshang: 'Operator',
		UsedTraffic: 'Used traffic',
		DeviceLog: 'Device Log',
		GetLog: 'Get logs',
		GSignalRecord: '4G signal recording',
		SignalValue: 'Signal value',
		FormatSDcardConfirmation: 'Formatting the SD card will clear all files inside the SD card. Are you sure you want to format the SD card',
		sending: 'send',
		RetransmissionCycle: 'Retransmission cycle',
		Instruct: 'Instruct',
		qsrhczq: 'Please enter the return period',
		qsrsz: 'please enter a number',
		qxzzl: 'Please select instructions',
		jiaoshi: 'Timing',
		tongbucanshu: 'Synchronization parameters',
		jixubofang: 'Continue playing',
		chongqi: 'restart',
		qingchu: 'Clear fire alarm status',
		nqdysczdm: 'Are you sure you want to delete terminal {a}',
		qxzyscdzd: 'Please select the terminal to delete',
		qdtsczxzdm: 'Are you sure you want to delete these terminals',
		Online: 'Online',
		Setting: 'Setting',
		ServiceExtranetAddress: 'Service Extranet Address',
		ServiceIntranetAddress: 'Service intranet address',
		Port: 'Port',
		tingji: 'Shutdown',
		fuji: 'Duplicate machine',
		qiangzhi: 'Forced Resurrection',
		jingtai: 'static state',
		simCardStatus: 'SIM card status',
		simCardControl: 'SIM card control',
		IPAddress: 'IP address',
		ziwangyanma: 'Subnet Mask',
		wangguan: 'gateway',
		MACAddress: 'MAC address',
		qxzfwqx: 'Please select the service period',
		qsrfwwwdz: 'Please enter the service internet address',
		qsrdk: 'Please enter the port',
		qsrfwnwdz: 'Please enter the service intranet address',
		qxzwlms: 'Please select network mode',
		qxzkzzl: 'Please select a control command',
		qxzysydh: 'Please select the row to move up',
		qxzyxydh: 'Please select the row to move down',
		dytbnbsy: 'Article 1 cannot be moved up',
		zhytbnbxy: 'The last item cannot be moved down',
		nqdyscghjdxm: 'Are you sure you want to delete the calling object',
		nqdytjwhjdxm: 'Are you sure you want to add as the calling object',
		djyx: 'Intercom priority',
		gbyx: 'Broadcasting priority',
		qdgbdgb: 'Are you sure you want to turn off {a}\'s broadcast',
		ImportDevice: 'Import Device',
		plsx: 'Batch review',
		plck: 'Batch outbound',
		ActiveState: 'Activation status',
		Active: 'Activated',
		NotActive: 'not active',
		nqdyscgsbm: 'Are you sure you want to delete this device',
		fwzt: 'Service status',
		yyyh: 'Operational Users',
		qsrybddyyyh: 'Please enter the operational user to bind',
		qsrsbmc: 'Please enter a device name',
		qxzyscdsb: 'Please select the device you want to delete',
		qdysczxsbm: 'Are you sure you want to delete these devices',
		qxzyshdsb: 'Please select the device to be audited',
		scdwjstxt: 'The uploaded file can only be in TXT format',
		scdwjdx: 'The uploaded file cannot exceed 1MB',
		qianjin: 'advance',
		houtui: 'Backward',
		mygdsjl: 'There\'s no more data left',
		EquipmentOnlineRate: 'Equipment online rate',
		zdfb: 'Terminal distribution',
		zdgbzt: 'Terminal broadcast status',
		Unit: 'Unit',
		qxzdgb: 'Circle terminal broadcast',
		yjhh: 'One click shout',
		qsrmccx: 'Please enter a name to query',
		TerminalView: 'Terminal View',
		Rectangle: 'Rectangle',
		Circle: 'rotundity',
		polygon: 'polygon',
		zzgb: 'be on the air',
		Satellite: 'Satellite',
		zzhhgb: 'Calling for a broadcast',
		qxzgbzd: 'Please select a broadcasting terminal',
		zcsr: 'Enter again',
		jymmts: 'A password with high security can make the account more secure. We suggest that you regularly change your password, which can be a combination of 8 to 20 digits, letters, or special symbols.',
		bdyxts: 'Binding an email can be used to retrieve the login password',
		bdsjts: 'Binding the phone can be used to retrieve the login password',
		djtpkgh: 'Click on the image to replace it',
		ddxq: 'Order details',
		fukuanren: 'Payer',
		jine: 'money',
		qsryx: 'Please enter your email address',
		qsrzqdyxdz: 'Please enter the correct email address',
		Tall: 'Tall',
		Centre: 'Centre',
		Low: 'Low',
		lcsrdmmbyz: 'The passwords entered twice do not match',
		qsrzqdsjh: 'Please enter the correct phone number',
		qxzxb: 'Please select gender',
		qxzqy: 'Please select a region',
		nqdyzxczhm: 'Are you sure you want to cancel this account',
		zxzh: 'Account cancellation',
		qgxjs: 'Please check the role',
		wenxintishi: 'Kind reminder',
		cjfzdwxts: 'The creator of the user group is the user group manager. After joining the user group, your device will be shared with other users within the user group',
		cjfzdwxts2: 'Each user can only create one user group, and each user can only join one user group',
		qdycyhm: 'Are you sure to remove {a}',
		yyyhtjsb: '{a} The user already has a user group, adding failed',
		byxcftj: 'Do not allow duplicate additions',
		byxycyhcyzs: 'Removing user members themselves is not allowed',
		qsryhzmc: 'Please enter the user group name',
		qsrhzzmsz: 'Please input Chinese characters, letters, or numbers',
		yhzcybnwk: 'User group member cannot be empty, please add a user group member',
		nqdyscyhzm: 'Are you sure you want to delete the user group',
		nqdytcyhzm: 'Are you sure you want to exit the user group',
		xzxjqy: 'Add Subordinate Regions',
		sjqybm: 'Superior Region Code',
		sjqymc: 'Name of superior region',
		xzqybm: 'Add Area Code',
		xzqymc: 'New Area Name',
		xzqyqc: 'Add full name of the area',
		qsrgdsz: 'Please enter ${a} digits',
		qsrqybm: 'Please enter the region code',
		qsrqymc: 'Please enter a region name',
		jdsryw: 'Longitude input error',
		wdsryw: 'Latitude input error',
		tjxgqy: 'Add/Modify Area',
		nqdyscqym: 'Are you sure you want to delete the area',
		scqy: 'Delete Region',
		qsrjsmc: 'Please enter a role name',
		qxzjsqx: 'Please select role permissions',
		qsrms: 'Please enter a description',
		nqdyscjsm: 'Are you sure you want to delete the role',
		scjs: 'Delete Role',
		nqdyscgjm: 'Are you sure you want to delete the firmware',
		qxscgj: 'Please upload the firmware first',
		yylx: 'Application Type',
		yycxsc: 'Application Upload',
		yylxpz: 'Application Type Configuration',
		gxnr: 'Update content',
		scz: 'Uploading',
		sfqzsj: 'Whether to force upgrade',
		yycxwj: 'Application files',
		tzwj: 'Drag and drop files or click on images to add files and start uploading',
		yylxmc: 'Application Type Name',
		lxgjz: 'Type Keyword',
		qsrlxgjz: 'Please enter the type keyword',
		qsryylxmc: 'Please enter an application type name',
		qxzyylx: 'Please select an application type',
		qxzscwj: 'Please select the upload file',
		scwjmcbgf: 'The uploaded file name is not standardized',
		nqdyscyym: 'Are you sure you want to delete the app',
		nqdyscgyylxm: 'Are you sure you want to delete this application type',
		xjwa: 'New copy',
		nqdysccwam: 'Are you sure you want to delete this copy',
		tjcc: 'Recommended size',
		qsrbt: 'Please enter a title',
		qsrwanr: 'Please enter the copy content',
		qscfmt: 'Please upload the cover image',
		sucaiku: 'Material Library',
		bdsc: 'Local upload',
		laiyuan: 'source',
		wllj: 'Network Link',
		Upload: 'upload',
		sctctips: 'Only. jpg,. png,. mp3,. mp4, and. flv files can be uploaded, and the length should not exceed 20m',
		Tag: 'label',
		crsc: 'Insert Material',
		fabuyu: 'Published on',
		wzx: 'unexecuted',
		yzx: 'executed',
		qxzx: 'Cancel Execution',
		qxzgjbb: 'Please select firmware version',
		qgxsb: 'Please check the device',
		nqdyqxzxrwm: 'Are you sure you want to cancel the upgrade task',
		ptxx: 'Platform Information',
		dbzxj: 'Bottom bottom left corner',
		dbjz: 'Bottom centered',
		dbyxj: 'Bottom bottom right corner',
		dibjz: 'Top centered',
		xswz: 'Display position',
		qxzxswz: 'Please select a display location',
		xxfb: 'Message release',
		MessageTitle: 'Message Title',
		MessageContent: 'Message content',
		MessageObject: 'Message Object',
		fbxx: 'Post a message',
		fbTime: 'Release time',
		Object: 'object',
		qgxxdx: 'Please check the message object',
		nqdyscxxm: 'Are you sure you want to delete the message',
		tjlmtfw: 'Add streaming service',
		xglmtfw: 'Modifying Streaming Services',
		文件服务器: 'File Server',
		turn服务器: 'Turn Server',
		kms服务器: 'KMS server',
		业务服务器: 'Business Server',
		信令服务器: 'signaling server ',
		mtfwlx: 'Media Service Type',
		sfqy: 'Enable or not',
		mtfwmc: 'Media Service Name',
		mtfwdz: 'Media Service Address',
		qsrmtfwmc: 'Please enter a media service name',
		qsrmtfwdz: 'Please enter the media service address',
		fwqcc: 'Server Storage',
		coscc: 'Tencent Cloud Object Storage',
		qsrcctmc: 'Please enter the bucket name',
		qsrcctqy: 'Please enter the storage bucket area',
		qsrid: 'Please enter ID',
		qsrmy: 'Please enter the key',
		dymc: 'Geographical name',
		dxyyid: 'SMS application ID',
		dxqm: 'SMS Signature',
		zhmmmbid: 'Retrieve password template ID',
		khzh: 'Customer account',
		bdkh: 'Bind customers',
		jcbd: 'Unbind',
		xgzhxx: 'Modify account information',
		nqdyjcbdczhm: 'Are you sure you want to unbind this account',
	Back: 'Back',
	Play: 'Play',
	jypfxgyhzncy: '将音频分享给用户组内成员',
	jsbfxgyhzncy: '将设备分享给用户组内成员',
	AddTask: '添加任务',
	EditScheme: '编辑方案',
	dlsx: '登录失效',
	xjxcxcz: '先进行查询操作',
	ExportDevice: '导出设备',
	Agree: '同意',
	Refuse: '拒绝',
	InviteToJoin: '邀请加入',
	InvitedPleaseWait: '已邀请，等待对方同意',
	PlayedTask: '已播任务',
	PlayAgain: '再播一次',
	SureToPlayAgain: '确定要再播一次吗',
	NumberOfTaskStarts: '任务启动次数',
	startInterval: '启动间隔',
	minute: '分钟',
	BadRequest: '错误请求',
	LoginAgain: '未授权，请重新登录',
	NoAccess: '拒绝访问',
	NotFound: '请求错误,未找到该资源',
	RequestMethodNotAllowed: '请求方法未允许',
	RequestTimeout: '请求超时',
	ServerSideError: '服务器端出错',
	NetworkNotImplemented: '网络未实现',
	NetworkError: '网络错误',
	ServiceUnavailable: '服务不可用',
	NetworkTimeout: '网络超时',
	DoesNotSupport: 'http版本不支持该请求',
	ConnectionError: '连接错误',
	RequestCanceled: '请求已取消',
	ConnectionFailed: '连接到服务器失败',
	Loading: '加载中',
	LoginFirst: '请先登录',
	DefaultLanguage: '默认语言',
	LoggingOut: '退出登录中',
	SelectDate: '选择日期2',
	PleaseSelectDate: '请选择日期2',
	qiyonghou: '启用后，定时任务在节日日期不启动',
	PleaseEnterHolidayName: '请输入节日名称',
	AreYouSureToDeleteHoliday: '确定要删除该节日吗',
	AdvancedSetting: '高级设置',
	lianxiguanliyuan: '您没有当前操作的权限,请联系管理员',
	APPDownload: '安卓APP下载',
	Shares: '分享',
	MenuLoading: '菜单加载中',
	CurrentVersion: '当前版本',
	ServiceMonitoring: '服务监控',
	ServerMonitor: '服务器监控',
	ServeName: '服务名称',
	RunningState: '运行状态',
	NormalOperation: '运行正常',
	cpuper: 'CPU使用率',
	memoryper: '内存使用率',
	diskper: '磁盘使用率',
	bandwidthper: '宽带使用',
	Usage: '使用',
	OutputBandwidth: '出带宽',
	IncomingBandwidth: '入带宽',
	DisplayInterval: '显示间隔',
	DeleteCopy: '删除文案',
	audioRecord: '音频录制',
	RecordingAudio: '录制音频',
	SoundRecording: '录音',
	Microphone: '麦克风',
	StartRecording: '开始录制',
	StopRecording: '停止录制',
	Recording: '正在录制',
	RecordUpload: '录制上传',
	ReRecord: '重新录制',
	ConfirmUpload: '确认上传',
	NotSupported: '暂不支持',
	NotSupportedForDisplayingContent: '暂不支持显示内容',
	RealTime: '实时',
	Nearly24hours: '近24小时',
	InThePastThreeDays: '近三天',
	Everyday: '每天',
	Mondays: '每周一',
	FirstFayOfEachMonth: '每月一日',
	BackupTime: '备份时间',
	BackedUpTimes: '已备份次数',
	BackupObject: '备份对象',
	Timing: '选择时间',
	Database: '数据库',
	PleaseSelectTime: '请选择时间',
	CreateScheduledBbackupTask: '创建定时备份任务',
	BackupNow: '立即备份',
	PleaseSelectBackupObject: '请选择备份对象',
	AreYouSureToDeleteTheBackupTask: '你确定要删除备份任务吗',
	BackupFiles: '备份文件',
	Size: '大小',
	AreYouSureToDeleteTheBackupFiles: '你确定要删除备份文件吗',
	RecordNeedHttps: '录制功能需要以https方式访问',
	Export: '导出',
	timedCalibration: '全网定时校时',
	Horn: '喇叭',
	LoadExternalNetworkResources: '是否加载外网资源',
	HTTPSAccessAddress: 'https访问地址',
	ConstantVoltageSwitching: '定压切换',
	ReplicationTask: '复制任务',
	SchemeBelongs: '所属方案',
	OpenRegistration: '开放注册',
	ssylbfsc: '实时预览播放时长',
	SavePreview: '保存预览记录',
	bcscdyypsc: '建议播出时长大于音频时长',
	RefreshDeviceStatus: '刷新设备状态',
	BroadcastNeedHttps: '喊话广播功能',
	NeedHttps: '需要以https方式访问',
	cjqgb: '采集器广播',
	transcodeStatus: '转码状态',
	untranscode: '未转码',
	transcoded: '转码完成',
	transcodefail: '转码失败',
	transcodewait: '等待转码',
	transcodeing: '正在转码',
	UserAgreement: 'User Agreement',
	TriggerChannel: 'Trigger Channel',
	TaskType: '任务类型',
	HongWaiChuFa: '红外触发',
	XiaoFangChuFa: '消防触发',
	Terminal: "终端",
	SmartHost: '智能主机',
	PowerSupplyStatus: '供电状态',
	NormalMainsPowerSupply: '市电正常',
	UtilityFailure: '市电停电',
	PowerAmplifierPartitionNotes: '功放分区备注',
	NoteName: '备注名称',
	Liaison: '联系人',
	Telephone: '联系电话',
	PowerAmplifierOn: '开启功放电源',
	PowerAmplifierOff: '关闭功放电源',
	TurnOnTheAmplifierInAdvance: '提前开启功放',
	PowerAmplifierPartition: '功放分区',
	SelectPowerAmplifierPartition: '请选择功放分区',
	AnjianRenwu: '音乐广播转终端按键任务',
	QuanQuKaiQi: '功放全区开启',
	QiJinYongSheBei: '启/禁用设备',
	TriggerMode: '触发模式',
	ImportTip1: '拖到文件到此处，或点击上传',
	DownloadTemplate: '下载模板',
	InvalidFileSuffix: '文件格式仅支持：{0}',
	ContentQuery: '内容查询',
	Played: '已播',
	Unplayed: '未播',
	DeleteShortcutMenu: '删除快捷菜单',
	SureToDeleteShortcutMenu: '确定要删除此快捷菜单吗',
	AddShortcutMenu: '删除快捷菜单',
	ShortcutMenuList: '快捷菜单列表',
	UploadFileSizeLimit: '上传文件大小限制',
	BroadcastDefaultParameters: '广播默认参数',
	BroadcastingTargetType: '默认广播目标类型',
	DefaultbroadcastMode: '默认播出方式',
	Modulation: '音量调节',
	NewSchemeName: '新方案名称',
	TodayBroadcastPlan: '今日播出计划',
	BatchModifyTasks: '批量修改任务',
	BatchModify: '批量修改',
	NumberOfSelectedTasks: '已选任务个数',
	PleaseCheckTheParameters: '请勾选需要修改的参数',
	NoPermissionTemporarily: '暂无权限',
	bcscbnxyypsc2: '播出时长不能小于音频时长*播出次数',
	CurrentlyDoesNotSupportDisplayingContent: '暂不支持显示内容',
	AreYouSureToSaveIfThereIsAConflict: '以下任务执行时间有冲突，确定要保存吗',
    ...enLocale
}