import { secondaryPackagingRequest } from '@/request'
import i18n from '@/i18n'
//登陆接口
export const Login = data => secondaryPackagingRequest({
    url: '/auth/login',
    method: 'POST',
    headers: {
        'Content-Type': 'application/json'
    },
    data,
    isLoading: true,
    needToken: false,
    loadingText: '登录中...'
})
//登陆接口(无验证码)
export const autoLogin = data => secondaryPackagingRequest({
    url: '/auth/loginByNoCode',
    method: 'POST',
    headers: {
        'Content-Type': 'application/json'
    },
    data,
    isLoading: false,
    needToken: false,
})
//获取验证码
export const getCode = () => secondaryPackagingRequest({
    url: '/auth/getCode',
    isLoading: false,
    needToken: false
})
//获取用户菜单列表
export const getMenu = () => secondaryPackagingRequest({
    url: '/auth/menu',
    timeout: 10000,
    isLoading: false,
    needToken: true
})
//退出登陆
export const signOut = () => secondaryPackagingRequest({
    url: '/auth/signOut',
    isLoading: true,
    needToken: true,
    loadingText: `${i18n.t('LoggingOut')}...`
})
//获取用户菜单列表
export const getVersion = () => secondaryPackagingRequest({
    url: '/Api/getVersion',
    isLoading: false,
    needToken: false
})