<template>
  <div class="help-box">
	<div class="help-header" v-if="showHeader">
		<div class="ptitle"><el-button type="primary" class="back-btn" size="mini" @click="$router.back()">{{$t('Back')}}</el-button>{{helpData.title}}</div>
		<div class="pname"><span>{{helpData.author}}</span><span v-if="helpData.createTime">{{$t('fabuyu')}}{{helpData.createTime}}</span></div>
	</div>
	<div class="help-text">
		<div v-html="helpData.content"></div>
	</div>
  </div>
</template>

<script>
import { homeContentUnfo } from '@/request/api/homecontent';
export default {
  name: "ContentCom",
  components: {
  },
  props: {
	  id: {
		  type: String|Number,
		  default: ''
	  },
	  isTemplate: {
		  type: Number,
		  default: 1
	  },
	  operationData: {
		  type: Object,
		  default: function(){
			  return {}
		  }
	  },
	  showHeader: {
		  type: Boolean,
		  default: true
	  }
  },
  data() {
    return {
		helpData: {}
    }
  },
  methods: {
	  async getHelpDetail(){
		const {id} = this
		try{
			const {code, data} = await homeContentUnfo({id})
			if (code == 200) {
				this.helpData= data
			}
		}catch(error){
			console.log(error)
		}
	  },
    //页面初始化
    init() {
		if(this.isTemplate == 1){
			this.getHelpDetail()
		}else{
			this.helpData = this.operationData
		}
    }
  },
  mounted() {
    this.init()
  },
}
</script>
<style lang="less" scoped>
	.help-box{
		width: 70%;
		color: #333;
		margin: 0 auto;
	}
	.help-header{
		position: relative;
		.ptitle{
			font-size: 23px;
			font-weight: bold;
			padding: 30px 100px;
			min-height: 23px;
			text-align: center;
		}
		.pname{
			font-size: 14px;
			color: #aaa;
			span{
				margin-right: 20px;
			}
		}
	}
	.back-btn{
		display: block;
		position: absolute;
		left: 0;
		top: 50%;
		transform: translateY(-75%);
	}
	.help-text{
		margin-top: 20px;
		font-size: 16px;
		color: #333;
		line-height: 1.8;
		padding-bottom: 30px;
		/deep/ img{
			max-width: 100%;
		}
	}
</style>