<template>
	<div class="download-center">
		<div class="center-title">
			<p class="ptit"><span>{{$t('downloadCenter')}}</span>（{{$t('syllq')}}）</p>
		</div>
		<div class="center-list">
			<div class="item" v-for="(item,index) in pageList" :key="index">
				<div class="dtit">{{item.typeName}}</div>
				<div class="dimg" :class="{gray: item.fileName.indexOf('.apk') < 0}">
					<vue-qr ref="images" :margin="0" :text="item.path" :size="204" v-if="item.fileName.indexOf('.apk') > -1"></vue-qr>
					<span class="iconfont icon-a-45" v-else-if="item.fileName.indexOf('.exe') > -1"></span>
					<span class="iconfont icon-a-46" v-else></span>
					<img class="pic" src="../../assets/img/logo.png" alt="" v-if="item.fileName.indexOf('.apk') < 0">
				</div>
				<div class="dbtn">
					<a :href="item.url" class="btn bluebtn">{{$t('download')}}</a>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import VueQr from 'vue-qr'
	import { selectLatestAppGrouping } from "@/request/api/application"
	export default {
		props: {
		},
		data() {
			return {
				origin: '',
				pageList: [], //最新包列表
			}
		},
		components: {VueQr},
		methods: {
			// 获取列表
			async getList(){
				try {
					const { code, data, message } = await selectLatestAppGrouping()
					if(code == 200){
						let list = data.map((v, i) => {
							v.url = `${v.url}?n=${v.fileName}`
							v.path = `${location.origin}/#/index/downloaddetail/${v.typeCode}`
							return v
						})
						this.pageList = list
					}
				} catch (error) {
					error?.status && console.error(error?.status)
				}
			},
			/**初始化**/
			init() {
				this.origin = location.origin
				this.getList()
			},
		},
		mounted() {
			this.init()
		},
	}
</script>
<style lang="less" scoped>
	.download-center{
		padding-bottom: 1px;
	}
	.center-title{
		padding: 40px 0 23px 0;
		border-bottom: 1px solid #DCDCDC;
		.ptit{
			font-size: 12px;
			color: #999;
			span{
				font-size: 14px;
				color: #333;
			}
		}
	}
	.center-list{
		overflow: hidden;
		.item{
			float: left;
			width: 33.3%;
			text-align: center;
			padding-bottom: 54px;
		}
		.dtit{
			font-size: 14px;
			color: #333;
			margin-top: 30px;
		}
		.dimg{
			width: 71%;
			margin: 15px auto 30px auto;
			position: relative;
			&.gray{
				background: #F8F8F8;
			}
			img{
				display: block!important;
				width: 100%;
			}
			.pic{
				opacity: 0;
				visibility: hidden;
			}
			.iconfont{
				position: absolute;
				left: 50%;
				top: 50%;
				font-size: 44px;
				color: #999;
				transform: translate(-50%, -50%);
			}
		}
		.btn{
			display: block;
			width: 100px;
			height: 36px;
			line-height: 36px;
			border-radius: 4px;
			font-size: 16px;
			color: #fff;
			margin: 0 auto;
		}
	}
</style>
