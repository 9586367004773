<template>
  <div class="app" :class="isOverflowHidden">
    <el-container class="h100">
      <!-- 左侧菜单 -->
      <LeftMenu v-if="$route.meta.showLeftMenu || ($route.meta.isLargeFullScreen && !largeFullScreen)" />
      <!-- 右侧内容 -->
      <el-main class="main" :class="[hasMargin, scrollBarMarginTop, fullScreen]">
        <!-- 头部导航 -->
        <section class="header" :class="headerWidth" v-if="$route.meta.showNav || ($route.meta.isLargeFullScreen && !largeFullScreen)">
          <Nav />
          <!-- 面包屑 -->
          <Bread v-if="$route.meta.showBread" />
        </section>
		<div class="soft-name" :class="`name${platformInfo.position}`" v-if="platformInfo.softwareName && showSoftName" @mouseover="mouseOver">{{platformInfo.softwareName}}</div>
        <!-- 路由视图盒子 -->
        <section class="rv-box" :class="[userWhiteBgColor, headerisFixed]">
          <!-- 路由视图 -->
          <Content v-if="notReload" class="rv" :class="hasPadding" />
        </section>
      </el-main>
    </el-container>
	<!-- 二维码弹窗 -->
	<el-dialog :title="$route.name == 'login' ? $t('ServerAddress') : $t('APPDownload')" :visible.sync="codeVisble" :before-close="test" center width="600px">
		<codeForm ref="codeform"></codeForm>
		<span slot="footer" class="dialog-footer">
		  <!-- 分享按钮 -->
		  <el-button class="sharebtn" size="small" type="success" @click="$refs.codeform.toShare()">{{$t('Shares')}}</el-button>
		  <!-- 关闭按钮 -->
		  <el-button size="small" type="primary" @click="test">{{$t('close')}}</el-button>
		</span>
	</el-dialog>
    <transition name="load" appear>
      <div class="loading" v-show="isLoading"></div>
    </transition>
  </div>
</template>

<script>
import Bread from "./components/Bread/Bread"
import Nav from "./components/Nav/Nav"
import LeftMenu from "./components/LeftMenu/LeftMenu"
import Content from "./components/Content/Content"
import { mapState } from 'vuex'
import codeForm from "@/components/codeForm/codeForm"
import { menuList } from '@/request/api/role'
import { localSet } from "@/configs/storage"
export default {
  name: "App",
  components: {
    Nav,
    Bread,
    LeftMenu,
	codeForm,
    Content
  },
  data() {
    return {
      notReload: true, //是否重载
      isLoading: false, //是否显示加载条
      timer: null ,//加载条防抖定时器
	  showSoftName: true, //显示软件名称
    }
  },
  computed: {
    ...mapState("publicOptions", ["isCollapse", "codeVisble", "platformInfo", "largeFullScreen", "keepAliveComponents"]),
    //头部导航在折叠与展开时的宽度
    headerWidth() {
      return (this.isCollapse ? 'is-collapse' : 'no-collapse')
    },
    // 头部导航固定需要的占位边距
    headerisFixed() {
      const { module } = this.$route.meta
	  const name = this.$route.name
	  const isLargeFullScreen = this.$route.meta.isLargeFullScreen
      return module === 'home' ? 'mt60' : (module === 'login' || (isLargeFullScreen && this.$store.state.publicOptions.largeFullScreen) ? '' : 'mt123')
    },
    // 登录， 错误模块页面使用白色背景
    userWhiteBgColor() {
      const { module } = this.$route.meta
      return (module === 'login' || module === 'error' ? 'bg-white' : '')
    },
    // 是否需要内边距
    hasPadding() {
      const { module } = this.$route.meta
	  const name = this.$route.name
	  const isLargeFullScreen = this.$route.meta.isLargeFullScreen
      return (module === 'login' || module === 'error' || module === 'home' || (isLargeFullScreen && this.$store.state.publicOptions.largeFullScreen) ? '' : 'p10')
    },
    // 是否需要外边距
    hasMargin() {
      const { module } = this.$route.meta
	  const name = this.$route.name
      if (module === 'login' || module === 'error') {
        return ''
      } else {
        if (this.isCollapse) return 'ml64'
        else return 'ml210'
      }
    },
    // 是否隐藏滚动条
    isOverflowHidden() {
      const { module } = this.$route.meta
      return (module === 'login' ? 'ovhi' : '')
    },
    // 滚动条的上边距
    scrollBarMarginTop() {
      const { module } = this.$route.meta
      return (module === 'home' ? 'scroll-mt65' : 'scroll-mt125')
    },
	fullScreen(){
		if(this.largeFullScreen){
			return 'malf0'
		}else{
			return ''
		}
	}
  },
  mounted() {
    this.$store.dispatch('publicOptions/getplatformInfo', 'needload')
	this.getMenuList()
	// 切换语言时, 用到echarts的页面需要做局部刷新才能切换图表中的文字
    this.$bus.$on('switchLang', () => {
      this.notReload = false
      this.$nextTick(() => {
        this.notReload = true
      })
    })
  },
  methods: {
	  test(){
		this.$store.commit('publicOptions/SAVE_CODEVISBLE', false)
	  },
	  mouseOver(){
		if(this.platformInfo.position != 4){
			this.showSoftName = false
		}
	  },
	  async getMenuList(){
		  try {
		    const { code, data } = await menuList()
			if(code == 200){
				this.$store.commit('publicOptions/SAVE_MENULLIST', data)
				// localSet("menuList", data)
			}			
		  } catch (error) {
		  	error?.status && console.error(error?.status)
		  }
	  }
  },
  beforeDestroy() {
    this.$bus.$off('switchLang')
  },
  watch: {
    //横条加载动画 防抖
    $route() {
      this.showSoftName = true
	  if (this.timer) return
      this.isLoading = true
      this.timer = setTimeout(() => {
        this.isLoading = false
        this.timer = null
      }, 1000)
    }
  }
}
</script>

<style lang='less' scoped>
.app {
  height: 100%;
  .main {
    min-width: 840px;
    padding: 0;
    .header {
      min-width: 800px;
      position: fixed;
      top: 0;
      z-index: 2000;
    }
    .rv-box {
      padding: 0 20px 10px 25px;
      background-color: #F6F8F9;
      .rv {
        background-color: #fff;
        border-radius: 5px;
      }
      .app-enter {
        opacity: 0;
      }
      .app-enter-active {
        transition: all 0.8s linear;
      }
      .app-enter-to {
        opacity: 1;
      }
    }
  }
  .scroll-mt65::-webkit-scrollbar-track-piece {
    margin-top: 65px;
  }
  .scroll-mt125::-webkit-scrollbar-track-piece {
    margin-top: 125px;
  }
  .ml210 {
    margin-left: 240px;
  }
  .ml64 {
    margin-left: 64px;
  }
  .mt123 {
    margin-top: 105px;
  }
  .mt60 {
    margin-top: 60px;
  }
  .malf0{
  	margin-left: 0;
  }
  .is-collapse {
    width: calc(~'100% - 64px');
  }
  .no-collapse {
    width: calc(~'100% - 240px');
  }
  .loading {
    width: 100%;
    height: 3px;
    position: fixed;
    z-index: 2002;
    left: 0;
    top: 0;
    background-color: rgb(111, 208, 247);
  }
  .load-enter {
    transform: translateX(-100%);
  }
  .load-enter-active {
    transition: all 1s ease-in-out;
  }
  .load-enter-to {
    transform: translateX(0%);
  }
}
.soft-name{
	position: absolute;
	z-index: 2000;
	font-size: 16px;
	padding: 3px 5px;
	background-color: #fff;
	&.name1{
		left: 250px;
		bottom: 5px;
	}
	&.name2{
		left: 50%;
		bottom: 5px;
		transform: translateX(-50%);
		margin-left: 120px;
	}
	&.name3{
		right: 10px;
		bottom: 5px;
	}
	&.name4{
		left: 50%;
		top: 0;
		padding: 0;
		line-height: 50px;
		transform: translateX(-50%);
		margin-left: 120px;
	}
}
@media screen and (max-width: 1200px){
	.app{
		.ml210{
			margin-left: 140px;
		}
		.no-collapse{
			width: calc(100% - 140px);
		}
	}
}
</style>
