import Vue from 'vue'
import App from './App.vue'
import store from './store' 
import router from './router' 
import i18n from './i18n' 
import ElementUI from 'element-ui'
ElementUI.Dialog.props.closeOnClickModal.default = false
ElementUI.Dialog.props.closeOnPressEscape.default = false
ElementUI.Drawer.props.wrapperClosable.default = false
ElementUI.Drawer.props.closeOnPressEscape.default = false
ElementUI.Tooltip.props.enterable.default = false
ElementUI.Table.props.border.default = true
import BaiduMap from 'vue-baidu-map'
// import VueBMap from 'vue-bmap-gl';
import 'vue-bmap-gl/dist/style.css'
import VueRouter from 'vue-router'
import VueClipBoard from 'vue-clipboard2'

import VueEasyTree from "@wchbrad/vue-easy-tree"
// import "@wchbrad/vue-easy-tree/src/assets/index.less"

import 'element-ui/lib/theme-chalk/index.css'
import '@/assets/css/color.css'
import '@/assets/font/iconfont.css'
import '@/assets/css/common.css'
import '@/assets/css/reset.css'
import '@/assets/css/less/button.less'
import '@/assets/css/less/common.less'
import '@/assets/css/less/common_media.less'
import '@/assets/css/less/map.less'
import '@/configs/directive'

Vue.use(BaiduMap, {
  ak: 'SZJR0XTpPqHlJSkHdcXmCUvOE70nNgMx'
})

// import ViewUI from 'view-design';
// import 'view-design/dist/styles/iview.css';
// Vue.use(ViewUI);


// Vue.use(VueBMap)
// VueBMap.initBMapApiLoader({
//   ak: 'sWVzBQ8S13r7sBpUf3vKzK88sFsGmdpf',
//   v: '1.0'
// })
Vue.use(ElementUI) 
Vue.prototype.$elconfirm = Vue.prototype.$confirm 
Vue.use(VueRouter)
Vue.use(VueClipBoard)
Vue.use(VueEasyTree)

import {TimePicker, DatePicker} from 'ant-design-vue'
Vue.use(TimePicker)
Vue.use(DatePicker)

Vue.filter('dateFormat', function (value, format = 'hh:mm:ss') {
	if(!value && value != 0) return ''
	const splittext = format.split('hh')
	const splittext1 = splittext[1].split('mm')
	const h = splittext1[0]
	const m = splittext1[1].split('ss')[0]
	let hour = numFromat(parseInt(value / 3600))
	let hourYu = numFromat(value % 3600)
	let min = numFromat(parseInt(hourYu / 60))
	let sec = numFromat(hourYu % 60)
	if(hour == 0){
		return min + m + sec
	}else{
		return hour + h + min + m + sec
	}
	
	function numFromat(val){
		return val >= 10 ? val : '0'+ val
	}
})
Vue.filter('sizeFormat', function (value) {
	if(!value) return ''
	return (value / 1024 / 1024).toFixed(2) + 'MB'
})
Vue.filter('signalFormat', function (value) {
	if(!value) return ''
	return `<div>value</div>`
})
Vue.filter('lineFormat', function (value) {
	if(!value) return ''
	return value.replace(/-/g, '.')
})
Vue.filter('getIcon', function (value) {
	if(!value) return ''
	const {type, status} = value
	console.log(333,type, status)
	let icon = ''
	switch(type){
		case 4:
			icon = 'djcion'
			break;
		default:
			icon = 'jsicon'
			break;
	}
	let temp = status
	if(status == -1){
		temp = 'f'
	}
	return require(`@/assets/img/largedatascreen/${icon}${temp}.png`)
})

Vue.config.productionTip = false 
new Vue({
  render: h => h(App),
  i18n,
  store,
  router,
  beforeCreate() {
    Vue.prototype.$bus = this
  }
}).$mount('#app')