<template>
  <div class="nav-section">
	<div class="nav">
	  <!-- 左边 -->
	  <section class="left" v-if="false">
	    <section>
	      <!-- 折叠 -->
	      <i v-if="isCollapse" @click="collapseClick('close')" class="el-icon-s-unfold collapse-icon"></i>
	      <!-- 展开 -->
	      <i v-else @click="collapseClick('open')" class="el-icon-s-fold collapse-icon"></i>
	      <!-- 一键投屏 -->
	      <el-button type="primary" class="screen" size="small" v-if="false">{{$t('oneTouchScreenProjection')}}</el-button>
	    </section>
	  </section>
	  <section class="left alce">
		<!-- 折叠 -->
		<i v-if="isCollapse" @click="collapseClick('close')" class="el-icon-s-unfold collapse-icon"></i>
		<!-- 展开 -->
		<i v-else @click="collapseClick('open')" class="el-icon-s-fold collapse-icon"></i>
	    <div class="yddbtn" @click="fullScreen"><i class="iconfont icon-a-10"></i>{{$t('fullscreen')}}</div>
	    <div class="yddbtn" @click="$store.commit('publicOptions/SAVE_CODEVISBLE', true)"><i class="iconfont icon-a-11"></i>{{$t('mobileterminal')}}</div>
	      <!-- 切换主题 -->
	    <el-dropdown class="dropdown" trigger="click" @command="changeThemeClick">
	      <span class="el-dropdown-link">
			<i class="iconfont icon-a-12"></i>
	        {{$t('switchTheme')}}
	        <i class="el-icon-arrow-down el-icon--right"></i>
	      </span>
	      <el-dropdown-menu slot="dropdown">
	        <el-dropdown-item command="black">{{$t('black')}}</el-dropdown-item>
	        <el-dropdown-item command="blue">{{$t('blue')}}</el-dropdown-item>
	        <el-dropdown-item command="gray">{{$t('gray')}}</el-dropdown-item>
	      </el-dropdown-menu>
	    </el-dropdown>
	    <!-- 切换语言 -->
	    <el-dropdown class="dropdown" trigger="click" @command="changeLangClick">
	      <span class="el-dropdown-link">
	        <i class="iconfont icon-a-13"></i>
			{{$t('switchLanguage')}}
	        <i class="el-icon-arrow-down el-icon--right"></i>
	      </span>
	      <el-dropdown-menu slot="dropdown">
	        <el-dropdown-item :command="item.value" v-for="(item, index) in languageList" :key="index">{{item.name}}</el-dropdown-item>
	        <!-- <el-dropdown-item command="en">English</el-dropdown-item>
	        <el-dropdown-item command="th">ภาษาไทย</el-dropdown-item>
	        <el-dropdown-item command="vi">Tiếng Việt</el-dropdown-item> -->
	      </el-dropdown-menu>
	    </el-dropdown>
		<div class="yddbtn backnone" v-if="listenData.isAdminRole">{{$t('Listeningbackup')}}:<el-switch class="switch-small in-swaitch malf10" v-model="listenData.listeningBackup" :active-value="true" :inactive-value="false" @change="listenChange">
</el-switch></div>
	  </section>
	  <!-- 右边 -->
	  <section class="right flex alce">
	    <!-- 帮助 -->
		<div class="marig">
			<el-popover popper-class="my-popover" ref="helpRef" :offset="-120" placement="bottom" width="370" trigger="click" @show="helpShow">
			  <section class="p10" @mouseleave="closeMsgBox('helpRef')">
			    <h1 class="h1">{{$t('rmwl')}}</h1>
			    <div class="help-list">
					<ul>
						<li v-for="(item, index) in helpList" :key="index" @click="toHelpDetail(item)">{{item.title}}</li>
					</ul>
				</div>
			  </section>
			  <div class="flex alce" slot="reference">
			    <i class="el-icon-question message ml10"></i>{{$t('help')}}
			    <span></span>
			  </div>
			</el-popover>
		</div>
	    <!-- 系统消息 -->
		<div class="marig">
			<el-popover popper-class="my-popover" ref="popoverRef" :offset="-120" placement="bottom" width="350" trigger="click">
			  <section class="p10" @mouseleave="closeMsgBox('popoverRef')">
			    <h1 class="h1">{{$t('systemMessage')}}</h1>
			    <ul class="msg-box">
			      <li class="flex spbe" v-for="m of msgList" :key="m.id" @click="seeMsgDetail(m)">
			        <div class="title flex-item"><p class="ptxt">{{ m.title }}</p></div>
			        <div class="time">{{ m.createTime }}</div>
			      </li>
			    </ul>
			  </section>
			  <div class="flex alce" slot="reference">
			    <i class="iconfont icon-a-14 message ml10" :class="{on: msgList.length}"></i>({{msgList.length}})
			    <span></span>
			  </div>
			</el-popover>
		</div>
	    <div class="yddbtn" @click="$router.push('/platform/accountmanagement/personalsettings')">
			<img :src="avatar" alt="" class="pic" v-if="avatar && avatar != null">
			<img src="../../assets/img/icon.png" alt="" class="pic" v-else>
			{{account}}
		</div>
	    <div class="yddbtn" @click="toSignOut"><i class="iconfont icon-a-15"></i>{{$t('quit')}}</div>
	    <!-- 用户按钮 -->
	    <el-dropdown class="dropdown" @command="changeUserClick" v-if="false">
	      <span class="el-dropdown-link">
	        <i class="el-icon-user-solid user"></i>
	      </span>
	      <el-dropdown-menu slot="dropdown">
	        <el-dropdown-item command="userName">{{account}}</el-dropdown-item>
	        <!-- 退出登录 -->
	        <el-dropdown-item command="signOut">{{$t('loginOut')}}</el-dropdown-item>
	      </el-dropdown-menu>
	    </el-dropdown>
	  </section>
	
	  <!-- 消息详情弹窗 -->
	  <el-dialog :title="msgDetail.title" append-to-body :visible.sync="msgDetailVisible" custom-class="com-dialog big-btn-dialog pad50-dialog" :show-close="false" center>
		<div class="message-box">
			<div class="msg-time">{{msgDetail.createTime}}</div>
			<section class="msg-content">
				<div v-html="msgDetail.content"></div>
			</section>
		</div>
	    <span slot="footer" class="dialog-footer">
	      <template v-if="msgDetail.type == 1">
			<!-- 接受用户组邀请 -->
			<el-button size="small" type="primary" @click="toMsgConfirm(1)">{{$t('Agree')}}</el-button>
			<!-- 拒绝用户组邀请 -->
			<el-button size="small" type="danger" @click="toMsgConfirm(2)">{{$t('Refuse')}}</el-button>
		  </template>
	      <!-- 关闭 -->
	      <el-button size="small" :type="msgDetail.type == 1 ? 'info' : 'primary'" @click="msgDetailVisible = false">{{$t('close')}}</el-button>
	    </span>
	  </el-dialog>
	</div>
  </div>
</template>

<script>
import '@/assets/css/resetElementPopper.css'
import { signOut } from '@/request/api/auth'
import { messageListOfUnRead, messageReadMsg, messageInfo, listeningBackupQuery, listeningBackupUpdate, msgConfirm } from '@/request/api/message'
import { typeList } from '@/request/api/homecontent'
import { localDel, localSet } from '@/configs/storage'
import { mapState } from 'vuex'
export default {
  name: "Nav", // 头部导航组件
  data() {
    return {
      msgDetailVisible: false, //消息详情弹窗
      msgDetail: {}, //消息详情
      msgList: [] ,//未读消息列表
	  helpList: [],
	  listenData: {},
	  languageList: this.$store.state.publicOptions.languageList
    }
  },
  computed: {
    ...mapState('publicOptions', ['account', 'avatar']),
    ...mapState('publicOptions', ['isCollapse'])
  },
  methods: {
	  async toMsgConfirm(status){
		  const {id} = this.msgDetail
		  const {code, message} = await msgConfirm({id, status})
		  if(code == 200){
			  this.$message.success(message)
			  this.msgDetailVisible = false
			  await messageReadMsg({ id })
			  this.getUnReadMsgList()
		  }
	  },
	  async listenChange(){
		const {code, data, message} = await listeningBackupUpdate({status: this.listenData.listeningBackup})
		if(code == 200){
			this.$message.success(message)
			this.getListeningBackupQuery()
		}
	  },
	  async getListeningBackupQuery(){
		const {code, data} = await listeningBackupQuery()
		if(code == 200){
			this.listenData = data
			this.$store.commit("publicOptions/SAVE_ADMINROLE", data.isAdminRole)
		}
	  },
	  toHelpDetail({id}){
		window.open(location.origin + `#/helpdetail/${id}`)
	  },
	  helpShow(){
		this.getTypeList()
	  },
	  async getTypeList(){
		try {
		  const {code, data} = await typeList({type: 1})
		  if(code == 200){
			  this.helpList = data
		  }
		} catch (error) {
		  error?.status && console.error(error?.status)
		}
	  },
    // 全屏
	fullScreen(){
		let element = document.documentElement
		if(!element) return false
		if (element.requestFullscreen) {
			element.requestFullscreen();
		} else if (element.mozRequestFullScreen) {
			element.mozRequestFullScreen();
		} else if (element.webkitRequestFullScreen) {
			element.webkitRequestFullScreen();
		}
	},
	// 查看消息详情
    async seeMsgDetail(detail) {
      try {
        let { code, data } = await messageInfo({ id: detail.id })
		if(code == 200){
			if(data){
				this.msgDetailVisible = true
				data.content = data.content.replace(/\n/g, '<br>')
				this.msgDetail = data
				if(detail.type != 1) await messageReadMsg({ id: detail.id })
			}else{
				this.$message.error(this.$t('xxbcz'))
			}
		}
        this.getUnReadMsgList()
      } catch (error) {
        error?.status && console.error(error?.status)
      }
    },
    // 菜单折叠
    collapseClick(state) {
      switch (state) {
        case 'open':
          this.$store.commit("publicOptions/MENU_COLLAPSE", true)
          break
        case 'close':
          this.$store.commit("publicOptions/MENU_COLLAPSE", false)
          break
      }
    },
    //切换语言
    changeLangClick(lang) {
      if (lang === this.$i18n.locale) return
      this.$i18n.locale = lang
      localSet('lang', lang)
      document.title = this.$t(this.$route.meta.title)
    },
    // 切换主题
    changeThemeClick(color) {
      let colorClass = ''
      switch (color) {
        case "gray":
          colorClass = 'menugray'
          break
        case "blue":
          colorClass = 'menublue'
          break
        case "black":
          colorClass = 'menublack'
          break
      }
      this.$store.commit("publicOptions/SAVE_THEMECOLOR", colorClass)
      localSet('themeClass', colorClass)
    },
    // 鼠标移出内容区域 关闭系统消息弹出框
    closeMsgBox(refName) {
      this.$refs[refName].doClose()
    },
	// 退出登录
	toSignOut(){
		this.$elconfirm(`${this.$t('sftcdl')}?`, this.$t('xtts'), {
		  confirmButtonText: this.$t('determine'),
		  cancelButtonText: this.$t('cancel'),
		  type: 'warning'
		}).then(async () => {
		  try {
		    const { code, message } = await signOut()
		    if (code === 200) {
		      this.$message.success(message)
		      this.$store.commit("publicOptions/SAVE_TOKEN", '') //删除vuex中的token
		      localDel('token')
			  this.$store.commit("publicOptions/SAVE_AVATAR", '') //删除vuex中的token
			  localDel('avatar')
		      this.$router.replace('/')
		    }
		  } catch (error) {
		    this.$message.error(error.status)
		  }
		}).catch(() => {
		  this.$message.info(this.$t('canceled'))
		})
	},
    // 用户按钮
    changeUserClick(value) {
      switch (value) {
        case 'signOut':
          this.$elconfirm(`${this.$t('sftcdl')}?`, this.$t('xtts'), {
            confirmButtonText: this.$t('determine'),
            cancelButtonText: this.$t('cancel'),
            type: 'warning'
          }).then(async () => {
            try {
              const { code, message } = await signOut()
              if (code === 200) {
                this.$message.success(message)
                this.$store.commit("publicOptions/SAVE_TOKEN", '') //删除vuex中的token
                localDel('token')
                this.$router.replace('/')
              }
            } catch (error) {
              this.$message.error(error.status)
            }
          }).catch(() => {
            this.$message.info(this.$t('canceled'))
          })
          break
		case 'userName':
			this.$router.push('/platform/accountmanagement/personalsettings')
			break
      }
    },
    // 获取未读消息列表
    async getUnReadMsgList() {
      try {
        const { code,data } = await messageListOfUnRead()
		if(code == 200){
			this.msgList = data
		}
      } catch (error) {
        error?.status && console.error(error?.status)
      }
    },
    // 页面初始化
    init() {
      this.getUnReadMsgList()
	  this.getListeningBackupQuery()
    }
  },
  mounted() {
    this.$bus.$on('updateUnreadNum', () => {
      this.getUnReadMsgList()
    })
    this.init()
  },
  beforeDestroy() {
    this.$bus.$off('updateUnreadNum')
  }
}
</script>

<style lang='less' scoped>
.nav {
  display: flex;
  justify-content: space-between;
  height: 50px;
  line-height: 50px;
  padding: 0 25px 0 25px;
  background-color: #fff;
  .left {
    display: flex;
	height: 100%;
	section {
      .screen {
        margin-left: 20px !important;
      }
    }
    .collapse-icon {
      position: relative;
      top: 1px;
      font-size: 20px;
	  color: #333;
      cursor: pointer;
	  margin-right: 30px;
    }
  }
  .right {
    .user {
      color: skyblue;
      display: flex;
      align-items: center;
      margin: 0 5px;
    }
  }
  .iconfont,.el-icon-question{
	  display: inline-block;
	  vertical-align: middle;
	  width: 15px;
	  height: 15px;
	  line-height: 15px;
	  font-size: 15px;
	  text-align: center;
	  margin: -3px 5px 0 0;
	  &.icon-a-12,&.icon-a-13{
		  margin-right: 3px;
	  }
	  &.icon-a-14{
		  height: 14px;
		  line-height: 14px;
		  font-size: 14px;
		  font-weight: bold;
		  margin-top: 2px;
		  margin-right: 3px;
		  &.on{
			  position: relative;
			  &:after{
				  content: '';
				  display: block;
				  position: absolute;
				  right: -4px;
				  top: 0;
				  width: 6px;
				  height: 6px;
				  background: #FF0808;
				  border-radius: 50%;
			  }
		  }
	  }
  }
  .el-icon-question{
	  font-size: 18px;
	  margin-top: 0px;
  }
}
.h1 {
  font-size: 18px;
}
.msg-box {
  padding: 5px;
  box-sizing: content-box;
  li {
    margin-bottom: 10px;
    font-size: 16px;
    cursor: pointer;
  }
  .title{
	  overflow: hidden;
  }
  .ptxt{
	  white-space: nowrap;
	  text-overflow: ellipsis;
	  overflow: hidden;
  }
}
.msg-content {
  margin-bottom: 20px;
}
.user-box {
  height: 80px;
  border: 1px solid #dcdfe6;
  border-radius: 5px;
  padding: 5px 10px;
}
.yddbtn{
	font-size: 14px;
	color: #333;
	cursor: pointer;
	line-height: 46px;
	padding: 0 10px;
	&+.yddbtn{
		margin-left: 30px;
	}
	&:hover{
		background: #F4F6F9;
	}
	&:active{
		color: #4F9AF7;
	}
	.pic{
		width: 34px;
		height: 34px;
		display: inline-block;
		vertical-align: middle;
		margin: 0 10px 0 0;
		border-radius: 50%;
	}
}
.in-swaitch{
	vertical-align: middle;
	margin-top: -2px;
}
.backnone{
	color: #333!important;
	background: none!important;
}
.el-dropdown,.el-popover__reference{
	font-size: 14px;
	color: #333;
	line-height: 46px;
	height: 46px;
	cursor: pointer;
	padding: 0 10px;
	margin-left: 20px!important;
	.el-dropdown-link{
		display: block;
		line-height: 46px;
	}
	&:hover{
		background: #F4F6F9;
	}
	&:active{
		color: #4F9AF7;
	}
}
.marig{
	margin-right: 20px;
}
.help-list{
	margin-top: 10px;
	max-height: 20vh;
	overflow: auto;
	li{
		line-height: 25px;
		padding: 5px 10px;
		cursor: pointer;
		&:hover{
			background-color: #f7f7f7;
		}
	}
}
.msg-time{
	color: #999;
	text-align: center;
	margin-top: -20px;
}
.msg-content{
	margin-top: 30px;
	font-size: 16px;
	line-height: 1.7;
}
@media screen and (max-width: 1200px){
	.nav{
		.left {
			.collapse-icon{
				margin-right: 15px;
			}
		}
	}
	.yddbtn{
		font-size: 12px;
		padding: 0 5px;
		&+.yddbtn{
			margin-left: 5px;
		}
	}
	.el-dropdown, .el-popover__reference{
		font-size: 12px;
		margin-left: 5px!important;
	}
	.marig{
		margin-right: 5px;
	}
}
</style>