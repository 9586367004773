import VueRouter from 'vue-router'
import { Message } from 'element-ui'
import { localGet, localSet } from '@/configs/storage'
import i18n from '@/i18n'
import home from './module/home'
import audio from './module/audio'
import platform from './module/platform'
import login from './module/login'
import information from './module/information'
import ai from './module/ai'
import error from './module/error'
import store from '@/store'
const router = new VueRouter({
    routes: [
        ...home,
        ...audio,
        ...platform,
        ...login,
        ...information,
        ...ai,
        ...error
    ]
})
//路由前置钩子
router.beforeEach((to, _, next) => {
	if(to.meta.module == 'login' && to.params.account && to.params.password && to.params.token){
		if(to.params.lang) i18n.locale = to.params.lang
		const { account, password, token } = to.params
		localSet('account', account)
		localSet('password', password)
		localSet('token', token)
		store.commit('publicOptions/SAVE_TOKEN', token)
		store.commit('publicOptions/SAVE_ACCOUNT', account)
		store.commit('publicOptions/SAVE_PASSWORD', password)
		next('/home/audiohome')
	}else if (localGet('token')) {
		if(to.meta.title == 'login'){
			next('/home/audiohome')
		}else{
			next()
		}
    } else {
        // 访问登录,错误模块的页面没有token可以访问
        if (to.meta.module === 'login' || to.meta.module === 'error' || to.meta.module === 'down') {
            next()
        } else {
            // 没有登录 跳回登录页 并提示
            Message.error(`${i18n.t('LoginFirst')}!`)
            next('/index/login')
        }
    }
})
//路由后置钩子
router.afterEach(to => {
    document.title = i18n.t(to.meta.title) || store.state.publicOptions.platformInfo.systemName
})
export default router