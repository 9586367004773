import { secondaryPackagingRequest } from '@/request'
//获取平台信息
export const platformInfo = () => secondaryPackagingRequest({
    url: '/sys/platform/info',
    isLoading: false,
    needToken: true
})
//修改平台信息
export const platformUpdate = data => secondaryPackagingRequest({
    url: '/sys/platform/update',
    method: 'POST',
    headers: {
        'Content-Type': 'application/json'
    },
    data,
    isLoading: true,
    needToken: true
})