<template>
	<!-- <keep-alive>
		<router-view></router-view>
	</keep-alive> -->
	<router-view></router-view>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'Content', //路由容器
  computed: {
    ...mapState("publicOptions", ["keepAliveComponents"]),
  },
}
</script>