// 中文
import zhLocale from 'element-ui/lib/locale/lang/zh-CN'
export default {
    smartCloudBroadcastingSystem: '智慧云广播系统',
    switchLanguage: '切换语言',
    register: '注册',
    verificationCode: '验证码',
    secureLogin: '安全登录',
    returnToLogin: '返回登录',
    login: '登录',
    autologin: '登录',
    error: '错误',
    downloadCenter: '下载中心',
    downloaddetail: '下载中心',
    commonProblem: '帮助中心',
    freeRegistration: '快速注册',
    agreement: '协议',
    findPassword: '找回密码',
    loginOut: '退出登录',
    switchTheme: '切换主题',
    black: '黑色',
    blue: '蓝色',
    gray: '灰色',
    home: '首页',
	paste: '粘贴',
	forever: '永久',
    broadcast: '广播',
    broadcastRelease: '调度控制',
    realTimeBroadcast: '音频直播',
    musicbroadcast: '音乐广播',
    timedBroadcast: '定时广播',
    deviceOnDemand: '设备点播',
    deviceBroadcastd: '设备广播',
    visualBroadcasting: '可视广播',
    shoutingbroadcast: '喊话广播',
    mediaLibrary: '媒体库',
    deviceManagement: '设备管理',
    monitoringEvaluation: '监测评估',
    platformManagement: '平台管理',
    accountManagement: '账户管理',
    systemManagement: '系统管理',
    mochaITOM: '运维管理',
    thirdPartyAccess: '第三方接入',
    serviceRenewal: '服务续费',
    personalSettings: '个人设置',
    userManagement: '用户管理',
    userGroupManagement: '用户组管理',
    orderlist: '订单记录',
    ordernumber: '订单号',
	Ordercontent: '订单内容',
	Orderdetails: '订单详情',
	drawee: '付款人',
	amount: '金额',
	Paymentmethod: '支付方式',
    Billingcenter: '开票中心',
    Toinvoice: '去开票',
    Electronicinvoice: '电子发票',
	servicerenewal: '服务续费',
    regionalManagement: '区域管理',
    roleManagement: '角色管理',
    menuManagement: '菜单管理',
    firmwareManagement: '固件管理',
    upgradeManagement: '升级管理',
    operationLog: '操作日志',
    aboutPlatform: '关于平台',
    dataBaseBackup: '数据备份',
    mediaServiceManagement: '媒体服务管理',
    systemLog: '系统日志',
    operationMonitoring: '运行监控',
    onlineService: '在线客服',
    systemMessageRelease: '系统消息发布',
    filestorage: '文件存储服务',
	fileStorageMethod: '文件存储方式',
    emergencyPlan: '应急预案',
    callIntercom: '呼叫对讲',
    fireLinkage: '消防联动',
    gisMap: 'GIS地图',
    audioMedia: '音频媒体',
    audio: '音频',
    speechSynthesis: '语音合成',
    acquisitionEquipment: '采集设备',
    acquisitionEquipment2: '采集设备（采集器+IP话筒）',
    intercomTerminal: '对讲终端',
    ipAdapter: 'IP适配器',
    ipMicrophone: 'IP话筒',
    collector: '采集器',
	mfr: '录播一体机',
    receivingTerminal: '接收终端',
    equipmentWarehousing: '设备出入库',
    deviceGroup: '终端分组',
    broadcastRecord: '广播记录',
    audioHome: {
        broadcastRecord: '播出记录',
    },
    broadcastStatistics: '广播统计',
    largedataScreen: '数据大屏',
    offlineTerminal: '离线终端',
    resources: '资源情况',
    serviceAlarm: '业务告警',
    offlineBroadcast: '离线广播',
    mediaAudit: '媒体审核',
    sensitiveWords: '敏感词',
    sensitiveVocabulary: '敏感词汇',
    newSensitiveWords: '新增敏感词汇',
    textContent: '文本内容',
    textName: '文本名称',
    informant: '发音人',
    speedOfSpeech: '语速',
    intonation: '语调',
    delay: '延迟',
    content: '内容',
    oneTouchScreenProjection: '一键投屏',
    systemMessage: '系统消息',
    deviceStatistics: '设备统计',
    totalCount: '总数',
    online: '在线',
    offline: '离线',
    menuShortcutentry: '菜单快捷入口',
    planAlarm: '预案报警',
    fire: '火灾',
    earthquake: '地震',
    airDefense: '防空',
    broadcasting: '正在播出',
    broadcastPlan: '播出计划',
    broadcastMonitoring: '广播监听',
    turnOffBroadcast: '关闭广播',
    detail: '详情',
    theDayBefore: '前一天',
    theDayAfter: '后一天',
    close: '关闭',
    broadcastFrequencyStatistics: '广播次数统计',
    broadcastStatusStatistics: '广播状态统计',
    terminalDistribution: '终端分布',
    quantity: '数量',
    many: '台',
    equipmentType: '设备类型',
    equipmentTypeStatistics: '设备类型统计',
    terminalOnlineStatistics: '终端在线统计',
    activateIntercomTerminal: '激活对讲终端',
    activateCollectionDevice: '激活采集设备',
    modifyCollectionDevice: '修改采集设备',
    modifyIntercomTerminal: '修改对讲终端',
    modifyTerminal: '修改终端',
    broadcastSuccessful: '广播成功',
    broadcastFailure: '广播失败',
    onlineRate: '在线率',
	task: '任务',
    taskName: '任务名称',
    priority: '优先级',
    broadcastTarget: '广播目标',
    startTime: '开始时间',
    endTime: '结束时间',
    volume: '音量',
    operation: '操作',
    newTask: '新建任务',
    sameDay: '当天',
    sevenDay: '七天',
    oneMonth: '一个月',
    startDate: '开始日期',
    endDate: '结束日期',
    search: '搜索',
    query: '查询',
    number: '编号',
    serialNumber: '序号',
    broadcastMode: '播出方式',
    broadcastStatus: '播出状态',
    operator: '操作员',
    offBroadcastTime: '关播时间',
    cycle: '周期',
    enable: '启用',
    disable: '禁用',
    creationTime: '创建时间',
    whole: '全部',
    date: '日期',
    currentlyEnabledScheme: '当前启用方案',
    newScheme: '新建方案',
    removeScheme: '删除方案',
    enableScheme: '启用方案',
    copyScheme: '复制方案',
    updateTask: '编辑任务',
    delete: '删除',
    refresh: '刷新',
    to: '至',
    audioUpload: '上传音频',
    mediaCategory: '媒体类别',
    mediaDetails: '媒体详情',
    fileName: '文件名称',
    duration: '时长',
    uploader: '上传人员',
    uploadTime: '上传时间',
    audit: '审核',
    auditStatus: '审核状态',
    reviewer: '审核人员',
    auditTime: '审核时间',
    audition: '试听',
    audioMediaName: '音频媒体名称',
    textView: '文本查看',
    reviewComments: '审核意见',
    newlyAdded: '新增',
    newlyAdded2: '新增',
    categoryName: '类别名称',
    category: '所属类别',
    edit: '编辑',
    mediaLevel: '媒体级别',
    uploadFile: '上传文件',
    clickUpload: '点击上传',
    determine: '确定',
    cancel: '取消',
    createText: '创建文本',
    textSpeechSynthesis: '文字语音合成',
    findingsOfAudit: '审核结果',
    mediaType: '媒体类型',
    activation: '激活',
    activateTerminal: '激活终端',
    terminal: '终端',
    terminalName: '终端名称',
    equipment: '设备',
    name: '名称',
    groupName: '组名',
    terminalNumber: '通道编号',
    groupingName: '分组名称',
    status: '状态',
    activatePersonnel: '激活人员',
    activateTime: '激活时间',
    servicePeriod: '服务期限',
    specialSettings: '特殊设置',
    quit: '退出',
    monitoringAndMonitoring: '监控监听',
    callObjectSettings: '呼叫对象设置',
    commandControl: '指令控制',
    onlineRecord: '上线记录',
    physicalAddress: '物理地址',
    warehousingStatus: '入库状态',
    newEquipmentGrouping: '新增终端分组',
    newEquipmentGrouping2: '新增设备分组',
    modifyEquipmentGrouping: '修改终端分组',
    groupNumber: '分组编号',
    type: '类型',
    coverage: '覆盖率',
    timeFrame: '时间范围',
    broadcastStatisticalMethod: '广播统计方式',
    broadcastType: '广播类型',
    user: '用户',
    area: '区域',
    playbackTimes: '播放次数',
	startplay: '开播',
    seconds: '次',
    download: '下载',
    lineChart: '折线图',
    barChart: '柱状图',
    lastOfflineTime: '最后离线时间',
    offlineDays: '离线天数',
    exportTerminalInformation: '导出终端信息',
    securitySetting: '安全设置',
    personalData: '个人资料',
    orderRecord: '订单记录',
    account: '所属账户',
    addTo: '添加',
    moveUp: '上移',
    moveDown: '下移',
    callObjectList: '呼叫对象列表',
    callObjectAddition: '呼叫对象添加',
    accountNumber: '账号',
    accountLevel: '账号级别',
    removeUserGroup: '移出用户组',
    fullName: '姓名',
    mailbox: '邮箱',
    mobilePhone: '手机',
	requeirPhone: '请输入手机号',
    sex: '性别',
    role: '角色',
    userGroup: '用户组',
    addToUserGroup: '添加到用户组',
    addUserGroupMembers: '添加用户组成员',
    managementArea: '管理区域',
    mapPositioning: '地图定位',
    registrationTime: '注册时间',
    yourBasicInformation: '基础信息',
    yourSecurityServices: '安全服务',
    safetyLevel: '安全等级',
    loginPassword: '登录密码',
    bindMailbox: '绑定邮箱',
    bindingMobilePhone: '绑定手机',
    changePassword: '修改密码',
    modifyMobilePhone: '修改手机',
    addEmail: '添加邮箱',
    replaceMailbox: '更换邮箱',
    replacementArea: '更换区域',
    low: '低',
    middle: '中',
    high: '高',
    male: '男',
    female: '女',
    currentAvatar: '当前头像',
    changeTheAvatar: '更换头像',
    other: '其他',
    preservation: '保存',
    registeredAccount: '注册账号',
    roleAuthorization: '角色授权',
    fixedLocation: '定位',
    createUserGroup: '创建用户组',
    userGroupName: '用户组名称',
    creator: '创建人员',
    groupMembers: '组内成员',
    addInitialArea: '添加初始区域',
    areaCode: '区域码',
    areaCoding: '区域编码',
	Superior: '上级',
    areaName: '区域名称',
    regional: {
        fullName: '全称',
    },
    areaFullName: '区域全称',
    longitudeAndLatitude: '经纬度',
    longitude: '经度',
    currentLongitude: '当前经度',
    latitude: '纬度',
    currentLatitude: '当前纬度',
    addChildRegion: '新增下级区域',
    newRole: '新增角色',
    modifyRole: '修改角色',
    rolePermissions: '角色权限',
    roleID: '角色ID',
    roleName: '角色名称',
    describe: '描述',
    firmwareUpload: '固件上传',
    firmwareFile: '固件文件',
    versionCode: '版本码',
    versionName: '版本名称',
    equipmentUpgrade: '设备升级',
    upgradeRecord: '升级记录',
    upgradeTarget: '升级目标',
    equipmentName: '设备名称',
    firmwareVersion: '固件版本',
    firmwarePackageName: '固件包名',
    forcedUpgrade: '强制升级',
    upgradeNow: '立即升级',
    anyPointInTime: '任意时间点',
    taskCreationTime: '任务创建时间',
    upgradeAccordingToTheSpecifiedTime: '按指定时间升级',
    deviceSoftwareVersion: '设备软件版本',
    force: '强制',
    upgradeTime: '升级时间',
    upgradeProgress: '升级进度',
    cancelExecution: '取消执行',
    logLevel: '日志级别',
    logContent: '日志内容',
    logTime: '记录日志时间',
    news: '消息',
    warning: '警告',
    systemName: '系统名称',
    softwareAbbreviation: '软件简称',
    systemAbbreviation: '系统简称',
    softwareVersion: '软件版本',
    SoftwareName: '软件名称',
    copyright: '版权',
    serviceProviderAddress: '服务商地址',
    serviceProvider: '服务提供方',
    modifyPlatformParameters: '修改平台参数',
    retry: '重试',
    password: '密码',
    onTheAir: '广播中',
    notbroadcast: '未广播',
    auditReceipt: '审核入库',
    inIntercom: '对讲中',
    notWarehoused: '未入库',
    warehoused: '已入库',
    auditFree: '免审核',
    notApproved: '未审核',
    Approved: '审核通过',
    passTheAudit: '通过审核',
    failedToPassTheReview: '审核不通过',
    schemeName: '方案名称',
    playbackMode: '播放方式',
    broadcastAudio: '播出音频',
    broadcastNumbers: '播出次数',
    numberOfCycles: '循环次数',
    grouping: '分组',
    partition: '分区',
    airDate: '播出日期',
    broadcastTimes: '播出时间',
    broadcastCycle: '播出周期',
    Monday: '周一',
    Tuesday: '周二',
    Wednesday: '周三',
    Thursday: '周四',
    Friday: '周五',
    Saturday: '周六',
    Sunday: '周日',
    writtenWords: '文字',
    audioFile: '音频文件',
    radioStation: '电台',
    radioRelay: '电台转播',
    streamingAddress: '流地址',
    streamingAddressRelay: '流地址转播',
    triggerEvent: '触发事件',
    stopBroadcastingMode: '停播方式',
    fault: '故障',
    numberOfTerminals: '终端数量',
    terminalList: '终端列表',
    singleCycle: '单次播放',
    loopPlayback: '循环播放',
    remark: '备注',
    time: '时间',
    addMediaCategory: '添加媒体类别',
    modifyMediaCategory: '修改媒体类别',
    modifyText: '修改文本',
    modifyUserGroup: '修改用户组',
    modifyArea: '修改区域',
    pleaseEnterYourName: '请输入姓名',
    pleaseEnterTheAccountNumber: '请输入账号',
    service: '服务',
    playing: '播放中',
    playbackSuccessful: '播放成功',
    playbackFailed: '播放失败',
    Selectedequipment: '已选设备',
    applicationmanagement: '应用管理',
    Versionnumber: '版本号',
    yes: '是',
	billingmode: '服务计费模式',
	ordermanagement: '订单管理',
	Daterange: '日期范围',
	invoice: '发票',
	Gocheck: '查看',
	second: '秒',
	Price: '价格',
	invoicingmanagement: '开票管理',
	Invoicingrequisition: '开票申请',
	Medialist: '媒体列表',
	Broadcastcontrol: '广播控制',
	Stopbroadcasting: '停播',
	Broadcastcontent: '广播内容',
	Oldpassword: '旧密码',
	NewPassword: '新密码',
    account2: '账户',
	smsservice: '短信服务',
	Reset: '重置',
	info: '信息发布',
	mediaassets: '媒资管理',
	picture: '图片',
	video: '视频',
	music: '音乐',
	smartscreenname: '智慧屏',
	smartscreen: '智慧屏管理',
	ljqy: '逻辑区域',
	equipmentdetail: '设备详情',
	screenrelease: '上屏发布',
	realtimerelease: '即时发布',
	timedrelease: '定时发布',
	no:'否',
	releasehistory: '发布记录',
	zdai: '终端触发任务',
	Pleaseinputapassword: '请输入密码',
	Pleaseentertheverificationcode: '请输入验证码',
	ServerAddress: '服务器地址',
	smdl: '扫码登录',
	zcjbsty: '注册即表示同意',
	Theserviceagreement: '服务协议',
	Privacypolicy: '隐私政策',
	Legalnotices: '法律声明',
	Clientserviceprotocol: '客户端服务协议',
	ji: '及',
	gxxy: '请勾选已阅读并同意以上协议',
	azapph: '安装手机APP后，用APP扫描二维码获取服务器地址',
	syllq: '请使用手机浏览器、支付宝、淘宝、QQ等APP扫码下载手机客户端',
	Downloadthepicture: '下载图片',
	zwkf: '暂未开放此功能',
	bnsykg: '不能使用空格',
	qsrmm: '请输入8-20位的密码',
	qsryzh: '请输入你需要找回登录密码的账户名',
	Existingaccount: '已有账号',
	Loginimmediately: '马上登录',
	hqyzm: '获取验证码',
	yzdshj: '验证的手机号',
	Resend: '重新发送',
	Pleaseenteranewpassword: '请输入新密码',
	qsrszzm: '请输入数字、字母',
	Aboutus: '关于我们',
	qsrzcxm: '请输入注册姓名',
	aitask: '终端触发任务',
	createGroup: '创建分组',
	广播用户权限: '广播用户权限',
	超级管理员权限: '超级管理员权限',
	运维: '运维',
	超管: '超管',
	系统维护人员: '系统维护人员',
	播音员: '播音员',
	广播用户权限: '广播用户权限',
	aiWarning: 'AI预警',
	aiDevice: '监控设备',
	screenView: '分屏预览',
	aiMap: '电子地图',
	aiAlarm: '报警查询',
	Adddevice: '添加设备',
	EquipmentNo: '设备编号',
	channel: '通道',
	aiLinkage: '广播联动配置',
	operatorManage: '运营商管理',
	customManage: '客户管理',
	receivingManage: '接收终端管理',
	collectingManage: '采集设备管理',
	talkingManage: '对讲终端管理',
	smartScreenManage: '智慧屏管理',
	homeContent: '宣传文案',
	timingLive: '定时直播',
	Preview: '预览',
	create: '新建',
	helpTitle: '帮助文档',
	appSlideTitle: 'APP轮播文档',
	title: '标题',
	author: '作者',
	release: '发布',
	cover: '封面',
	abstract: '摘要',
	fams: '方案描述',
	modify: '修改',
	scheme: '方案',
	jrsd: '节日设定',
	holidayName: '节日名称',
	rwlb: '任务列表',
	broadcastlinkage: '广播联动配置',
	fullscreen: '全屏',
	mobileterminal: '移动端',
	Listeningbackup: '听力备份',
	yjgb: '应急广播',
	wjtsgb: '文件推送广播',
	dsbfgb: '定时播放广播',
	gisdtgb: 'GIS地图广播',
	tjyabj: '添加预案报警',
	yjbj: '应急报警',
	cybj: '常用报警',
	zdybj: '自定义报警',
	qxzyjbj: '请选择应急报警',
	qxztb: '请选择图标',
	Icon: '图标',
	zdytb: '自定义图标',
	xzwj: '选择文件',
	yajc: '预案简称',
	bdyjya: '绑定应急预案',
	qsryajc: '请输入预案简称',
	qxzyjya: '请选择应急预案',
	qsrgjz: '请输入关键字',
	tpcj: '图片裁剪',
	amplify: '放大',
	reduce: '缩小',
	leftrotate: '左旋转',
	rightrotate: '右旋转',
	jingjie: '警戒',
	fangdao: '防盗',
	hongzai: '洪灾',
	gongong: '公共卫生',
	tongyong: '通用图标',
	yjyglb: '应急预案列表',
	huozai: '火灾',
	dizhen: '地震',
	shusan: '疏散',
	fangkong: '防空',
	kongxi: '空袭',
	dizhenyanlian: '地震演练',
	longjuanfeng: '龙卷风',
	jiechu: '解除警报',
	help: '帮助',
	rmwl: '热门问题',
	linqi: '临期/已过期',
	scyabj: '删除预案报警',
	qdscya: '确定要删除此预案报警吗',
	zdzxs: '终端在线数',
	zdzs: '终端总数',
	qdqdya: '确定要启动此预案报警吗',
	xtts: '系统提示',
	gbcs: '广播次数',
	zwsj: '暂无数据',
	tpgsyq: '上传图片只能是JPG或PNG格式',
	loop: '循环',
	qsrnr: '请输入内容',
	qbzd: '全部终端',
	qxzbcyp: '请选择播出音频',
	qgxbcmb: '请勾选播出目标',
	qiege: '切歌',
	shangyiqu: '上一曲',
	xiayiqu: '下一曲',
	zanting: '暂停',
	qsrrwmc: '请输入任务名称',
	qsrxhcs: '请输入循环次数',
	qsryxj: '请输入优先级',
	qsryl: '请输入音量',
	qxzbcrq: '请选择播出日期',
	qgxbczq: '请勾选播出周期',
	qsrldz: '请输入流地址',
	qsrwz: '请输入文字',
	qxzcjsb: '请选择采集设备',
	qxzyytd: '请选择音源通道',
	szyd: '数字越大，优先播出',
	yxgq: '已选歌曲',
	gbmbqbzd: '广播目标为全部终端',
	fzxdzdlb: '分组下的终端列表',
	yytd: '音源通道',
	upan: 'U盘',
	none: '无',
	ydjc: '移动检测',
	rqqy: '入侵区域',
	rxjc: '人形检测',
	tljc: '停留检测',
	zdtb: '自动停播',
	sdtb: '手动停播',
	qsrmc: '请输入名称',
	sxtj: '生效条件',
	ryzt: '任意状态',
	zxzt: '在线状态',
	cxsc: '持续时长',
	xgssfz: '修改所属分组',
	qxzbcsj: '请选择播出时间',
	jssjbnxykssj: '结束时间不能小于开始时间',
	bcscbnxyypsc: '播出时长不能小于音频时长',
	qdgbgb: '确定要关闭广播吗',
	canceled: '已取消',
	zdwjsl: '最多只能选择50个音频文件',
	sbcj: '设备采集',
	Hour: '小时',
	rwxq: '任务详情',
	gblb: '广播列表',
	bcz: '保存中',
	plsc: '批量删除',
	qdsczxrwm: '确定要删除这些任务吗',
	qxzyscdrw: '请选择要删除的任务',
	qdyscgbm: '确定要删除广播吗',
	qsrfamc: '请输入方案名称',
	qxxzfa: '请先选择方案',
	scfahscrw: '删除方案时会删除方案下的所有任务，确定要删除方案吗',
	sxsj: '生效时间',
	qdyscrwm: '确定要删除任务吗',
	qdyqdgbm: '确定要启动广播吗',
	bcmb: '播出目标',
	qidong: '启动',
	sftcdl: '是否退出登录',
	xxbcz: '消息不存在',
	ptjb: '普通级别',
	aqjb: '安全级别',
	mtwjzctips: '仅支持mp3/wav格式，上传单个文件大小不能超过{a}MB，请勿上传违法、违规、不健康的内容，否则您将承担必要的法律责任',
	mtwjzctips2: '仅支持mp3/wav格式，请勿上传违法、违规、不健康的内容，否则您将承担必要的法律责任',
	qxmtlb: '请选择媒体类别',
	qxzmtjb: '请选择媒体级别',
	qscypwj: '请上传音频文件',
	share: '共享',
	scypz: '上传音频中',
	xiaofeng: '男声-小峰',
	xiaoyan: '女声-小燕',
	mypzcos: '没有配置腾讯cos信息',
	qxzyscdmtwj: '请选择要删除的媒体文件',
	qdysczxmtwjm: '确定要删除这些媒体文件吗',
	qdycmtwj: '确定移除',
	scdypwjgs: '上传的音频文件格式只能是 mp3/wav 格式',
	cfscwj: '该文件已存在，请勿重复上传文件',
	scddgwjdx: '上传的音频文件单个不能超过{a}MB',
	qdscmtwj: '你确定要删除 {a} 吗',
	aqez: '注：请勿上传违法、违规、不健康的内容，否则您将承担必要的法律责任',
	qsrwbmc: '请输入文本名称',
	qxzyscdwj: '请选择要删除的文件',
	qdysczxwjm: '确定要删除这些文件吗',
	nqdyscwbwjm: '你确定要删除文本文件吗',
	qxzyscdmtlb: '请选择要删除的媒体类别',
	xtmrlbbyxsc: '系统默认类别不允许删除',
	qrscmtlb: '你确定要删除媒体类别 {a} 吗',
	qxxzmtlbzjxxg: '请先选择媒体类别再进行修改',
	xtmrlbbyxxg: '系统默认类别不允许修改',
	lbmcbt: '类别名称必填',
	sftgsh: '是否通过审核',
	sfbtgsh: '是否不通过审核',
	mgchh: '注：每行一个词汇，使用回车键换行',
	qsrmgc: '请输入敏感词',
	nqdyscmgcm: '你确定要删除 {a} 敏感词吗',
	hjjg: '呼叫结果',
	hjcg: '呼叫成功',
	hjsb: '呼叫失败',
	hjflx: '呼叫方类型',
	hjf: '呼叫方',
	bhjflx: '被呼叫方类型',
	bhjf: '被呼叫方',
	hjsj: '呼叫时间',
	thsc: '通话时长',
	hjcs: '呼叫超时',
	dfjj: '对方拒绝',
	dfzm: '对方正忙',
	qxhj: '取消呼叫',
	wjt: '未接通',
	xfxhy: '消防信号源',
	xfxhysz: '消防信号源设置',
	SignalSourceDescription: '信号源描述',
	SignalSourceEquipment: '信号源设备',
	SignalNumber: '信号编号',
	PleaseSelectAChannel: '请选择通道',
	unexpired: '未到期',
	Expired: '已到期',
	Advent: '临期',
	periodOfValidity: '有效期',
	Map: '地图',
	DefaultVolume: '默认音量',
	BroadcastVolume: '广播音量',
	InstallationPosition: '安装位置',
	IntercomVolume: '对讲音量',
	TaskPriority: '任务优先级',
	FrequencySetting: '频率设置',
	Frequency: '频率',
	GSignal: '4G信号',
	qsrssgjc: '请输入搜索关键词',
	qsrwldz: '请输入物理地址',
	qsrzdmc: '请输入终端名称',
	qxztdbh: '请选择通道编号',
	qxzzt: '请选择状态',
	qsrdjyl: '请输入对讲音量',
	qxzrwyxj: '请选择任务优先级',
	UploadSuccessful: '上传成功',
	ReuploadImages: '点击后可重新上传图片',
	GoBack: '返回上一级',
	OptionalReceivingTerminal: '可选接收终端',
	OptionalReceivingDevice: '可选设备',
	SelectedReceivingTerminal: '已选接收终端',
	SelectedReceivingDevice: '已选设备',
	qsrfzmc: '请输入分组名称',
	qsrfzbh: '请输入分组编号',
	PleaseSelectTheReceivingTerminal: '请选择接收终端',
	PleaseSelectTheReceivingDevice: '请选择设备',
	nqdyscfzm: '你确定要删除分组{a}吗',
	SDcardCapacity: 'SD卡容量',
	SDcardStatus: 'SD卡状态',
	NetworkMode: '网络模式',
	Normal: '正常',
	Bbnormal: '异常',
	Unrecognized: '未识别',
	FormatSDCard: '格式化SD卡',
	Record: '记录',
	Wired: '有线',
	yunyingshang: '运营商',
	UsedTraffic: '已使用流量',
	DeviceLog: '设备日志',
	GetLog: '获取日志',
	GSignalRecord: '4G信号记录',
	SignalValue: '信号值',
	FormatSDcardConfirmation: '格式化SD卡将清空SD卡内的所有文件，确认要格式化SD卡吗',
	sending: '发送',
	RetransmissionCycle: '回传周期',
	Instruct: '指令',
	qsrhczq: '请输入回传周期',
	qsrsz: '请输入数字',
	qxzzl: '请选择指令',
	jiaoshi: '校时',
	tongbucanshu: '同步参数',
	jixubofang: '继续播放',
	chongqi: '重启',
	qingchu: '清除消防报警状态',
	nqdysczdm: '你确定要删除终端{a}吗',
	qxzyscdzd: '请选择要删除的终端',
	qdtsczxzdm: '确定要删除这些终端吗',
	Online: '上线',
	Setting: '设置',
	ServiceExtranetAddress: '服务外网地址',
	ServiceIntranetAddress: '服务内网地址',
	Port: '端口',
	tingji: '停机',
	fuji: '复机',
	qiangzhi: '强制激活',
	jingtai: '静态',
	simCardStatus: 'SIM卡状态',
	simCardControl: 'SIM卡控制',
	IPAddress: 'IP地址',
	ziwangyanma: '子网掩码',
	wangguan: '网关',
	MACAddress: 'MAC地址',
	qxzfwqx: '请选择服务期限',
	qsrfwwwdz: '请输入服务外网地址',
	qsrdk: '请输入端口',
	qsrfwnwdz: '请输入服务内网地址',
	qxzwlms: '请选择网络模式',
	qxzkzzl: '请选择控制指令',
	qxzysydh: '请选择要上移的行',
	qxzyxydh: '请选择要下移的行',
	dytbnbsy: '第一条不能被上移',
	zhytbnbxy: '最后一条不能被下移',
	nqdyscghjdxm: '你确定要删除该呼叫对象吗',
	nqdytjwhjdxm: '你确定要添加为呼叫对象吗',
	djyx: '对讲优先',
	gbyx: '广播优先',
	qdgbdgb: '你确定要关闭{a}的广播吗',
	ImportDevice: '导入设备',
	plsx: '批量审核',
	plck: '批量出库',
	ActiveState: '激活状态',
	Active: '已激活',
	NotActive: '未激活',
	nqdyscgsbm: '你确定要删除该设备吗',
	fwzt: '服务状态',
	yyyh: '运营用户',
	qsrybddyyyh: '请输入要绑定的运营用户',
	qsrsbmc: '请输入设备名称',
	qxzyscdsb: '请选择要删除的设备',
	qdysczxsbm: '确定要删除这些设备吗',
	qxzyshdsb: '请选择要审核的设备',
	scdwjstxt: '上传的文件只能是TXT格式',
	scdwjdx: '上传的文件不能大于1MB',
	qianjin: '前进',
	houtui: '后退',
	mygdsjl: '没有更多数据了',
	EquipmentOnlineRate: '设备在线率',
	zdfb: '终端分布',
	zdgbzt: '终端广播状态',
	Unit: '单位',
	qxzdgb: '圈选终端广播',
	yjhh: '一键喊话',
	qsrmccx: '请输入名称查询',
	TerminalView: '终端查看',
	Rectangle: '矩形',
	Circle: '圆形',
	polygon: '多边形',
	zzgb: '正在广播',
	Satellite: '卫星',
	zzhhgb: '正在喊话广播',
	qxzgbzd: '请选择广播终端',
	zcsr: '再次输入',
	jymmts: '安全性高的密码可以使账号更安全。建议您定期更换密码，密码为8-20位数字、字母或特殊符号三种组合。',
	bdyxts: '绑定邮箱可以用于找回登录密码',
	bdsjts: '绑定手机可以用于找回登录密码',
	djtpkgh: '点击图片可更换',
	ddxq: '订单详情',
	fukuanren: '付款人',
	jine: '金额',
	qsryx: '请输入邮箱',
	qsrzqdyxdz: '请输入正确的邮箱地址',
	Tall: '高',
	Centre: '中',
	Low: '低',
	lcsrdmmbyz: '两次输入的密码不一致',
	qsrzqdsjh: '请输入正确手机号',
	qxzxb: '请选择性别',
	qxzqy: '请选择区域',
	nqdyzxczhm: '你确定要注销此账号吗',
	zxzh: '注销账号',
	qgxjs: '请勾选角色',
	wenxintishi: '温馨提示',
	cjfzdwxts: '用户组创建者即为用户组管理人，加入用户组后，您的设备将共享给用户组内其他用户',
	cjfzdwxts2: '每个用户只能创建一个用户组，每个用户只能加入一个用户组',
	qdycyhm: '确定移除{a}吗',
	yyyhtjsb: '{a}该用户已有用户组了,添加失败',
	byxcftj: '不允许重复添加',
	byxycyhcyzs: '不允许移除用户成员自身',
	qsryhzmc: '请输入用户组名称',
	qsrhzzmsz: '请输入汉字、字母或者数字',
	yhzcybnwk: '用户组成员不能为空， 请添加用户组成员',
	nqdyscyhzm: '你确定要删除用户组吗',
	nqdytcyhzm: '你确定要退出用户组吗',
	xzxjqy: '新增下级区域',
	sjqybm: '上级区域编码',
	sjqymc: '上级区域名称',
	xzqybm: '新增区域编码',
	xzqymc: '新增区域名称',
	xzqyqc: '新增区域全称',
	qsrgdsz: '请输入${a}位数',
	qsrqybm: '请输入区域编码',
	qsrqymc: '请输入区域名称',
	jdsryw: '经度输入有误',
	wdsryw: '纬度输入有误',
	tjxgqy: '添加/修改区域',
	nqdyscqym: '你确定要删除区域吗',
	scqy: '删除区域',
	qsrjsmc: '请输入角色名称',
	qxzjsqx: '请选择角色权限',
	qsrms: '请输入描述',
	nqdyscjsm: '你确定要删除角色吗',
	scjs: '删除角色',
	nqdyscgjm: '你确定要删除固件吗',
	qxscgj: '请先上传固件',
	yylx: '应用类型',
	yycxsc: '应用程序上传',
	yylxpz: '应用类型配置',
	gxnr: '更新内容',
	scz: '上传中',
	sfqzsj: '是否强制升级',
	yycxwj: '应用程序文件',
	tzwj: '拖拽文件或是点击图片添加文件，开始上传',
	yylxmc: '应用类型名称',
	lxgjz: '类型关键字',
	qsrlxgjz: '请输入类型关键字',
	qsryylxmc: '请输入应用类型名称',
	qxzyylx: '请选择应用类型',
	qxzscwj: '请选择上传文件',
	scwjmcbgf: '上传文件名称不规范',
	nqdyscyym: '你确定要删除应用吗',
	nqdyscgyylxm: '你确定要删除该应用类型吗',
	xjwa: '新建文案',
	nqdysccwam: '你确定要删除此文案吗',
	tjcc: '推荐尺寸',
	qsrbt: '请输入标题',
	qsrwanr: '请输入文案内容',
	qscfmt: '请上传封面图',
	sucaiku: '素材库',
	bdsc: '本地上传',
	laiyuan: '来源',
	wllj: '网络链接',
	Upload: '上传',
	sctctips: '只能上传.jpg,.png,.mp3,.mp4,.flv文件，且不超过20m',
	Tag: '标签',
	crsc: '插入素材',
	fabuyu: '发布于',
	wzx: '未执行',
	yzx: '已执行',
	qxzx: '取消执行',
	qxzgjbb: '请选择固件版本',
	qgxsb: '请勾选设备',
	nqdyqxzxrwm: '你确定要取消执行升级任务吗',
	ptxx: '平台信息',
	dbzxj: '底部左下角',
	dbjz: '底部居中',
	dbyxj: '底部右下角',
	dibjz: '顶部居中',
	xswz: '显示位置',
	qxzxswz: '请选择显示位置',
	xxfb: '消息发布',
	MessageTitle: '消息标题',
	MessageContent: '消息内容',
	MessageObject: '消息对象',
	fbxx: '发布消息',
	fbTime: '发布时间',
	Object: '对象',
	qgxxdx: '请勾选消息对象',
	nqdyscxxm: '你确定要删除消息吗',
	tjlmtfw: '添加流媒体服务',
	xglmtfw: '修改流媒体服务',
	文件服务器: '文件服务器',
	turn服务器: 'turn服务器',
	kms服务器: 'kms服务器',
	业务服务器: '业务服务器',
	信令服务器: '信令服务器',
	mtfwlx: '媒体服务类型',
	sfqy: '是否启用',
	mtfwmc: '媒体服务名称',
	mtfwdz: '媒体服务地址',
	qsrmtfwmc: '请输入媒体服务名称',
	qsrmtfwdz: '请输入媒体服务地址',
	fwqcc: '服务器存储',
	coscc: '腾讯云对象存储',
	qsrcctmc: '请输入存储桶名称',
	qsrcctqy: '请输入存储桶区域',
	qsrid: '请输入id',
	qsrmy: '请输入密钥',
	dymc: '地域名称',
	dxyyid: '短信应用ID',
	dxqm: '短信签名',
	zhmmmbid: '找回密码模板ID',
	khzh: '客户账号',
	bdkh: '绑定客户',
	jcbd: '解除绑定',
	xgzhxx: '修改账号信息',
	nqdyjcbdczhm: '你确定要解除绑定此账号吗',
	Back: '返回',
	Play: '播放',
	jypfxgyhzncy: '将音频分享给用户组内成员',
	jsbfxgyhzncy: '将设备分享给用户组内成员',
	AddTask: '添加任务',
	EditScheme: '编辑方案',
	dlsx: '登录失效',
	xjxcxcz: '先进行查询操作',
	ExportDevice: '导出设备',
	Agree: '同意',
	Refuse: '拒绝',
	InviteToJoin: '邀请加入',
	InvitedPleaseWait: '已邀请，等待对方同意',
	PlayedTask: '已播任务',
	PlayAgain: '再播一次',
	SureToPlayAgain: '确定要再播一次吗',
	NumberOfTaskStarts: '启动次数',
	startInterval: '启动间隔',
	minute: '分钟',
	BadRequest: '错误请求',
	LoginAgain: '未授权，请重新登录',
	NoAccess: '拒绝访问',
	NotFound: '请求错误,未找到该资源',
	RequestMethodNotAllowed: '请求方法未允许',
	RequestTimeout: '请求超时',
	ServerSideError: '服务器端出错',
	NetworkNotImplemented: '网络未实现',
	NetworkError: '网络错误',
	ServiceUnavailable: '服务不可用',
	NetworkTimeout: '网络超时',
	DoesNotSupport: 'http版本不支持该请求',
	ConnectionError: '连接错误',
	RequestCanceled: '请求已取消',
	ConnectionFailed: '连接到服务器失败',
	Loading: '加载中',
	LoginFirst: '请先登录',
	DefaultLanguage: '默认语言',
	LoggingOut: '退出登录中',
	SelectDate: '选择日期',
	PleaseSelectDate: '请选择日期',
	qiyonghou: '启用后，定时任务在节日日期不启动',
	PleaseEnterHolidayName: '请输入节日名称',
	AreYouSureToDeleteHoliday: '确定要删除该节日吗',
	AdvancedSetting: '高级设置',
	lianxiguanliyuan: '您没有当前操作的权限,请联系管理员',
	APPDownload: '安卓APP下载',
	Shares: '分享',
	MenuLoading: '菜单加载中',
	CurrentVersion: '当前版本',
	ServiceMonitoring: '服务监控',
	ServerMonitor: '服务器监控',
	ServeName: '服务名称',
	RunningState: '运行状态',
	NormalOperation: '运行正常',
	cpuper: 'CPU使用率',
	memoryper: '内存使用率',
	diskper: '磁盘使用率',
	bandwidthper: '宽带使用',
	Usage: '使用',
	OutputBandwidth: '出带宽',
	IncomingBandwidth: '入带宽',
	DisplayInterval: '显示间隔',
	DeleteCopy: '删除文案',
	audioRecord: '音频录制',
	RecordingAudio: '录制音频',
	SoundRecording: '录音',
	Microphone: '麦克风',
	StartRecording: '开始录制',
	StopRecording: '停止录制',
	Recording: '正在录制',
	RecordUpload: '录制上传',
	ReRecord: '重新录制',
	ConfirmUpload: '确认上传',
	NotSupported: '暂不支持',
	NotSupportedForDisplayingContent: '暂不支持显示内容',
	RealTime: '实时',
	Nearly24hours: '近24小时',
	InThePastThreeDays: '近三天',
	Everyday: '每天',
	Mondays: '每周一',
	FirstFayOfEachMonth: '每月一日',
	BackupTime: '备份时间',
	BackedUpTimes: '已备份次数',
	BackupObject: '备份对象',
	Timing: '选择时间',
	Database: '数据库',
	PleaseSelectTime: '请选择时间',
	CreateScheduledBbackupTask: '创建定时备份任务',
	BackupNow: '立即备份',
	PleaseSelectBackupObject: '请选择备份对象',
	AreYouSureToDeleteTheBackupTask: '你确定要删除备份任务吗',
	BackupFiles: '备份文件',
	Size: '大小',
	AreYouSureToDeleteTheBackupFiles: '你确定要删除备份文件吗',
	RecordNeedHttps: '录制功能需要以https方式访问',
	Export: '导出',
	timedCalibration: '全网定时校时',
	Horn: '喇叭',
	LoadExternalNetworkResources: '是否加载外网资源',
	HTTPSAccessAddress: 'https访问地址',
	ConstantVoltageSwitching: '定压切换',
	ReplicationTask: '复制任务',
	SchemeBelongs: '所属方案',
	OpenRegistration: '开放注册',
	ssylbfsc: '实时预览播放时长',
	SavePreview: '保存预览记录',
	bcscdyypsc: '建议播出时长大于音频时长',
	RefreshDeviceStatus: '刷新设备状态',
	BroadcastNeedHttps: '喊话广播功能',
	NeedHttps: '需要以https方式访问',
	cjqgb: '采集器广播',
	transcodeStatus: '转码状态',
	untranscode: '未转码',
	transcoded: '转码完成',
	transcodefail: '转码失败',
	transcodewait: '等待转码',
	transcodeing: '正在转码',
	UserAgreement: '用户协议',
	TriggerChannel: '触发通道',
	TaskType: '任务类型',
	HongWaiChuFa: '红外触发',
	XiaoFangChuFa: '消防触发',
	Terminal: "终端",
	SmartHost: '智能主机',
	PowerSupplyStatus: '供电状态',
	NormalMainsPowerSupply: '市电正常',
	UtilityFailure: '市电停电',
	PowerAmplifierPartitionNotes: '功放分区备注',
	NoteName: '备注名称',
	Liaison: '联系人',
	Telephone: '联系电话',
	PowerAmplifierOn: '开启功放电源',
	PowerAmplifierOff: '关闭功放电源',
	TurnOnTheAmplifierInAdvance: '提前开启功放',
	PowerAmplifierPartition: '功放/发射分区',
	SelectPowerAmplifierPartition: '请选择功放分区',
	AnjianRenwu: '音乐广播转终端按键任务',
	QuanQuKaiQi: '功放全区开启',
	QiJinYongSheBei: '启/禁用设备',
	TriggerMode: '触发模式',
	ImportTip1: '拖到文件到此处，或点击上传',
	DownloadTemplate: '下载模板',
	InvalidFileSuffix: '文件格式仅支持：{0}',
	ContentQuery: '内容查询',
	Played: '已播',
	Unplayed: '未播',
	DeleteShortcutMenu: '删除快捷菜单',
	SureToDeleteShortcutMenu: '确定要删除此快捷菜单吗',
	AddShortcutMenu: '删除快捷菜单',
	ShortcutMenuList: '快捷菜单列表',
	UploadFileSizeLimit: '上传文件大小限制',
	BroadcastDefaultParameters: '广播默认参数',
	BroadcastingTargetType: '默认广播目标类型',
	DefaultbroadcastMode: '默认播出方式',
	Modulation: '音量调节',
	NewSchemeName: '新方案名称',
	TodayBroadcastPlan: '今日播出计划',
	BatchModifyTasks: '批量修改任务',
	BatchModify: '批量修改',
	NumberOfSelectedTasks: '已选任务个数',
	PleaseCheckTheParameters: '请勾选需要修改的参数',
	NoPermissionTemporarily: '暂无权限',
	bcscbnxyypsc2: '播出时长不能小于音频时长*播出次数',
	CurrentlyDoesNotSupportDisplayingContent: '暂不支持显示内容',
	AreYouSureToSaveIfThereIsAConflict: '与下列任务有冲突，确认要保存吗',
    ...zhLocale
}