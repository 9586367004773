<template>
  <section class="bread">
    <el-breadcrumb separator="/" class="el-bread">
      <el-breadcrumb-item v-for="({meta: { title }}, index) of $route.matched" :key="index">
        <span :class="addAnimationClass(index)">{{ $t(title) }}</span>
      </el-breadcrumb-item>
    </el-breadcrumb>
  </section>
</template>
<script>
export default {
  name: "Bread", //面包屑组件
  data() {
    return {
      show: false, //是否开启动画
      timer: null //动画定时器 防抖
    }
  },
  computed: {
    //添加动画类名
    addAnimationClass(){
      return index => {
        // 只最后一个面包屑有动画
        if(index + 1 === this.$route.matched.length && this.show){
          return 'new'
        }
      }
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler() {
		if(this.$route.meta.needKeep) this.$store.dispatch('publicOptions/addCachedView', this.$route)
        if (this.timer) clearTimeout(this.timer)
        this.show = true
        this.timer = setTimeout(() => {
          this.show = false
          this.timer = null
        }, 1000)
      }
    }
  }
}
</script>
<style lang="less" scoped>
.bread {
  background-color: #F6F8F9;
  padding: 0 25px;
  height: 55px;
  /deep/ .el-breadcrumb__inner{
	color: #666;
	line-height: 55px;
  }
  .el-bread {
    display: flex;
    align-items: center;
    font-size: 14px;
    .new {
      animation: animate 0.6s linear;
    }
  }
  .route-title {
    margin: 13px 0 3px 0;
    font-size: 22px;
  }
  /deep/ .el-breadcrumb__separator{
	color: #666;
	margin: 0 5px;
	font-weight: normal;
  }
}
.el-bread :last-child span{
  font-size: 16px;
  color: #333;
  font-weight: bold;
  display: block;
}
@keyframes animate {
  0% {
    transform: translateX(50%);
  }
  100% {
    transform: translateX(0);
  }
}
</style>