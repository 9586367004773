export default[{
    path: '*',
    name: '404',
    component: () => import(/*webpackChunkName: 'error'*/'@/components/Error/Error'),
    meta: {
        title: 'error',
        showBread: false, 
        showNav: false,
        showLeftMenu: false,
        module: 'error'
    }
}]