import axios from 'axios'
import { Message, Loading, MessageBox } from 'element-ui'
import { localGet, localDel, localSet } from '@/configs/storage'
import router from '../router'
import store from '../store'
import i18n from '@/i18n'
//axios实例
var development = process.env.NODE_ENV === 'development'
var protocol = location.protocol
var hostname = location.hostname
var onlineUrl = `${protocol}//${location.hostname}:${location.port}`
var baseURL1 = development ? '/AdminApi' : onlineUrl
var baseURL2 = development ? '/AdminApi/ystms' : `${onlineUrl}/ystms`
var changeBaseURL = false
var showMessageBoxEd = false  //是否显示在别的地方登录的弹窗
const ystHttpRequest = axios.create({
  //  development开发模式 production生产模式
  baseURL: baseURL2,
  timeout: 8000 //默认超时时间
})

// 添加请求拦截器
ystHttpRequest.interceptors.request.use(
  config => config,
  err => Promise.reject(err)
)

const setbaseURL = (needYstms) => {
	if(!needYstms){
		//needYstms为false的时候修改baseURL1（链接不带/ystms）
		ystHttpRequest.defaults.baseURL = baseURL1
		//changeBaseURL修改为true，表示baseURL修改过
		changeBaseURL = true
	}else if(changeBaseURL){
		//needYstms为true且changeBaseURL为true表示baseURL修改过，修改baseURL为初始值（链接带/ystms）
		ystHttpRequest.defaults.baseURL = baseURL2
		changeBaseURL =false
	}
}

const toLogin = (message, noTips) => {
	store.commit("publicOptions/SAVE_TOKEN", '') //删除vuex中的token
	localDel('token') //删除本地存储中的token
	showMessageBoxEd = false
	if(router.history.current.meta.module && router.history.current.meta.module != 'login'){
		if(!localGet('logintips') && !noTips){
			Message.error(message)
			localSet("logintips", new Date().getTime())
		}
		router.replace('/') //跳转回登录页
		setTimeout(() => {
			localDel('logintips')
		}, 1000)
	}
}

// 添加响应拦截器
ystHttpRequest.interceptors.response.use(
  response => response,
  err => {
    if (err && err.response) {
      switch (err.response.status) {
        case 400:
          Message.error(i18n.t('BadRequest'))
          break
        case 401:
          Message.error(i18n.t('LoginAgain'))
          break
        case 403:
          Message.error(i18n.t('NoAccess'))
          break
        case 404:
          Message.error(i18n.t('NotFound'))
          break
        case 405:
          Message.error(i18n.t('RequestMethodNotAllowed'))
          break
        case 408:
          Message.error(i18n.t('RequestTimeout'))
          break
        case 500:
          Message.error(i18n.t('ServerSideError'))
          break
        case 501:
          Message.error(i18n.t('NetworkNotImplemented'))
          break
        case 502:
          Message.error(i18n.t('NetworkError'))
          break
        case 503:
          Message.error(i18n.t('ServiceUnavailable'))
          break
        case 504:
          Message.error(i18n.t('NetworkTimeout'))
          break
        case 505:
          Message.error(i18n.t('DoesNotSupport'))
          break
        default:
          Message.error(`${i18n.t('ConnectionError')}${err.response.status}`)
      }
    } else if (err && err.__CANCEL__) {
      Message.error(i18n.t('RequestCanceled'))
    } else {
      Message.error(i18n.t('ConnectionFailed'))
    }
    return Promise.reject(err.response)
  })

let loadingInstance = null //加载动画变量
const loadingFn = (isLoading, state, text = `${i18n.t('Loading')}...`) => { //加载动画函数
  if (isLoading) {
    if (state === 'open') {
      loadingInstance = Loading.service({
        lock: true,
        text,
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.2)'
      })
    } else {
      setTimeout(() => {
        loadingInstance.close()
      }, 500)
    }
  }
}

// axios请求封装
export const secondaryPackagingRequest = ({
  url, //接口路径
  data = {}, //POST参数
  params = {}, //GET参数
  headers = {  //请求头
    'Content-Type': 'application/x-www-form-urlencoded'
  },
  method = 'GET', //请求类型
  timeout = 8000, //超时时间
  isLoading = false, //是否显示加载动画
  needToken = true, //是否需要token
  loadingText, //加载动画文字
  cancelToken, //取消请求
  responseType = 'json', //响应参数类型
  needYstms = true, //响应参数类型
  needTips = true, //请求失败是否提升
  onUploadProgress //上传进度函数
}) => {
  setbaseURL(needYstms)
  return new Promise((resolve, reject) => {
    if (needToken) headers.Authorization = localGet('token')  //是否需要token 需要添加Authorization
	let lang = 'zh_CN'
	const saveLang = localGet('lang')
	if(saveLang == 'en'){
		lang = 'en_US'
	}else if(saveLang == 'th'){
		lang = 'th_TH'
	}else if(saveLang == 'vi'){
		lang = 'vi_VN'
	}
	headers.lang = lang
    loadingFn(isLoading, 'open', loadingText) //开启加载动画
    ystHttpRequest({
      url,
      data,
      params,
      headers,
      method,
      timeout,
      cancelToken,
      responseType,
      onUploadProgress
    }).then(res => {
	  loadingFn(isLoading, 'close') //关闭加载动画
      let { code, message, msg } = res.data
	  if(!message && msg) message = msg
      if (code !== 200 && code!== -10001 && code!== 405 && code!== 401 && code && needTips) Message.error({
		dangerouslyUseHTMLString: true,
		message: message,
	  }) //请求错误时的提示,-20000为特定错误，需要根据具体情况特殊处理
      if (code === 401) { // 登录超时 删除token 返回登录页
        toLogin(message)
      }else if(code == 405){
		if(!showMessageBoxEd){
			showMessageBoxEd = true
			MessageBox.confirm(message, i18n.t('xtts'), {
			  confirmButtonText: i18n.t('determine'),
			  cancelButtonText: i18n.t('cancel'),
			  type: 'warning'
			}).then(async () => {
				toLogin(message, 1)
			}).catch(() => {
				toLogin(message, 1)
			})
		}
	  }
      if (responseType === 'arraybuffer') resolve(res)
      else resolve(res.data)
    }).catch(err => {
      loadingFn(isLoading, 'close') //关闭加载动画
      reject(err)
    })
  })
}