// 越南
import viLocale from 'element-ui/lib/locale/lang/vi'
export default {
    smartCloudBroadcastingSystem: 'HỆ THỐNG TRUYỀN THANH ĐÁM MÂY THÔNG MINH',
	switchLanguage: 'Chuyển đổi ngôn ngữ',
	register: 'Đăng ký',
	verificationCode: 'Mã xác nhận',
	secureLogin: 'Đăng nhập an toàn',
	returnToLogin: 'Trở về trang đăng nhập',
	login: 'Đăng nhập',
	autologin: 'Đăng nhập',
	error: 'Lỗi',
	downloadCenter: 'Trung tâm tải xuống',
	downloaddetail: 'Trung tâm tải xuống',
	commonProblem: 'Trung tâm trợ giúp',
	freeRegistration: 'Đăng kí nhanh',
	agreement: 'Giao thức',
	findPassword: 'Lấy lại mật khẩu',
	loginOut: 'Đăng xuất',
	switchTheme: 'Chuyển đổi chủ đề',
	black: 'Màu đen',
	blue: 'Màu xanh da trời',
	gray: 'Màu xám',
	home: 'Trang đầu',
	paste: 'Dán',
	forever: 'Vĩnh viễn',
	broadcast: 'PHÁT THANH',
	broadcastRelease: 'Tạo lệnh',
	realTimeBroadcast: 'Phát trực tiếp',
	musicbroadcast: 'Phát nhạc',
	timedBroadcast: 'Hẹn giờ phát thanh',
	deviceOnDemand: 'Thiết bị chọn phát',
	deviceBroadcastd: 'Phát sóng thiết bị',
	visualBroadcasting: 'phát sóng trực quan',
	shoutingbroadcast: 'PHÁT TRỰC TIẾP',
	mediaLibrary: 'Kho thư viện',
	deviceManagement: 'Quản lý thiết bị',
	monitoringEvaluation: 'Giám sát và đánh giá',
	platformManagement: 'Quản lý nền tảng',
	accountManagement: 'Quản lý tài khoản',
	systemManagement: 'Quản lý hệ thống',
	mochaITOM: 'Quản lý vận hành và bảo trì',
	thirdPartyAccess: 'Quyền truy cập của bên thứ ba',
	serviceRenewal: 'Gia hạn dịch vụ',
	personalSettings: 'Thiết lập cá nhân',
	userManagement: 'Quản lý người dùng',
	userGroupManagement: 'Quản lý nhóm người dùng',
	orderlist: 'Ghi chép đơn hàng',
	ordernumber: 'Mã đơn hàng',
		Ordercontent: 'Nội dung đơn hàng',
		Orderdetails: 'Chi tiết đơn hàng',
		drawee: 'Người trả tiền',
		amount: 'Tổng tiền',
		Paymentmethod: 'Phương thức thanh toán',
	Billingcenter: 'Trung tâm xuất hóa đơn',
	Toinvoice: 'Xuất hóa đơn',
	Electronicinvoice: 'Hóa đơn điện tử',
		servicerenewal: 'Gia hạn dịch vụ',
	regionalManagement: 'Quản lý khu vực',
	roleManagement: 'Quản lý vai trò',
	menuManagement: 'Quản lý thực đơn',
	firmwareManagement: 'Quản lý phần mềm',
	upgradeManagement: 'Quản lý nâng cấp',
	operationLog: 'Nhật ký hoạt động',
	aboutPlatform: 'Về nền tảng',
	dataBaseBackup: 'Sao lưu dữ liệu',
	mediaServiceManagement: 'Quản lý dịch vụ truyền thông',
	systemLog: 'Nhật ký hệ thống',
	operationMonitoring: 'Giám sát vận hành',
	onlineService: 'Dịch vụ trực tuyến',
	systemMessageRelease: 'Công bố thông tin hệ thống',
	filestorage: 'Dịch vụ lưu trữ tập tin',
		fileStorageMethod: 'Phương pháp lưu trữ tập tin',
	emergencyPlan: 'Phương án khẩn cấp',
	callIntercom: 'Gọi đối thoại',
	fireLinkage: 'Liên kết phòng cháy',
	gisMap: 'bản đồ GIS',
	audioMedia: 'Phương tiện âm thanh',
	audio: 'Âm thanh',
	speechSynthesis: 'Chuyển đổi giọng nói',
	acquisitionEquipment: 'Thu thập thiết bị ',
	acquisitionEquipment2: 'Thu thập thiết bị (Bộ thu + micro IP)',
	intercomTerminal: 'Đôi thoại thiết bị đầu cuối',
	ipAdapter: 'Bộ chuyển đổi IP',
	ipMicrophone: 'Micro IP',
	collector: 'Người thu thập',
		mfr: 'Ghi phát',
	receivingTerminal: 'Bộ thu',
	equipmentWarehousing: 'Thiết bị nhập kho',
	deviceGroup: 'Phân nhóm thiết bị',
	broadcastRecord: 'Nhật ký phát thanh',
	audioHome:{
		broadcastRecord: 'Nhật ký phát ra',
	},
	broadcastStatistics: 'Thống kê',
	largedataScreen: 'Màn hình dữ liệu lớn',
	offlineTerminal: 'Bộ thu ngoại tuyến',
	resources: 'Tình hình tài nguyên',
	serviceAlarm: 'Báo động kinh doanh',
	offlineBroadcast: 'Phát sóng ngoại tuyến',
	mediaAudit: 'Sát hạch truyền thông',
	sensitiveWords: 'Từ nhạy cảm',
	sensitiveVocabulary: 'Từ vựng nhạy cảm',
	newSensitiveWords: 'Thêm từ nhạy cảm',
	textContent: 'Nội dung văn bản',
	textName: 'Tên văn bản',
	informant: 'Người phát âm',
	speedOfSpeech: 'Tốc độ giọng nói',
	intonation: 'Âm điệu',
	delay: 'Trì hoãn',
	content: 'Nội dung',
	oneTouchScreenProjection: 'Kéo thả vào màn hình',
	systemMessage: 'Thông tin hệ thống',
	deviceStatistics: 'Thống kê thiết bị',
	totalCount: 'Tổng cộng',
	online: 'Trực tuyến',
	offline: 'Ngoại tuyến',
	menuShortcutentry: 'Đưa vào màn hình chính',
	planAlarm: 'Kế hoạch báo động',
	fire: 'Tường lửa',
	earthquake: 'Động đất',
	airDefense: 'Phòng không',
	broadcasting: 'ĐANG PHÁT THANH',
	broadcastPlan: 'Kế hoạch phát thanh',
	broadcastMonitoring: 'Giám sát phát thanh',
	turnOffBroadcast: 'TẮT MÁY',
	detail: 'Chi tiết',
	theDayBefore: 'Ngày hôm trước',
	theDayAfter: 'Ngày hôm sau',
	close: 'Đóng',
	broadcastFrequencyStatistics: 'Thống kê số lần phát thanh',
	broadcastStatusStatistics: 'Thống kê trạng thái phát thanh',
	terminalDistribution: 'Phân bố thiết bị đầu cuối',
	quantity: 'Số lượng',
	many: 'Cái',
	equipmentType: 'Loại thiết bị',
	equipmentTypeStatistics: 'Thống kê loại thiết bị',
	terminalOnlineStatistics: 'Thống kê thiết bị online',
	activateIntercomTerminal: 'Kích hoạt thiết bị đối thoại',
	activateCollectionDevice: 'Kích hoạt thiết bị thu thập',
	modifyCollectionDevice: 'Sửa đổi thiết bị thu thập',
	modifyIntercomTerminal: 'Sửa đổi thiết bị đối thoại',
	modifyTerminal: 'Sửa đổi thiết bị',
	broadcastSuccessful: 'Phát thanh thành công',
	broadcastFailure: 'Phát thanh thất bại',
	onlineRate: 'Tỷ lệ online',
		task: 'Nhiệm vụ',
	taskName: 'Tên nhiệm vụ',
	priority: 'Cấp ưu tiên',
	broadcastTarget: 'Mục tiêu phát thanh',
	startTime: 'Thời gian bắt đầu',
	endTime: 'Thời gian kết thúc',
	volume: 'Âm lượng',
	operation: 'Thao tác',
	newTask: 'Tạo nhiệm vụ mới',
	sameDay: 'Trong ngày',
	sevenDay: '7 ngày',
	oneMonth: 'Một tháng',
	startDate: 'Ngày bắt đầu',
	endDate: 'Ngày kết thúc',
	search: 'Tìm kiếm',
	query: 'Truy tìm',
	number: 'Số thứ tự',
	serialNumber: 'Số seri',
	broadcastMode: 'Phương thức phát thanh',
	broadcastStatus: 'Trạng thái phát thanh',
	operator: 'Người thao tác',
	offBroadcastTime: 'Thời gian dừng phát',
	cycle: 'Chu kỳ',
	enable: 'Cho phép',
	disable: 'Vô hiệu hóa',
	creationTime: 'Thời gian lập lệnh',
	whole: 'Tất cả',
	date: 'Ngày tháng',
	currentlyEnabledScheme: 'Phương án đang chọn dùng',
	newScheme: 'Tạo kế hoạch mới',
	removeScheme: 'Xóa kế hoạch',
	enableScheme: 'Bắt đầu sử dụng phương án',
	copyScheme: 'Sao chép',
	updateTask: 'Chỉnh sửa',
	delete: 'Xóa',
	refresh: 'Làm mới',
	to: 'đến',
	audioUpload: 'Tải âm thanh lên',
	mediaCategory: 'Thể loại',
	mediaDetails: 'Chi tiết',
	fileName: 'Tên tập tin',
	duration: 'Khoảng thời gian',
	uploader: 'Người tải lên',
	uploadTime: 'Thời gian tải lên',
	audit: 'Xét duyệt',
	auditStatus: 'Trạng thái xét duyệt',
	reviewer: 'Người xét duyệt',
	auditTime: 'Thời gian xét duyệt',
	audition: 'Nghe thử',
	audioMediaName: 'Tên phương tiện âm thanh',
	textView: 'Xem văn bản',
	reviewComments: 'Ý kiến ​​xét duyệt',
	newlyAdded: 'Thêm mới',
	newlyAdded2: 'Thêm mới',
	categoryName: 'Phân loại tên gọi',
	category: 'Thuộc quyền phân loại',
	edit: 'Biên tập',
	mediaLevel: 'Cấp độ',
	uploadFile: 'Tải tập tin lên',
	clickUpload: 'Bấm để tải lên',
	determine: 'Xác nhận',
	cancel: 'Hủy bỏ',
	createText: 'Tạo văn bản',
	textSpeechSynthesis: 'Chuyển đổi văn bản thành giọng nói',
	findingsOfAudit: 'Kết quả xét duyệt',
	mediaType: 'Loại phương tiện truyền thông',
	activation: 'Kích hoạt',
	activateTerminal: 'Kích hoạt thiết bị',
	terminal: 'Thiết bị',
	terminalName: 'Tên thiết bị ',
	equipment: 'Thiết bị',
	name: 'Tên gọi',
	groupName: 'Tên nhóm',
	terminalNumber: 'Số kênh',
	groupingName: 'Tên nhóm',
	status: 'Trạng thái',
	activatePersonnel: 'Người kích hoạt',
	activateTime: 'Thời gian kích hoạt',
	servicePeriod: 'Thời hạn dịch vụ',
	specialSettings: 'Cài đặt đặc biệt',
	quit: 'Thoát ra',
	monitoringAndMonitoring: 'Giám sát âm thanh',
	callObjectSettings: 'Cài đặt đối tượng cuộc gọi',
	commandControl: 'Lệnh điều khiển',
	onlineRecord: 'Nhật ký online',
	physicalAddress: 'Địa chỉ vật lý',
	warehousingStatus: 'Trạng thái nhập kho',
	newEquipmentGrouping: 'Thêm nhóm thiết bị mới',
	newEquipmentGrouping2: 'Thêm nhóm thiết bị mới',
	modifyEquipmentGrouping: 'Sửa đổi ',
	groupNumber: 'Số nhóm',
	type: 'Kiểu',
	coverage: 'Độ phủ sóng',
	timeFrame: 'Phạm vi thời gian',
	broadcastStatisticalMethod: 'Phương thức thống kê phát thanh',
	broadcastType: 'Kiểu phát thanh',
	user: 'Người dùng',
	area: 'Khu vực',
	playbackTimes: 'Số lần phát',
		startplay: 'BẮT ĐẦU PHÁT THANH',
	seconds: 'lần',
	download: 'Tải xuống',
	lineChart: 'Hình đường gấp khúc',
	barChart: 'Hình dạng trụ',
	lastOfflineTime: 'Thời gian ngoại tuyến cuối cùng',
	offlineDays: 'Số ngày ngoại tuyến',
	exportTerminalInformation: 'Xuất thông tin thiết bị',
	securitySetting: 'Cài đặt an toàn',
	personalData: 'Thông tin cá nhân',
	orderRecord: 'Nhật ký đặt hàng',
	account: 'Tài khoản thuộc sở hữu',
	addTo: 'Thêm vào',
	moveUp: 'Lên',
	moveDown: 'Xuống',
	callObjectList: 'Danh sách đối tượng cuộc gọi',
	callObjectAddition: 'Thêm đối tượng cuộc gọi',
	accountNumber: 'Tài khoản',
	accountLevel: 'Cấp độ tài khoản',
	removeUserGroup: 'Di chuyển ra khỏi nhóm',
	fullName: 'Tên',
	mailbox: 'Hòm thư',
	mobilePhone: 'Điện thoại di động',
		requeirPhone: 'Vui lòng nhập số điện thoại',
	sex: 'Giới tính',
	role: 'Vai trò',
	userGroup: 'Nhóm người dùng',
	addToUserGroup: 'Thêm vào nhóm người dùng',
	addUserGroupMembers: 'Thêm thành viên nhóm người dùng',
	managementArea: 'Khu vực quản lý',
	mapPositioning: 'Bản đồ định vị',
	registrationTime: 'Thời gian đăng ký',
	yourBasicInformation: 'Thông tin cơ bản',
	yourSecurityServices: 'Dịch vụ bảo mật',
	safetyLevel: 'Cấp độ bảo mật',
	loginPassword: 'Mật khẩu đăng nhập',
	bindMailbox: 'Ràng buộc email',
	bindingMobilePhone: 'Ràng buộc điện thoại di động',
	changePassword: 'Đổi mật khẩu',
	modifyMobilePhone: 'Sửa đổi điện thoại di động',
	addEmail: 'Thêm email',
	replaceMailbox: 'Thay đổi email',
	replacementArea: 'Thay đổi khu vực',
	low: 'Thấp',
	middle: 'Ở giữa',
	high: 'Cao',
	male: 'Nam',
	female: 'Nữ',
	currentAvatar: 'Hình đại diện hiện tại',
	changeTheAvatar: 'Thay đổi hình đại diện',
	other: 'Khác',
	preservation: 'Lưu',
	registeredAccount: 'Đăng ký tài khoản',
	roleAuthorization: 'Ủy quyền vai trò',
	fixedLocation: 'Định vị',
	createUserGroup: 'Tạo nhóm người dùng',
	userGroupName: 'Tên nhóm người dùng',
	creator: 'Người tạo',
	groupMembers: 'Thành viên trong nhóm',
	addInitialArea: 'Thêm khu vực ban đầu',
	areaCode: 'Mã khu vực',
	areaCoding: 'Mã vùng',
		Superior: 'Cấp trên',
	areaName: 'Tên khu vực',
	regional: {
		fullName: 'Tên đầy đủ',
	},
	areaFullName: 'Tên đầy đủ khu vực',
	longitudeAndLatitude: 'Vĩ độ và kinh độ',
	longitude: 'Kinh độ',
	currentLongitude: 'Kinh độ hiện tại',
	latitude: 'Vĩ độ',
	currentLatitude: 'Vĩ độ hiện tại',
	addChildRegion: 'Thêm khu vực cấp dưới mới',
	newRole: 'Thêm vai trò mới',
	modifyRole: 'Sửa đổi vai trò',
	rolePermissions: 'Quyền hạn của vai trò',
	roleID: 'ID vai trò',
	roleName: 'Tên vai trò',
	describe: 'Mô tả',
	firmwareUpload: 'Tải lên',
	firmwareFile: 'Tập tin phần mềm',
	versionCode: 'Mã phiên bản',
	versionName: 'Tên phiên bản',
	equipmentUpgrade: 'Nâng cấp thiết bị',
	upgradeRecord: 'Nhật ký nâng cấp',
	upgradeTarget: 'Mục tiêu nâng cấp',
	equipmentName: 'Tên thiết bị',
	firmwareVersion: 'Phiên bản phần mềm',
	firmwarePackageName: 'Tên gói phần mềm',
	forcedUpgrade: 'Buộc nâng cấp',
	upgradeNow: 'Nâng cấp ngay lập tức',
	anyPointInTime: 'Bất kỳ thời điểm nào',
	taskCreationTime: 'Thời gian tạo nhiệm vụ',
	upgradeAccordingToTheSpecifiedTime: 'Nâng cấp theo thời gian quy định',
	deviceSoftwareVersion: 'Phiên bản phần mềm thiết bị',
	force: 'Cưỡng chế',
	upgradeTime: 'Thời gian nâng cấp',
	upgradeProgress: 'Tiến độ nâng cấp',
	cancelExecution: 'Hủy lệnh',
	logLevel: 'Cấp nhật ký',
	logContent: 'Nội dung nhật ký',
	logTime: 'Ghi lại nhật ký thời gian',
	news: 'Thông tin',
	warning: 'Cảnh báo',
	systemName: 'Tên hệ thống',
	softwareAbbreviation: 'Tên viết tắt của phần mềm',
	systemAbbreviation: 'Tên viết tắt của Hệ thống ',
	softwareVersion: 'Phiên bản phần mềm',
	SoftwareName: 'Tên phần mềm',
	copyright: 'Bản quyền',
	serviceProviderAddress: 'Địa chỉ nhà cung cấp dịch vụ',
	serviceProvider: 'Bên cung cấp dịch vụ',
	modifyPlatformParameters: 'Sửa đổi các tham số',
	retry: 'Thử lại',
	password: 'Mật khẩu',
	onTheAir: 'Đang phát thanh',
	notbroadcast: 'Chưa phát thanh',
	auditReceipt: 'Xét duyệt lưu kho',
	inIntercom: 'Đang đối thoại',
	notWarehoused: 'Chưa nhập kho',
	warehoused: 'Đã nhập kho',
	auditFree: 'Miễn xét duyệt',
	notApproved: 'Chưa xét duyệt',
	Approved: 'Xét duyệt thông qua',
	passTheAudit: 'Thông qua xét duyệt',
	failedToPassTheReview: 'Xét duyệt không đạt',
	schemeName: 'Tên phương án',
	playbackMode: 'Phương thức phát thanh',
	broadcastAudio: 'Phát ra âm thanh',
	broadcastNumbers: 'Số lần phát ra',
	numberOfCycles: 'Số lần lặp lại',
	grouping: 'Phân nhóm',
	partition: 'Phân khu',
	airDate: 'Ngày tháng phát',
	broadcastTimes: 'Thời gian phát',
	broadcastCycle: 'Chu kỳ phát',
	Monday: 'Thứ 2',
	Tuesday: 'Thứ 3',
	Wednesday: 'Thứ 4',
	Thursday: 'Thứ 5',
	Friday: 'Thứ 6',
	Saturday: 'Thứ 7',
	Sunday: 'Chủ nhật',
	writtenWords: 'Chữ',
	audioFile: 'Tập tin âm thanh',
	radioStation: 'Đài',
	radioRelay: 'Đài phát thanh',
	streamingAddress: 'Địa chỉ luồng',
	streamingAddressRelay: 'Chuyển phát địa chỉ luồng',
	triggerEvent: 'Xuất hiện sự việc',
	stopBroadcastingMode: 'Dừng phát sóng',
	fault: 'Lỗi',
	numberOfTerminals: 'Số lượng thiết bị',
	terminalList: 'Danh sách thiết bị',
	singleCycle: 'Phát từng lần',
	loopPlayback: 'Phát lặp lại',
	remark: 'Ghi chú',
	time: 'Thời gian',
	addMediaCategory: 'Thêm ',
	modifyMediaCategory: 'Chỉnh sửa ',
	modifyText: 'Chỉnh sửa văn bản',
	modifyUserGroup: 'Sửa đổi nhóm người dùng',
	modifyArea: 'Sửa đổi khu vực',
	pleaseEnterYourName: 'Vui lòng nhập họ tên',
	pleaseEnterTheAccountNumber: 'Vui lòng nhập tên người dùng',
	service: 'Dịch vụ',
	playing: 'Đang phát thanh',
	playbackSuccessful: 'Phát thanh thành công',
	playbackFailed: 'Phát thanh thất bại',
	Selectedequipment: 'Chọn thiết bị',
	applicationmanagement: 'Quản lý ứng dụng',
	Versionnumber: 'Số phiên bản',
	yes: 'OK',
		billingmode: 'Mô hình tính phí dịch vụ',
		ordermanagement: 'Quản lý đơn hàng',
		Daterange: 'Phạm vi ngày tháng',
		invoice: 'Hóa đơn',
		Gocheck: 'Xem',
		second: 'Giây',
		Price: 'Giá cả',
		invoicingmanagement: 'Quản lý hóa đơn',
		Invoicingrequisition: 'Trình duyệt hóa đơn',
		Medialist: 'Danh sách phương tiện truyền thông',
		Broadcastcontrol: 'Khống chế phát thanh',
		Stopbroadcasting: 'Dừng phát thanh',
		Broadcastcontent: 'Nội dung phát thanh',
		Oldpassword: 'Mật khẩu cũ',
		NewPassword: 'Mật khẩu mới',
	account2: 'Tài khoản',
		smsservice: 'Tin nhắn dịch vụ',
		Reset: 'Cài đặt lại',
		info: 'Ban hành thông tin',
		mediaassets: 'Quản lý',
		picture: 'Hình ảnh',
		video: 'Video',
		music: 'Âm nhạc',
		smartscreenname: 'Màn hình thông minh',
		smartscreen: 'Quản lý màn hình thông minh',
		ljqy: 'Vùng logic',
		equipmentdetail: 'Chi tiết thiết bị',
		screenrelease: 'Ban hành lên màn hình chính',
		realtimerelease: 'Phát thanh ngay',
		timedrelease: 'Hẹn giờ phát thanh',
		no: 'NO',
		releasehistory: 'Nhật ký phát',
		zdai: 'Nhiệm vụ AI của thiết bị đầu cuối',
		Pleaseinputapassword: 'Xin vui lòng nhập mật khẩu',
		Pleaseentertheverificationcode: 'vui lòng nhập mã xác nhận',
		ServerAddress: 'Địa chỉ máy chủ',
		smdl: 'Quét mã để đăng nhập',
		zcjbsty: 'Đăng ký và đồng ý',
		Theserviceagreement: 'Thỏa thuận dịch vụ',
		Privacypolicy: 'Chính sách bảo mật',
		Legalnotices: 'Thông báo pháp lý',
		Clientserviceprotocol: 'Thỏa thuận dịch vụ khách hàng',
		ji: 'Và',
		gxxy: 'Vui lòng chọn đã đọc và đồng ý với thỏa thuận trên',
		azapph: 'Sau khi cài đặt APP di động, hãy sử dụng APP để quét mã QR để lấy địa chỉ máy chủ.',
		syllq: 'Vui lòng sử dụng trình duyệt di động để quét mã để tải xuống ứng dụng khách di động.',
		Downloadthepicture: 'Tải hình ảnh',
		zwkf: 'Tạm thời chưa mở tính năng này',
		bnsykg: 'Không cho phép để trống',
		qsrmm: 'Vui lòng nhập mật khẩu 8-20 chữ số',
		qsryzh: 'Vui lòng nhập tên tài khoản bạn cần lấy lại mật khẩu đăng nhập',
		Existingaccount: 'Tài khoản đã tồn tại',
		Loginimmediately: 'Đăng nhập ngay ',
		hqyzm: 'Lấy mã xác minh',
		yzdshj: 'Số điện thoại di động xác minh',
		Resend: 'Gửi lại',
		Pleaseenteranewpassword: 'Vui lòng nhập mật khẩu mới',
		qsrszzm: 'Vui lòng nhập số và chữ cái',
		Aboutus: 'Liên hệ chúng tôi',
		qsrzcxm: 'Vui lòng nhập tên đăng ký của bạn',
		aitask: 'Nhiệm vụ AI của thiết bị ',
		createGroup: 'Tạo nhóm',
		广播用户权限: 'Quyền của người dùng ',
		超级管理员权限: 'Quyền của quản trị viên cấp cap',
		运维: 'Vận hành và bảo trì',
		超管: 'Siêu quản lý',
		系统维护人员: 'Nhân viên bảo trì hệ thống',
		播音员: 'Phát thanh viên',
		广播用户权限: 'Quyền của người dùng',
		aiWarning: 'Cảnh báo sớm AI',
		aiDevice: 'Thiết bị giám sát',
		screenView: 'Chia nhiều màn hình',
		aiMap: 'Bản đồ',
		aiAlarm: 'Truy vấn cảnh báo',
		Adddevice: 'Thêm thiết bị',
		EquipmentNo: 'ID thiết bị',
		channel: 'Kênh',
		aiLinkage: 'Cấu hình liên kết phát sóng',
		operatorManage: 'Quản lý nhà cung cấp dịch vụ',
		customManage: 'Quản lý khách hàng',
		receivingManage: 'Quản lý thiết bị nhận',
		collectingManage: 'Quản lý thiết bị thu thập',
		talkingManage: 'Quản lý thiết bị đối thoại',
		smartScreenManage: 'Quản lý màn hình thông minh',
		homeContent: 'Tài liệu tuyên truyền',
		timingLive: 'Phát thanh theo lịch ',
		Preview: 'Xem trước',
		create: 'Tạo mới',
		helpTitle: 'Tài liệu trợ giúp',
		appSlideTitle: 'Tài liệu APP',
		title: 'Tiêu đề',
		author: 'Tác giả',
		release: 'Công bố',
		cover: 'Niêm phong',
		abstract: 'Bản tóm tắt',
		fams: 'Mô tả giải pháp',
		modify: 'Chỉnh sửa',
		scheme: 'Kế hoạch',
		jrsd: 'Thiết lập ngày lễ',
		holidayName: 'Tên ngày lễ',
		rwlb: 'Danh sách nhiệm vụ',
		broadcastlinkage: 'Cấu hình liên kết phát sóng',
		fullscreen: 'Toàn màn hình',
		mobileterminal: 'Đầu di động',
		Listeningbackup: 'Sao lưu ',
		yjgb: 'Phát thanh khẩn cấp',
		wjtsgb: 'Tập tin đưa lên phát thanh',
		dsbfgb: 'Hẹn giờ phát thanh',
		gisdtgb: 'Phát thanh bản đồ GIS',
		tjyabj: 'Thêm cảnh báo dự phòng',
		yjbj: 'Báo động khẩn cấp',
		cybj: 'Báo động chung',
		zdybj: 'Tùy chọn báo động',
		qxzyjbj: 'Vui lòng chọn báo động khẩn cấp',
		qxztb: 'Vui lòng chọn một biểu tượng',
		Icon: 'Biểu tượng',
		zdytb: 'Tùy chọn biểu tượng',
		xzwj: 'Chọn file',
		yajc: 'Tên viết tắt của kế hoạch',
		bdyjya: 'Ràng buộc kế hoạch khẩn cấp',
		qsryajc: 'Vui lòng nhập tên kế hoạch',
		qxzyjya: 'Vui lòng chọn kế hoạch khẩn cấp',
		qsrgjz: 'Vui lòng nhập từ khóa',
		tpcj: 'Cắt ảnh',
		amplify: 'Phóng to',
		reduce: 'Thu nhỏ',
		leftrotate: 'Xoay trái',
		rightrotate: 'Xoay phải',
		jingjie: 'Cảnh giới',
		fangdao: 'Chống trộm',
		hongzai: 'Bão lũ',
		gongong: 'Sức khỏe cộng đồng',
		tongyong: 'Biểu tượng chung',
		yjyglb: 'Danh sách kế hoạch khẩn cấp',
		huozai: 'Hỏa hoạn',
		dizhen: 'Động đất',
		shusan: 'Sơ tán',
		fangkong: 'Phòng không',
		kongxi: 'Tấn công bằng phi cơ',
		dizhenyanlian: 'Diễn tập động đất',
		longjuanfeng: 'Lốc xoáy',
		jiechu: 'Gỡ báo động',
		help: 'Hỗ trợ',
		rmwl: 'Vấn đề nóng',
		linqi: 'Sắp hết hạn/Hết hạn',
		scyabj: 'Xóa báo cảnh báo',
		qdscya: 'Bạn có chắc chắn muốn xóa cảnh báo này không?',
		zdzxs: 'Số lượng thiết bị đầu cuối đang online',
		zdzs: 'Tổng số thiết bị đầu cuối',
		qdqdya: 'Bạn có chắc chắn muốn kích hoạt kế hoạch báo động này không?',
		xtts: 'Hệ thống nhắc nhở',
		gbcs: 'Số lần phát thanh',
		zwsj: 'Không có dữ liệu',
		tpgsyq: 'Hình ảnh tải lên chỉ có thể ở định dạng JPG hoặc PNG',
		loop: 'Lặp lại',
		qsrnr: 'Vui lòng nhập nội dung',
		qbzd: 'Tất cả các thiết bị đầu cuối',
		qxzbcyp: 'Vui lòng chọn âm thanh để phát',
		qgxbcmb: 'Vui lòng tích chọn mục tiêu phát',
		qiege: 'Cắt bài hát',
		shangyiqu: 'Trước',
		xiayiqu: 'Sau',
		zanting: 'Tạm ngừng',
		qsrrwmc: 'Vui lòng nhập tên nhiệm vụ',
		qsrxhcs: 'Vui lòng nhập số lần lặp lại',
		qsryxj: 'Vui lòng nhập cấp độ ưu tiên',
		qsryl: 'Vui lòng nhập âm lượng',
		qxzbcrq: 'Vui lòng chọn ngày phát sóng',
		qgxbczq: 'Vui lòng chọn thời gian phát thanh',
		qsrldz: 'Vui lòng nhập địa chỉ luồng',
		qsrwz: 'Vui lòng nhập văn bản',
		qxzcjsb: 'Vui lòng chọn thiết bị thu thập',
		qxzyytd: 'Vui lòng chọn đường dẫn âm thanh',
		szyd: 'Số càng cao thì ưu tiên phát trước',
		yxgq: 'Đã chọn bài hát',
		gbmbqbzd: 'Phát thanh đến tất cả các thiết bị',
		fzxdzdlb: 'Danh sách thiết bị theo nhóm',
		yytd: 'Đưỡng dẫn',
		upan: 'Ổ U',
		none: 'Không có',
		ydjc: 'Phát hiện chuyển động',
		rqqy: 'Khu vực xâm lược',
		rxjc: 'Phát hiện hình người',
		tljc: 'Phát hiện dừng',
		zdtb: 'Tự động dừng phát thanh',
		sdtb: 'Dừng phát thanh thủ công',
		qsrmc: 'Vui lòng nhập tên',
		sxtj: 'Điều kiện hiệu quả',
		ryzt: 'Trạng thái tùy ý',
		zxzt: 'Trạng thái online',
		cxsc: 'Kéo dài thời gian',
		xgssfz: 'Chỉnh sửa nhóm sở thuộc',
		qxzbcsj: 'Vui lòng chọn thời gian phát thanh',
		jssjbnxykssj: 'Thời gian kết thúc không được nhỏ hơn thời gian bắt đầu',
		bcscbnxyypsc: 'Thời lượng phát không được nhỏ hơn thời lượng file âm thanh',
		qdgbgb: 'Bạn có chắc chắn muốn tắt phát thanh không?',
		canceled: 'Đã hủy',
		zdwjsl: 'Chỉ có thể chọn tối đa 50 tệp âm thanh',
		sbcj: 'Thu thập thiết bị',
		Hour: 'Giờ',
		rwxq: 'Chi tiết nhiệm vụ',
		gblb: 'Danh sách phát',
		bcz: 'Đang lưu',
		plsc: 'Xóa hàng loạt',
		qdsczxrwm: 'Bạn có chắc chắn muốn xóa những tác vụ này không?',
		qxzyscdrw: 'Hãy chọn nhiệm vụ cần xóa',
		qdyscgbm: 'Bạn có chắc chắn muốn xóa chương trình phát thanh này không?',
		qsrfamc: 'Vui lòng nhập tên kế hoạch',
		qxxzfa: 'Vui lòng chọn kế hoạch',
		scfahscrw: 'Khi xóa một kế hoạch, tất cả các nhiệm vụ trong kế hoạch đó cũng sẽ bị xóa. Bạn có chắc chắn muốn xóa không?',
		sxsj: 'Thời gian hiệu quả',
		qdyscrwm: 'Bạn có chắc chắn muốn xóa nhiệm vụ này không?',
		qdyqdgbm: 'Bạn có chắc chắn muốn bắt đầu phát thanh không?',
		bcmb: 'Mục tiêu phát thanh',
		qidong: 'Khởi động',
		sftcdl: 'Có phải bạn muốn đăng xuất không?',
		xxbcz: 'Tin nhắn không tồn tại',
		ptjb: 'Cấp độ thường',
		aqjb: 'Cấp độ bảo mật',
		mtwjzctips: 'Chỉ hỗ trợ định dạng mp3/wav, kích thước của một tệp tải lên không được vượt quá {a} MB, vui lòng không tải lên nội dung bất hợp pháp, không lành mạnh, nếu không bạn sẽ phải chịu trách nhiệm pháp lý trước Pháp luật!',
		mtwjzctips: 'Chỉ hỗ trợ định dạng mp3/wav, vui lòng không tải lên nội dung bất hợp pháp, không lành mạnh, nếu không bạn sẽ phải chịu trách nhiệm pháp lý trước Pháp luật!',
		qxmtlb: 'Vui lòng chọn loại phương tiện',
		qxzmtjb: 'Vui lòng chọn cấp độ phương tiện',
		qscypwj: 'Vui lòng tải lên tập tin âm thanh',
		share: 'Đã chia sẻ',
		scypz: 'Đang tải âm thanh lên',
		xiaofeng: 'Giọng nam',
		xiaoyan: 'Giọng nữ',
		mypzcos: 'Không có cấu hình thông tin cos của Tencent',
		qxzyscdmtwj: 'Vui lòng chọn tập tin media muốn xóa',
		qdysczxmtwjm: 'Bạn có chắc chắn muốn xóa các tập tin media này không?',
		qdycmtwj: 'Xác nhận xóa',
		scdypwjgs: 'Định dạng tệp âm thanh đã tải lên chỉ có thể ở định dạng mp3/wav',
		cfscwj: 'File đã tồn tại, vui lòng không tải lên trùng lặp',
		scddgwjdx: 'Mỗi tệp âm thanh được tải lên không được vượt quá {a}MB.',
		qdscmtwj: 'Bạn có chắc chắn muốn xóa {a} không?',
		aqez: 'Lưu ý: Vui lòng không tải lên nội dung bất hợp pháp, không lành mạnh, nếu không bạn sẽ phải chịu trách nhiệm pháp lý trước Pháp luật.',
		qsrwbmc: 'Vui lòng nhập tên văn bản',
		qxzyscdwj: 'Vui lòng chọn tập tin để xóa',
		qdysczxwjm: 'Bạn có chắc chắn muốn xóa những tập tin này không?',
		nqdyscwbwjm: 'Bạn có chắc chắn muốn xóa tệp văn bản không?',
		qxzyscdmtlb: 'Vui lòng chọn danh mục muốn xóa',
		xtmrlbbyxsc: 'Không được phép xóa danh mục mặc định của hệ thống',
		qrscmtlb: 'Bạn có chắc chắn muốn xóa danh mục  {a} này không?',
		qxxzmtlbzjxxg: 'Vui lòng chọn loại phương tiện trước sau đó mới tiến hành chỉnh sửa',
		xtmrlbbyxxg: 'Không được phép chỉnh sửa danh mục mặc định của hệ thống',
		lbmcbt: 'Phải điền tên danh mục',
		sftgsh: 'Phải chăng đã thông qua kiểm duyệt?',
		sfbtgsh: 'Phải chăng không thông qua kiểm duyệt?',
		mgchh: 'Lưu ý: Mỗi dòng một từ, dùng phím Enter để xuống dòng',
		qsrmgc: 'Vui lòng nhập từ nhạy cảm',
		nqdyscmgcm: 'Bạn có chắc chắn muốn xóa từ nhạy cảm {a} không?',
		hjjg: 'Kết quả cuộc gọi',
		hjcg: 'Cuộc gọi thành công',
		hjsb: 'Cuộc gọi thất bại',
		hjflx: 'Loại hình của bên đang gọi',
		hjf: 'Bên gọi',
		bhjflx: 'Loại hình của bên được gọi',
		bhjf: 'Bên được gọi',
		hjsj: 'Thời gian gọi',
		thsc: 'Thời lượng cuộc gọi',
		hjcs: 'Cuộc gọi quá thời gian',
		dfjj: 'Đối phương từ chối',
		dfzm: 'Đối phương đang bận',
		qxhj: 'Hủy cuộc gọi',
		wjt: 'Chưa kết nối',
		xfxhy: 'Nguồn tín hiệu chữa cháy',
		xfxhysz: 'Cài đặt nguồn tín hiệu chữa cháy',
		SignalSourceDescription: 'Mô tả nguồn tín hiệu',
		SignalSourceEquipment: 'Thiết bị nguồn tín hiệu',
		SignalNumber: 'Số tín hiệu',
		PleaseSelectAChannel: 'Vui lòng chọn kênh',
		unexpired: 'Chưa hết hạn',
		Expired: 'Hết hạn',
		Advent: 'Sắp hết hạn',
		periodOfValidity: 'Có hiệu lực',
		Map: 'Bản đồ',
		DefaultVolume: 'Âm lượng mặc định',
		BroadcastVolume: 'Âm lượng phát',
		InstallationPosition: 'Vị trí lắp đặt',
		IntercomVolume: 'Âm lượng đối thoại',
		TaskPriority: 'Nhiệm vụ cấp ưu tiên',
		FrequencySetting: 'Cài đặt tần số',
		Frequency: 'Tần số',
		GSignal: 'Tín hiệu 4G',
		qsrssgjc: 'Nhập từ khóa tìm kiếm',
		qsrwldz: 'Vui lòng nhập địa chỉ vật lý',
		qsrzdmc: 'Vui lòng nhập tên thiết bị đầu cuối',
		qxztdbh: 'Vui lòng chọn số kênh',
		qxzzt: 'Vui lòng chọn trạng thái',
		qsrdjyl: 'Vui lòng nhập âm lượng đối thoại',
		qxzrwyxj: 'Vui lòng chọn mức độ ưu tiên của nhiệm vụ',
		UploadSuccessful: 'Tải lên thành công',
		ReuploadImages: 'Click để tải lại ảnh',
		GoBack: 'Quay lại trước đó',
		OptionalReceivingTerminal: 'Tùy chọn thiết bị đầu cuối thu nhận',
		OptionalReceivingDevice: 'Tùy chọn thiết bị thu nhận',
		SelectedReceivingTerminal: 'Đã chọn thiết bị đầu cuối thu nhận',
		SelectedReceivingDevice: 'Đã chọn thiết bị thu nhận',
		qsrfzmc: 'Vui lòng nhập tên nhóm',
		qsrfzbh: 'Vui lòng nhập số nhóm',
		PleaseSelectTheReceivingTerminal: 'Vui lòng chọn thiết bị đầu cuối thu nhận',
		PleaseSelectTheReceivingDevice: 'Vui lòng chọn thiết bị thu nhận',
		nqdyscfzm: 'Bạn có chắc chắn muốn xóa nhóm {a} không?',
		SDcardCapacity: 'Dung lượng thẻ SD',
		SDcardStatus: 'Trạng thái thẻ SD',
		NetworkMode: 'Chế độ mạng',
		Normal: 'Bình thường',
		Bbnormal: 'Bất thường',
		Unrecognized: 'Chưa được nhận dạng',
		FormatSDCard: 'Định dạng thẻ SD',
		Record: 'Ghi',
		Wired: 'Có dây',
		yunyingshang: 'Nhà điều hành',
		UsedTraffic: 'Dữ liệu được sử dụng',
		DeviceLog: 'Nhật ký thiết bị',
		GetLog: 'Thu nhận nhật ký',
		GSignalRecord: 'Nhật ký tín hiệu 4G',
		SignalValue: 'Giá trị tín hiệu',
		FormatSDcardConfirmation: 'Nếu bạn format thẻ SD sẽ mất hết tất cả các dữ liệu trong thẻ SD. Bạn có chắc chắn muốn format thẻ SD không?',
		sending: 'Gửi',
		RetransmissionCycle: 'Chu kỳ luân chuyển',
		Instruct: 'Chỉ dẫn',
		qsrhczq: 'Vui lòng nhập chu kỳ luân chuyển',
		qsrsz: 'Vui lòng nhập số',
		qxzzl: 'Vui lòng chọn một lệnh',
		jiaoshi: 'Giờ học',
		tongbucanshu: 'Đồng bộ tham số',
		jixubofang: 'Tiếp tục phát',
		chongqi: 'Khởi động lại',
		qingchu: 'Xóa trạng thái báo cháy',
		nqdysczdm: 'Bạn có chắc chắn muốn xóa thiết bị đầu cuối {a} không?',
		qxzyscdzd: 'Vui lòng chọn thiết bị đầu cuối để xóa',
		qdtsczxzdm: 'Bạn có chắc chắn muốn xóa các thiết bị đầu cuối này không?',
		Online: 'Online',
		Setting: 'Cài đặt',
		ServiceExtranetAddress: 'Địa chỉ mạng bên ngoài của dịch vụ',
		ServiceIntranetAddress: 'Địa chỉ mạng nội bộ của dịch vụ',
		Port: 'Cổng port',
		tingji: 'Tắt máy',
		fuji: 'Khởi động lại',
		qiangzhi: 'Buộc phải hồi sinh',
		jingtai: 'Trạng thái tĩnh',
		simCardStatus: 'Trạng thái thẻ SIM',
		simCardControl: 'Kiểm soát thẻ SIM',
		IPAddress: 'địa chỉ IP',
		ziwangyanma: 'Mạng phụ',
		wangguan: 'Cổng mạng',
		MACAddress: 'Địa chỉ MAC',
		qxzfwqx: 'Vui lòng chọn thời gian sử dụng dịch vụ',
		qsrfwwwdz: 'Vui lòng nhập địa chỉ mạng ngoài dịch vụ',
		qsrdk: 'Vui lòng nhập số cổng port',
		qsrfwnwdz: 'Vui lòng nhập địa chỉ mạng nội bộ của dịch vụ',
		qxzwlms: 'Vui lòng chọn chế độ mạng',
		qxzkzzl: 'Vui lòng chọn một lệnh điều khiển',
		qxzysydh: 'Vui lòng chọn các hàng bạn muốn di chuyển lên',
		qxzyxydh: 'Vui lòng chọn hàng bạn muốn di chuyển xuống',
		dytbnbsy: 'Hàng đầu tiên không thể di chuyển lên',
		zhytbnbxy: 'Hàng cuối cùng không thể di chuyển xuống',
		nqdyscghjdxm: 'Bạn có chắc chắn muốn xóa đối tượng cuộc gọi này không?',
		nqdytjwhjdxm: 'Bạn có chắc chắn muốn thêm nó làm đối tượng cuộc gọi không?',
		djyx: 'Ưu tiên đối thoại',
		gbyx: 'Ưu tiên phát thanh',
		qdgbdgb: 'Bạn có chắc chắn muốn tắt chương trình phát thanh của {a} không?',
		ImportDevice: 'Nhập thiết bị',
		plsx: 'Kiểm duyệt hàng loạt',
		plck: 'Xuất kho theo lô ',
		ActiveState: 'Trạng thái kích hoạt',
		Active: 'Đã kích hoạt',
		NotActive: 'Chưa kích hoạt',
		nqdyscgsbm: 'Bạn xác nhận muốn xóa thiết bị này không?',
		fwzt: 'Trạng thái phục vụ',
		yyyh: 'Người vận hành',
		qsrybddyyyh: 'Vui lòng nhập người vận hành muốn được ràng buộc',
		qsrsbmc: 'Vui lòng nhập tên thiết bị',
		qxzyscdsb: 'Vui lòng chọn thiết bạn muốn xóa',
		qdysczxsbm: 'Bạn có chắc chắn muốn xóa các thiết bị này không?',
		qxzyshdsb: 'Vui lòng chọn thiết bị cần kiểm duyệt',
		scdwjstxt: 'Các tệp đã tải lên chỉ có thể ở định dạng TXT',
		scdwjdx: 'Tệp đã tải lên không được lớn hơn 1MB',
		qianjin: 'Tiến',
		houtui: 'Lùi',
		mygdsjl: 'Không còn nhiều dữ liệu rồi',
		EquipmentOnlineRate: 'Tỷ lệ online của thiết bị',
		zdfb: 'Phân bố thiết bị',
		zdgbzt: 'Trạng thái phát sóng của thiết bị ',
		Unit: 'Đơn vị',
		qxzdgb: 'Khoanh chọn thiết bị phát thanh',
		yjhh: 'Phím gọi nhanh',
		qsrmccx: 'Vui lòng nhập tên để truy vấn',
		TerminalView: 'Truy vấn thiết bị',
		Rectangle: 'Hình chữ nhật',
		Circle: 'Hình tròn',
		polygon: 'Hình đa giác',
		zzgb: 'Đang phát thanh',
		Satellite: 'Vệ tinh',
		zzhhgb: 'Đang phát thanh',
		qxzgbzd: 'Vui lòng chọn thiết bị ',
		zcsr: 'Nhập lại',
		jymmts: 'Mật khẩu mạnh có thể giúp tài khoản của bạn an toàn hơn. Bạn nên thay đổi mật khẩu thường xuyên, mật khẩu phải có từ 8-20 chữ số, chữ cái hoặc ký hiệu đặc biệt.',
		bdyxts: 'Ràng buộc E-mail có thể được sử dụng để lấy lại mật khẩu đăng nhập',
		bdsjts: 'Ràng buộc điện thoại di động có thể được sử dụng để lấy lại mật khẩu đăng nhập',
		djtpkgh: 'Click vào hình ảnh để thay đổi',
		ddxq: 'Chi tiết đặt hàng',
		fukuanren: 'Người trả tiền',
		jine: 'Tổng tiền',
		qsryx: 'Vui lòng nhập email của bạn',
		qsrzqdyxdz: 'Vui lòng nhập đúng địa chỉ email',
		Tall: 'Cao',
		Centre: 'Trung',
		Low: 'Thấp',
		lcsrdmmbyz: 'Mật khẩu nhập hai lần không đồng nhất',
		qsrzqdsjh: 'Vui lòng nhập đúng số điện thoại',
		qxzxb: 'Vui lòng chọn giới tính',
		qxzqy: 'Vui lòng chọn khu vực',
		nqdyzxczhm: 'Bạn có chắc chắn muốn hủy bỏ tài khoản này không?',
		zxzh: 'Hủy bỏ tài khoản',
		qgxjs: 'Vui lòng tích chọn vai trò',
		wenxintishi: 'Nhắc nhở',
		cjfzdwxts: 'Người tạo nhóm người dùng là người quản lý nhóm người dùng. Sau khi tham gia nhóm người dùng, thiết bị của bạn sẽ được chia sẻ với những người dùng khác trong nhóm.',
		cjfzdwxts2: 'Mỗi tài khoản người dùng chỉ có thể tạo một nhóm người dùng và mỗi người dùng chỉ có thể tham gia một nhóm người dùng.',
		qdycyhm: 'Bạn có chắc chắn muốn xóa {a} không?',
		yyyhtjsb: '{a}Người dùng này đã có nhóm người dùng rồi, thêm vào không thành công.',
		byxcftj: 'Không được phép thêm vào trùng lặp',
		byxycyhcyzs: 'Không cho phép tự mình loại bỏ thành viên người dùng',
		qsryhzmc: 'Vui lòng nhập tên nhóm người dùng',
		qsrhzzmsz: 'Vui lòng nhập ký tự, chữ cái hoặc số',
		yhzcybnwk: 'Thành viên nhóm người dùng không được để trống, vui lòng thêm thành viên nhóm người dùng',
		nqdyscyhzm: 'Bạn có chắc chắn muốn xóa nhóm người dùng này không?',
		nqdytcyhzm: 'Bạn có chắc chắn muốn rời khỏi nhóm người dùng này?',
		xzxjqy: 'Khu vực cấp dưới mới thêm vào',
		sjqybm: 'Mã khu vực cấp cao',
		sjqymc: 'Tên khu vực cấp cao',
		xzqybm: 'Mã khu vực mới thêm vào',
		xzqymc: 'Tên khu vực mới thêm vào',
		xzqyqc: 'Tên đầy đủ của khu vực mới thêm vào',
		qsrgdsz: 'Vui lòng nhập chữ số ${a}',
		qsrqybm: 'Vui lòng nhập mã khu vực',
		qsrqymc: 'Vui lòng nhập tên khu vực',
		jdsryw: 'Nhập sai kinh độ',
		wdsryw: 'Nhập sai vĩ độ',
		tjxgqy: 'Thêm/Chỉnh sửa',
		nqdyscqym: 'Bạn có chắc chắn muốn xóa khu vực này không?',
		scqy: 'Xóa khu vực',
		qsrjsmc: 'Vui lòng nhập tên vai trò',
		qxzjsqx: 'Vui lòng chọn quyền vai trò',
		qsrms: 'Vui lòng nhập mô tả',
		nqdyscjsm: 'Bạn có chắc chắn muốn xóa vai trò này không?',
		scjs: 'Xóa vai trò',
		nqdyscgjm: 'Bạn có chắc chắn muốn xóa phần mềm này không?',
		qxscgj: 'Vui lòng tải phần mềm lên trước',
		yylx: 'Loại ứng dụng',
		yycxsc: 'Tải lên ứng dụng',
		yylxpz: 'Cấu hình loại ứng dụng',
		gxnr: 'Cập nhật nội dung',
		scz: 'Đang tải lên',
		sfqzsj: 'Có bắt buộc nâng cấp không?',
		yycxwj: 'Tập tin chương trình ứng dụng',
		tzwj: 'Kéo tệp hoặc nhấp vào hình ảnh để thêm vào tệp và bắt đầu tải lên',
		yylxmc: 'Tên loại ứng dụng',
		lxgjz: 'Gõ từ khóa',
		qsrlxgjz: 'Vui lòng nhập loại từ khóa',
		qsryylxmc: 'Vui lòng nhập tên loại ứng dụng',
		qxzyylx: 'Vui lòng chọn loại ứng dụng',
		qxzscwj: 'Vui lòng chọn một tập tin để tải lên',
		scwjmcbgf: 'Tên file tải lên chưa đúng quy chuẩn',
		nqdyscyym: 'Bạn có chắc chắn muốn xóa ứng dụng này không?',
		nqdyscgyylxm: 'Bạn có chắc chắn muốn xóa loại ứng dụng này không?',
		xjwa: 'Tạo hồ sơ mới',
		nqdysccwam: 'Bạn có chắc chắn muốn xóa hồ sơ này không?',
		tjcc: 'Kích thước đề xuất',
		qsrbt: 'Vui lòng nhập tiêu đề',
		qsrwanr: 'Vui lòng nhập nội dung hồ sơ',
		qscfmt: 'Vui lòng tải lên ảnh bìa',
		sucaiku: 'Kho tài kiệu',
		bdsc: 'Tải lên ',
		laiyuan: 'Nguồn dẫn',
		wllj: 'Kết nối Internet',
		Upload: 'Tải lên',
		sctctips: 'Chỉ có thể tải lên các tệp .jpg, .png, .mp3, .mp4, .flv và không được vượt quá 20m',
		Tag: 'Nhãn',
		crsc: 'Chèn vào tài liệu',
		fabuyu: 'Đăng trên',
		wzx: 'Chưa thực hiện',
		yzx: 'Đã thực hiện',
		qxzx: 'Hủy ',
		qxzgjbb: 'Vui lòng chọn phiên bản phần mềm',
		qgxsb: 'Vui lòng tích chọn thiết bị',
		nqdyqxzxrwm: 'Bạn có chắc chắn muốn hủy tác vụ nâng cấp không?',
		ptxx: 'Thông tin nền tảng',
		dbzxj: 'Góc dưới bên trái',
		dbjz: 'Gần giữa góc dưới',
		dbyxj: 'Góc dưới bên phải',
		dibjz: 'Hàng đầu',
		xswz: 'Hiển thị vị trí',
		qxzxswz: 'Vui lòng chọn vị trí hiển thị',
		xxfb: 'Thông tin đăng',
		MessageTitle: 'Tiêu đề',
		MessageContent: 'Nội dung ',
		MessageObject: 'Đối tượng ',
		fbxx: 'Ban hành thông tin',
		fbTime: 'Thời gian ban hành',
		Object: 'Đối tượng ',
		qgxxdx: 'Vui lòng chọn đối tượng nhắn tin',
		nqdyscxxm: 'Bạn có chắc chắn muốn xóa tin nhắn?',
		tjlmtfw: 'Thêm dịch vụ ',
		xglmtfw: 'Sửa đổi dịch vụ',
		文件服务器: 'Máy chủ tập tin',
		turn服务器: 'Máy chủ Turn',
		kms服务器: 'Máy chủ kms',
		业务服务器: 'Máy chủ nghiệp vụ',
		信令服务器: 'Máy chủ báo hiệu',
		mtfwlx: 'Loại dịch vụ ',
		sfqy: 'Bạn có muốn khởi động không?',
		mtfwmc: 'Tên dịch vụ',
		mtfwdz: 'Địa chỉ dịch vụ ',
		qsrmtfwmc: 'Vui lòng nhập tên dịch vụ',
		qsrmtfwdz: 'Vui lòng nhập địa chỉ dịch vụ ',
		fwqcc: 'Máy chủ lưu trữ',
		coscc: 'Lưu trữ đối tượng đám mây ',
		qsrcctmc: 'Vui lòng nhập tên thùng lưu trữ',
		qsrcctqy: 'Vui lòng nhập khu vực thùng lưu trữ',
		qsrid: 'Vui lòng nhập id',
		qsrmy: 'Vui lòng nhập mật khẩu khóa',
		dymc: 'Tên lãnh thổ',
		dxyyid: 'ID ứng dụng SMS',
		dxqm: 'Chữ ký SMS',
		zhmmmbid: 'Mẫu ID lấy lại mật khẩu',
		khzh: 'Tài khoản khách hàng',
		bdkh: 'Ràng buộc khách hàng',
		jcbd: 'Hủy bỏ ràng buộc',
		xgzhxx: 'Sửa đổi thông tin tài khoản',
		nqdyjcbdczhm: 'Bạn có chắc chắn muốn hủy bỏ ràng buộc tài khoản này không?',
	Back: 'Quay lại',
	Play: 'Chơi',
	jypfxgyhzncy: '将音频分享给用户组内成员',
	jsbfxgyhzncy: '将设备分享给用户组内成员',
	AddTask: '添加任务',
	EditScheme: '编辑方案',
	dlsx: '登录失效',
	xjxcxcz: '先进行查询操作',
	ExportDevice: '导出设备',
	Agree: '同意',
	Refuse: '拒绝',
	InviteToJoin: '邀请加入',
	InvitedPleaseWait: '已邀请，等待对方同意',
	PlayedTask: '已播任务',
	PlayAgain: '再播一次',
	SureToPlayAgain: '确定要再播一次吗',
	NumberOfTaskStarts: '任务启动次数',
	startInterval: '启动间隔',
	minute: '分钟',
	BadRequest: '错误请求',
	LoginAgain: '未授权，请重新登录',
	NoAccess: '拒绝访问',
	NotFound: '请求错误,未找到该资源',
	RequestMethodNotAllowed: '请求方法未允许',
	RequestTimeout: '请求超时',
	ServerSideError: '服务器端出错',
	NetworkNotImplemented: '网络未实现',
	NetworkError: '网络错误',
	ServiceUnavailable: '服务不可用',
	NetworkTimeout: '网络超时',
	DoesNotSupport: 'http版本不支持该请求',
	ConnectionError: '连接错误',
	RequestCanceled: '请求已取消',
	ConnectionFailed: '连接到服务器失败',
	Loading: '加载中',
	LoginFirst: '请先登录',
	DefaultLanguage: '默认语言',
	LoggingOut: '退出登录中',
	SelectDate: '选择日期',
	PleaseSelectDate: '请选择日期',
	qiyonghou: '启用后，定时任务在节日日期不启动',
	PleaseEnterHolidayName: '请输入节日名称',
	AreYouSureToDeleteHoliday: '确定要删除该节日吗',
	AdvancedSetting: '高级设置',
	lianxiguanliyuan: '您没有当前操作的权限,请联系管理员',
	APPDownload: '安卓APP下载',
	Shares: '分享',
	MenuLoading: '菜单加载中',
	CurrentVersion: '当前版本',
	ServiceMonitoring: '服务监控',
	ServerMonitor: '服务器监控',
	ServeName: '服务名称',
	RunningState: '运行状态',
	NormalOperation: '运行正常',
	cpuper: 'CPU使用率',
	memoryper: '内存使用率',
	diskper: '磁盘使用率',
	bandwidthper: '宽带使用',
	Usage: '使用',
	OutputBandwidth: '出带宽',
	IncomingBandwidth: '入带宽',
	DisplayInterval: '显示间隔',
	DeleteCopy: '删除文案',
	audioRecord: '音频录制',
	RecordingAudio: '录制音频',
	SoundRecording: '录音',
	Microphone: '麦克风',
	StartRecording: '开始录制',
	StopRecording: '停止录制',
	Recording: '正在录制',
	RecordUpload: '录制上传',
	ReRecord: '重新录制',
	ConfirmUpload: '确认上传',
	NotSupported: '暂不支持',
	NotSupportedForDisplayingContent: '暂不支持显示内容',
	RealTime: '实时',
	Nearly24hours: '近24小时',
	InThePastThreeDays: '近三天',
	Everyday: '每天',
	Mondays: '每周一',
	FirstFayOfEachMonth: '每月一日',
	BackupTime: '备份时间',
	BackedUpTimes: '已备份次数',
	BackupObject: '备份对象',
	Timing: '选择时间',
	Database: '数据库',
	PleaseSelectTime: '请选择时间',
	CreateScheduledBbackupTask: '创建定时备份任务',
	BackupNow: '立即备份',
	PleaseSelectBackupObject: '请选择备份对象',
	AreYouSureToDeleteTheBackupTask: '你确定要删除备份任务吗',
	BackupFiles: '备份文件',
	Size: '大小',
	AreYouSureToDeleteTheBackupFiles: '你确定要删除备份文件吗',
	RecordNeedHttps: '录制功能需要以https方式访问',
	Export: '导出',
	timedCalibration: '全网定时校时',
	Horn: '喇叭',
	LoadExternalNetworkResources: '是否加载外网资源',
	HTTPSAccessAddress: 'https访问地址',
	ConstantVoltageSwitching: '定压切换',
	ReplicationTask: '复制任务',
	SchemeBelongs: '所属方案',
	OpenRegistration: '开放注册',
	ssylbfsc: '实时预览播放时长',
	SavePreview: '保存预览记录',
	bcscdyypsc: '建议播出时长大于音频时长',
	RefreshDeviceStatus: '刷新设备状态',
	BroadcastNeedHttps: '喊话广播功能',
	NeedHttps: '需要以https方式访问',
	cjqgb: '采集器广播',
	transcodeStatus: '转码状态',
	untranscode: '未转码',
	transcoded: '转码完成',
	transcodefail: '转码失败',
	transcodewait: '等待转码',
	transcodeing: '正在转码',
	UserAgreement: 'Thỏa thuận người dùng',
	TriggerChannel: 'Kênh kích hoạt',
	TaskType: '任务类型',
	HongWaiChuFa: '红外触发',
	XiaoFangChuFa: '消防触发',
	Terminal: "终端",
	SmartHost: '智能主机',
	PowerSupplyStatus: '供电状态',
	NormalMainsPowerSupply: '市电正常',
	UtilityFailure: '市电停电',
	PowerAmplifierPartitionNotes: '功放分区备注',
	NoteName: '备注名称',
	Liaison: '联系人',
	Telephone: '联系电话',
	PowerAmplifierOn: '开启功放电源',
	PowerAmplifierOff: '关闭功放电源',
	TurnOnTheAmplifierInAdvance: '提前开启功放',
	PowerAmplifierPartition: '功放分区',
	SelectPowerAmplifierPartition: '请选择功放分区',
	AnjianRenwu: '音乐广播转终端按键任务',
	QuanQuKaiQi: '功放全区开启',
	QiJinYongSheBei: '启/禁用设备',
	TriggerMode: '触发模式',
	ImportTip1: '拖到文件到此处，或点击上传',
	DownloadTemplate: '下载模板',
	InvalidFileSuffix: '文件格式仅支持：{0}',
	ContentQuery: '内容查询',
	Played: '已播',
	Unplayed: '未播',
	DeleteShortcutMenu: '删除快捷菜单',
	SureToDeleteShortcutMenu: '确定要删除此快捷菜单吗',
	AddShortcutMenu: '删除快捷菜单',
	ShortcutMenuList: '快捷菜单列表',
	UploadFileSizeLimit: '上传文件大小限制',
	BroadcastDefaultParameters: '广播默认参数',
	BroadcastingTargetType: '默认广播目标类型',
	DefaultbroadcastMode: '默认播出方式',
	Modulation: '音量调节',
	NewSchemeName: '新方案名称',
	TodayBroadcastPlan: '今日播出计划',
	BatchModifyTasks: '批量修改任务',
	BatchModify: '批量修改',
	NumberOfSelectedTasks: '已选任务个数',
	PleaseCheckTheParameters: '请勾选需要修改的参数',
	NoPermissionTemporarily: '暂无权限',
	bcscbnxyypsc2: '播出时长不能小于音频时长*播出次数',
	CurrentlyDoesNotSupportDisplayingContent: '暂不支持显示内容',
	AreYouSureToSaveIfThereIsAConflict: '以下任务执行时间有冲突，确定要保存吗',
    ...viLocale
}