<template>
	<!-- 备案号 -->
	<div class="login-footer">
		<template v-if="showBeiAn">
			<a class="left" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=35020602002469" target="_blank">
				<img src="../../assets/img/policebadge.jpg" alt="警徽">
				<span>闽公网安备 35020602002469号</span>
			</a>
			<a class="right" href="https://beian.miit.gov.cn/" target="_blank">备案号：闽ICP备2022005087号-1</a>
		</template>
		<span style="margin-left: 12px; cursor: pointer;" @click="aboutSubmit">{{$t('Aboutus')}}</span>
	</div>
</template>

<script>
	import { getVersion } from "@/request/api/auth"
	export default {
		name: "FooterForm", // 头部导航组件
		data() {
			return {
				showBeiAn: false, //是否显示备案号
				version: '',
			}
		},
		methods: {
			//点击关于我们
			aboutSubmit() {
				const h = this.$createElement
				this.$elconfirm('',{message: h('div', [h('p', '前端版本: V1.0.0.2024082301'),h('p', '后端版本: ' + this.version)])}).then(() => {}).catch(() => {})
			},
			// 判断是否显示备案号,ip地址访问的不显示备案号
			setShowBeiAn(){
				const { hostname } = location
				const nodes = hostname.split('.')
				if(nodes.length != 4){
					this.showBeiAn = true
				}
			},
			async toGetVersion(){
				const {code, data} = await getVersion()
				if(code == 200){
					this.version = data
				}
			}
		},
		mounted() {
			this.setShowBeiAn()
			this.toGetVersion()
		}
	}
</script>

<style lang='less' scoped>
	.login-footer {
		position: absolute;
		left: 0;
		right: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		bottom: 40px;
		font-size: 14px;
		color: #333;
		z-index: 999;

		a {
			display: block;
			color: #333;
		}

		.left {
			margin-right: 12px;
			display: flex;
			align-items: center;

			img {
				border-radius: 10px;
				margin-right: 10px;
			}
		}
	}
	@media screen and (max-height: 900px){
		.login-footer{
			font-size: 14px;
			bottom: 20px;
		}
	}
	@media screen and (max-width: 1440px){
		.login-footer{
			font-size: 14px;
			bottom: 20px;
		}
	}
</style>
