import { localGet, localSet } from '@/configs/storage'
import { platformInfo, platformUpdate } from "@/request/api/platform"
import i18n from '@/i18n'
import router from '@/router'
export default {
    namespaced: true,
    state: () => ({
        token: localGet('token') || '',
        account: localGet('account') || '',
        password: localGet('password') || '',
		avatar: localGet('avatar') || '',
		userId: localGet('userId') || '',
        themeClass: localGet('themeClass'),
        isCollapse: false,
		codeVisble: false,
		adminRole: false,
		pageSize: parseInt(localGet('pageSize')) || 10,
		platformInfo: {
			systemName: '智慧云广播平台',
		},
		playUrl: '',
		menuList: [],
		largeFullScreen: false, //数据大屏页面是否全屏
		clientWidth: document.body.clientWidth,
		clientHeight: document.body.clientHeight,
		isDevelopment: process.env.NODE_ENV === 'development', //是否是调试模式
		settinsNameAll: { //报警设置状态，status为1的时候开启状态，0是关闭状态
			1: { name: '人工视频报警', status: 1 },
			2: { name: '移动侦测报警', status: 1 },
			3: { name: '遗留物检测报警', status: 1 },
			4: { name: '物体移除检测报警', status: 1 },
			5: { name: '越界侦测报警', status: 1 },
			6: { name: '区域入侵侦测报警', status: 1 },
			7: { name: '逆行检测报警', status: 1 },
			8: { name: '徘徊检测报警', status: 1 },
			9: { name: '流量统计报警', status: 1 },
			10: { name: '密度检测报警', status: 1 },
			11: { name: '视频异常检测报警', status: 1 },
			12: { name: '快速移动报警', status: 1 },
		},
		languageList: {
			zh_CN: {name: '简体中文', value: 'zh'},
			en_US: {name: 'English', value: 'en'},
			th_TH: {name: 'ภาษาไทย', value: 'th'},
			vi_VN: {name: 'Tiếng Việt', value: 'vi'},
		},
		keepAliveComponents: [],
		terminalTypeList: {
			1: {label: 'Terminal', value: '1', type: 'revice'},
			2: {label: 'ipMicrophone', value: '2', type: 'acquisition'},
			3: {label: 'collector', value: '3', type: 'acquisition'},
			4: {label: 'intercomTerminal', value: '4', type: 'intercom'},
			5: {label: 'ipAdapter', value: '5', type: 'ipAdapter'},
			6: {label: 'mfr', value: '6', type: 'acquisition'},
			7: {label: 'smartscreenname', value: '7', type: 'smartscreenname'},
			8: {label: 'SmartHost', value: '8', type: 'revice'},
		},
    }),
    actions: {
		async getplatformInfo({commit, state, dispatch}, paramas){
			try {
			  let { data, code } = await platformInfo()
			  if(code == 200){
				if(!data.openRegistration) data.openRegistration = '1'
				commit('SAVE_PLATFORMINFO', data)
				if(!localGet('lang')){
					const {languageList} = state
					const lang = languageList[data.language].value
					i18n.locale = lang
					localSet('lang', lang)
					document.title = i18n.t(router.history.current.meta.title)
				}
				if(data.loadNetworkResources == '1' && paramas && paramas == 'needload'){
					dispatch('addLink', 'https://cdn.bootcdn.net/ajax/libs/font-awesome/6.1.1/css/all.css')
					dispatch('addLink', '//api.map.baidu.com/library/DrawingManager/1.4/src/DrawingManager_min.css')
					// dispatch('addScript', '//api.map.baidu.com/api?v=2.0&ak=sWVzBQ8S13r7sBpUf3vKzK88sFsGmdpf')
					dispatch('addScript', 'https://api.map.baidu.com/getscript?v=2.0&ak=sWVzBQ8S13r7sBpUf3vKzK88sFsGmdpf&services=&t=20240108120844')
					setTimeout(() => {
						dispatch('addScript', '//api.map.baidu.com/library/DrawingManager/1.4/src/DrawingManager_min.js')
						dispatch('addScript', '//api.map.baidu.com/library/GeoUtils/1.2/src/GeoUtils_min.js')
					}, 1000)
				}
			  }
			} catch (error) {
			  error?.status && console.error(error?.status)
			}
		},
		addScript({ dispatch, commit }, src){
			var script = document.createElement('script')
			script.type = 'text/javascript'
			script.src = src
			document.head.appendChild(script)
		},
		addLink({ dispatch, commit }, src){
			var link = document.createElement('link')
			link.rel = 'stylesheet'
			link.href = src
			document.head.appendChild(link)
		},
		addCachedView({ dispatch, commit }, view) {
		    commit('ADD_CACHED_VIEW', view)
		},
    },
    mutations: {
		SAVE_ADMINROLE(state, value){
			state.adminRole = value
		},
		//保存是否全屏
		SAVE_FULLSCREEN(state, value){
			if(value == 1){
				state.largeFullScreen = false
			}else{
				state.largeFullScreen = !state.largeFullScreen
			}			
		},
		//保存菜单地址
		SAVE_MENULLIST(state, value){
			state.menuList = value
		},
		//保存首页播放记录的当前播放歌曲的地址
		SAVE_PLAYURL(state, value){
			state.playUrl = value
		},
		//保存平台信息
		SAVE_PAGESIZE(state, value){
			state.pageSize = value
		},
		//保存平台信息
		SAVE_PLATFORMINFO(state, value){
			state.platformInfo = value
		},
        //存储token
        SAVE_TOKEN(state, value) {
            state.token = value
        },
        //存储用户id
        SAVE_USERID(state, value) {
            state.userId = value
        },
        //存储用户名
        SAVE_ACCOUNT(state, value) {
            state.account = value
        },
        //存储密码
        SAVE_PASSWORD(state, value) {
            state.password = value
        },
        //存储主题颜色
        SAVE_THEMECOLOR(state, value) {
            state.themeClass = value
        },
        // 左侧菜单折叠
        MENU_COLLAPSE(state, value) {
            state.isCollapse = value
        },
		SAVE_CODEVISBLE(state, value) {
            state.codeVisble = value
        },
		SAVE_AVATAR(state, value) {
            state.avatar = value
        },
		ADD_CACHED_VIEW: (state, view) => {
		    if (state.keepAliveComponents.includes(view.name)) return
		    state.keepAliveComponents.push(view.name)
		},
    },
    getters: {

    },
}