<template>
  <div class="menu-tree">
    <label v-for="{ id, path, children, icon, code } of menuData" :key="id">
      <el-submenu :index="path" v-if="children" popper-class="asdf">
        <template slot="title">
          <i class="ft-icon iconfont" :class="icon" aria-hidden="true"></i>
          <span class="textspan" :title="$t(code)">{{ $t(code) }}</span>
        </template>
        <label class="label-item">
          <MenuTree :menuData="children"></MenuTree>
        </label>
      </el-submenu>
	  <a :href="`/#${path}`" v-else>
		<el-menu-item :index="path">
		  <i v-if="icon" class="ft-icon iconfont" :class="icon" aria-hidden="true"></i>
		  <span class="textspan" :title="$t(code)" slot="title">{{ $t(code) }}</span>
		</el-menu-item>
	  </a>
    </label>
  </div>
</template>

<script>
import MenuTree from "./MenuTree" //递归的思想
import '@/assets/css/resetElementMenu.css'
export default {
  props: {
    menuData: {
      type: Array,
      required: true
    }
  },
  name: "MenuTree", //菜单树组件
  components: {
    MenuTree
  }
}
</script>
<style lang="less" scoped>
.menu-tree {
  .ft-icon {
    vertical-align: middle;
    margin-right: 17px;
    width: 20px;
	height: 20px;
	line-height: 20px;
    text-align: center;
    font-size: 19px;
    color: #fff;
  }
  .icon-info{
	  font-size: 15px;
	  margin-top: 2px;
  }
  /deep/ .el-submenu__icon-arrow{
	  width: 0;
	  height: 0;
	  border: 7px solid transparent;
	  border-left-color: #fff;
	  margin-top: -3px;
	  &:before{
		  display: none;
	  }
  }
  /deep/ .el-submenu.is-opened>.el-submenu__title .el-submenu__icon-arrow{
	  transform: rotate(90deg);
  }
  .menu-tree{
  	/deep/ .el-submenu__title{
		font-size: 14px;
	}
	/deep/ .el-submenu__icon-arrow{
		margin-top: -6px;
	}
	.menu-tree{
  		/deep/ .el-menu-item{
			height: 38px!important;
			margin: 5px 0;
			line-height: 38px!important;
  			padding-left: 86px!important;
  		}
		.textspan{
			max-width: 100%;
		}
  	}
  }
  .textspan{
  	display: inline-block;
  	max-width: 70%;
  	overflow: hidden;
  	text-overflow: ellipsis;
  	vertical-align: middle;
  }
}
.el-menu-item{
	font-size: 18px;
  	color: #fff;
  &:hover{
	  background: none;
  }
  &.is-active{
	  color: #fff;
	  background: rgba(255,255,255,.3);
  }
  *{
	vertical-align: baseline;
  }
}
.el-menu-item,/deep/ .el-submenu__title{
	height: 58px!important;
	line-height: 58px!important;
	margin: 0 0;
}
.label-item{
	.menu-tree{
		label{
			.el-submenu,.el-menu-item{
				  .ft-icon{
					  font-size: 13px;
					  margin-right: 10px;
					  // display: none;
				  }
				  .icon-bell{
					  font-size: 17px;
				  }
				  .icon-music{
					  font-size: 12px;
				  }
				  .icon-phone-volume{
					  font-size: 15px;
				  }
				  .icon-fire-extinguisher,.icon-up-right-from-square,.icon-yen-sign,.icon-ai-alarm{
					  font-size: 17px;
				  }
				  .icon-location-dot,.icon-ai-view,.icon-ai-map{
					  font-size: 15px;
				  }
				  .icon-screenrelease,.icon-user,.icon-ai-device,.icon-operator{
					  font-size: 16px;
				  }
				  .icon-smartscreen,.icon-releasehistory{
					  font-size: 14px;
				  }
			}
			/deep/ .el-submenu__title,.el-menu-item{
				padding-left: 56px!important;
			}
		}
	}
}
</style>