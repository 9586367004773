//泰文
import thLocale from 'element-ui/lib/locale/lang/th'
export default {
    smartCloudBroadcastingSystem: 'ระบบวิทยุเมฆอัจฉริยะ',
    switchLanguage: 'เปลี่ยนภาษา',
    register: 'ลงทะเบียน',
    verificationCode: 'รหัสการตรวจสอบ',
    secureLogin: 'เข้าสู่ระบบการรักษาความปลอดภัย',
    returnToLogin: 'เข้าสู่ระบบกลับ',
    login: 'ล็อกอิน',
    autologin: 'ล็อกอิน',
    error: 'ข้อผิดพลาด',
    downloadCenter: 'ศูนย์ดาวน์โหลด',
    downloaddetail: 'ศูนย์ดาวน์โหลด',
    commonProblem: 'คำถามที่พบบ่อย',
    freeRegistration: 'ลงทะเบียนฟรี',
    agreement: 'ข้อตกลง',
    findPassword: 'กู้คืนรหัสผ่าน',
    loginOut: 'ออกจากระบบ',
    switchTheme: 'เปลี่ยนชุดตกแต่ง',
    black: 'กาฬ',
    blue: 'สีฟ้า',
    gray: 'สีเทา',
    home: 'หน้าแรก',
	paste: 'แปะ',
	forever: 'ตลอดไป ตลอดกาล',
    broadcast: 'กระจายเสียง',
    broadcastRelease: 'ประกาศวิทยุ',
    realTimeBroadcast: 'วิทยุเวลาจริง',
    musicbroadcast: 'เพลงวิทยุ',
    timedBroadcast: 'เวลาออกอากาศ',
    deviceOnDemand: 'ความต้องการอุปกรณ์',
    deviceBroadcastd: 'อุปกรณ์การออกอากาศ',
    visualBroadcasting: 'วิดีโอออกอากาศ',
    shoutingbroadcast: 'การโห่ร้อง',
    mediaLibrary: 'ห้องสมุดสื่อ',
    deviceManagement: 'การจัดการอุปกรณ์',
    monitoringEvaluation: 'การติดตามประเมินผล',
    platformManagement: 'การจัดการแพลตฟอร์ม',
    accountManagement: 'การจัดการบัญชี',
    systemManagement: 'การจัดการระบบ',
    mochaITOM: 'การจัดการมิติการดำเนินงาน',
    thirdPartyAccess: 'การเข้าถึงบุคคลที่สาม',
    serviceRenewal: 'ต่ออายุบริการ',
    personalSettings: 'การตั้งค่าส่วนบุคคล',
    userManagement: 'การจัดการผู้ใช้งาน',
    userGroupManagement: 'การจัดการกลุ่มผู้ใช้',
    orderlist: 'บันทึกการสั่งซื้อ',
    ordernumber: 'หมายเลขการสั่งซื้อ',
	Ordercontent: 'เนื้อหาการสั่งซื้อ',
	Orderdetails: 'รายละเอียดการสั่งซื้อ',
	drawee: 'จ่ายเงิน',
	amount: 'ผลรวม',
	Paymentmethod: 'วิธีการชำระเงิน',
    Billingcenter: 'ศูนย์ออกตั๋ว',
    Toinvoice: 'ร่างเอกสาร',
    Electronicinvoice: 'ใบแจ้งหนี้อิเล็กทรอนิกส์',
	servicerenewal: 'ต่ออายุบริการ',
    regionalManagement: 'การจัดการภูมิภาค',
    roleManagement: 'การจัดการบทบาท',
    menuManagement: 'การจัดการเมนู',
    firmwareManagement: 'การจัดการเฟิร์มแวร์',
    upgradeManagement: 'การจัดการการอัพเกรด',
    operationLog: 'เข้าสู่ระบบการดำเนินงาน',
    aboutPlatform: 'เกี่ยวกับแพลตฟอร์ม',
    dataBaseBackup: 'สำรองฐานข้อมูล',
    mediaServiceManagement: 'การจัดการบริการสื่อมวลชน',
    operationMonitoring: 'การตรวจสอบการดำเนินงาน',
    onlineService: 'เว็บบอร์ดออนไลน์',
    systemLog: 'เข้าสู่ระบบ',
    systemMessageRelease: 'เผยแพร่ข้อความระบบ',
    filestorage: 'บริการจัดเก็บไฟล์',
	fileStorageMethod: 'โหมดการจัดเก็บไฟล์',
    emergencyPlan: 'แผนฉุกเฉิน',
    callIntercom: 'เรียกอินเตอร์คอม',
    fireLinkage: 'การเชื่อมโยงดับเพลิง',
    gisMap: 'แผนที่ระบบสารสนเทศภูมิศาสตร์',
    audioMedia: 'สื่อเสียง',
    audio: 'ออดิโอ',
    speechSynthesis: 'การสังเคราะห์เสียง',
    acquisitionEquipment: 'อุปกรณ์การเก็บรวบรวม',
    acquisitionEquipment2: 'อุปกรณ์การเก็บรวบรวม（เก็บรวบรวม+ไมโครโฟน IP）',
    intercomTerminal: 'อินเตอร์คอมเทอร์มินัล',
    ipMicrophone: 'ไมโครโฟน IP',
    collector: 'เก็บรวบรวม',
	mfr: 'MFR',
    ipAdapter: 'อะแดปเตอร์ IP',
    receivingTerminal: 'สถานีรับ',
    equipmentWarehousing: 'อุปกรณ์เข้าห้องสมุด',
    deviceGroup: 'กลุ่มอุปกรณ์',
    broadcastRecord: 'บันทึกการออกอากาศ',
    audioHome: {
        broadcastRecord: 'บันทึกการออกอากาศ',
    },
    broadcastStatistics: 'สถิติการออกอากาศ',
    largedataScreen: 'หน้าจอข้อมูลขนาดใหญ่',
    offlineTerminal: 'ออฟไลน์เทอร์มินัล',
    resources: 'สถานการณ์ทรัพยากร',
    serviceAlarm: 'เตือนภัยทางธุรกิจ',
    offlineBroadcast: 'วิทยุออนไลน์',
    mediaAudit: 'การตรวจสอบสื่อ',
    sensitiveWords: 'อ่อนไหว',
    sensitiveVocabulary: 'คำศัพท์ที่ละเอียดอ่อน',
    newSensitiveWords: 'เพิ่มคำศัพท์ที่ละเอียดอ่อน',
    textContent: 'เนื้อหาข้อความ',
    textName: 'ชื่อข้อความ',
    informant: 'การออกเสียง',
    speedOfSpeech: 'อัตราความเร็ว',
    intonation: 'สไตล์การพูด',
    delay: 'ถ่วงความเจริญ',
    content: 'เนื้อหา',
    oneTouchScreenProjection: 'หน้าจอคลิกเดียว',
    systemMessage: 'ข่าวระบบ',
    deviceStatistics: 'สถิติอุปกรณ์',
    totalCount: 'จำนวนรวม',
    online: 'ออนไลน์',
    offline: 'ออฟไลน์',
    menuShortcutentry: 'เมนูทางลัด',
    planAlarm: 'แผนเตือนภัย',
    fire: 'เพลิงไหม้',
    earthquake: 'แผ่นดินไหว',
    airDefense: 'การป้องกันทางอากาศ',
    broadcasting: 'ในอากาศ',
    broadcastPlan: 'โปรแกรมการออกอากาศ',
    broadcastMonitoring: 'ฟังวิทยุ',
    turnOffBroadcast: 'ปิดวิทยุ',
    detail: 'รายละเอียด',
    theDayBefore: 'วันก่อน',
    theDayAfter: 'วันต่อมา',
    close: 'ปิดกล้อง',
    broadcastFrequencyStatistics: 'สถิติจำนวนออกอากาศ',
    broadcastStatusStatistics: 'สถิติสถานะการออกอากาศ',
    terminalDistribution: 'การแจกแจงเทอร์มินัล',
    quantity: 'จำนวนรวม',
    many: 'เวที',
    equipmentType: 'ประเภทอุปกรณ์',
    equipmentTypeStatistics: 'สถิติประเภทอุปกรณ์',
    terminalOnlineStatistics: 'สถิติออนไลน์',
    activateIntercomTerminal: 'เปิดใช้งานอินเตอร์คอม',
    activateCollectionDevice: 'เปิดใช้งานอุปกรณ์',
    modifyCollectionDevice: 'ปรับเปลี่ยนอุปกรณ์การซื้อ',
    modifyIntercomTerminal: 'แก้ไขอินเตอร์คอมเทอร์มินัล',
    modifyTerminal: 'แก้ไขเทอร์มินัล',
    broadcastSuccessful: 'วิทยุสำเร็จ',
    broadcastFailure: 'วิทยุล้มเหลว',
    onlineRate: 'อัตราออนไลน์',
	task: 'ภารกิจ',
    taskName: 'ชื่องาน',
    priority: 'ลำดับความสำคัญ',
    broadcastTarget: 'เป้าหมายการออกอากาศ',
    startTime: 'เวลาเริ่มต้น',
    endTime: 'เวลาสิ้นสุด',
    volume: 'ระดับเสียง',
    operation: 'การดำเนินการ',
    newTask: 'งานใหม่',
    sameDay: 'วันนั้น',
    sevenDay: 'เจ็ดวัน',
    oneMonth: 'หนึ่งเดือน',
    startDate: 'วันที่เริ่มต้น',
    endDate: 'วันที่สิ้นสุด',
    search: 'ค้นหา',
    query: 'สอบถาม',
    number: 'หมายเลข',
    serialNumber: 'เลขลำดับ',
    broadcastMode: 'กระจายเสียง',
    broadcastStatus: 'ออกอากาศ',
    operator: 'ผู้ควบคุม',
    offBroadcastTime: 'เวลาปิด',
    cycle: 'กลมดิก',
    enable: 'เปิดผนึก',
    disable: 'ปิดการใช้งาน',
    creationTime: 'สร้างเวลา',
    whole: 'จำนวนทั้งหมด',
    date: 'เดท',
    currentlyEnabledScheme: 'เปิดใช้งานรูปแบบปัจจุบัน',
    newScheme: 'โครงการใหม่',
    removeScheme: 'ลบโครงร่าง',
    enableScheme: 'เปิดใช้งานโปรแกรม',
    copyScheme: 'โครงการคัดลอก',
    updateTask: 'ปรับปรุงงาน',
    delete: 'ลบทิ้ง',
    refresh: 'ทำให้สดชื่น',
    to: 'จนกระทั่ง',
    audioUpload: 'อัพโหลดเสียง',
    mediaCategory: 'หมวดสื่อ',
    mediaDetails: 'รายละเอียดสื่อมวลชน',
    fileName: 'ชื่อไฟล์',
    duration: 'กาลเวลา',
    uploader: 'อัพโหลด',
    uploadTime: 'เวลาอัพโหลด',
    audit: 'บทวิจารณ์',
    auditStatus: 'สถานะการตรวจสอบ',
    reviewer: 'เจ้าหน้าที่ตรวจสอบ',
    auditTime: 'เวลาตรวจสอบ',
    audition: 'ออดิชั่น',
    audioMediaName: 'ชื่อสื่อเสียง',
    textView: 'ดูข้อความ',
    reviewComments: 'ตรวจสอบความคิดเห็น',
    newlyAdded: 'ใหม่',
    newlyAdded: 'ใหม่',
    categoryName: 'ชื่อหมวดหมู่',
    category: 'การจัดเรียง',
    edit: 'ตัดต่อ',
    mediaLevel: 'ระดับสื่อ',
    uploadFile: 'อัพโหลดไฟล์',
    clickUpload: 'คลิกที่อัพโหลด',
    determine: 'ยืนยัน',
    cancel: 'ยกเลิก',
    createText: 'สร้างข้อความ',
    textSpeechSynthesis: 'การสังเคราะห์เสียงพูด',
    findingsOfAudit: 'ผลการตรวจสอบ',
    mediaType: 'ประเภทสื่อ',
    activation: 'กระตุ้น',
    activateTerminal: 'เปิดใช้งานเทอร์มินัล',
    terminal: 'ตอนท้าย',
    terminalName: 'ชื่อสถานี',
    equipment: 'อุปกรณ์',
    name: 'นาม',
    groupName: 'ชื่อกลุ่ม',
    terminalNumber: 'หมายเลขช่อง',
    groupingName: 'ชื่อกลุ่ม',
    status: 'สภาพ',
    activatePersonnel: 'กระตุ้นบุคลากร',
    activateTime: 'เวลาเปิดใช้งาน',
    servicePeriod: 'ระยะเวลาการให้บริการ',
    specialSettings: 'ตั้งค่าพิเศษ',
    quit: 'ถอนตัว',
    monitoringAndMonitoring: 'จอภาพ',
    callObjectSettings: 'การตั้งค่าวัตถุโทร',
    commandControl: 'คำสั่งควบคุม',
    onlineRecord: 'บันทึกออนไลน์',
    physicalAddress: 'ที่อยู่ทางกายภาพ',
    warehousingStatus: 'สถานะห้องสมุด',
    newEquipmentGrouping: 'กลุ่มอุปกรณ์ใหม่',
    newEquipmentGrouping2: '新增设备分组',
    modifyEquipmentGrouping: 'ปรับเปลี่ยนกลุ่มอุปกรณ์',
    groupNumber: 'หมายเลขกลุ่ม',
    type: 'รูปร่างลักษณะ',
    coverage: 'ความคุ้มครอง',
    timeFrame: 'ช่วงเวลา',
    broadcastStatisticalMethod: 'สถิติการออกอากาศ',
    broadcastType: 'ประเภทของการออกอากาศ',
    user: 'ประเทศอเมริกา',
    area: 'แว่นแคว้น',
    playbackTimes: 'จำนวนผู้เล่น',
	startplay: 'เริ่มออกอากาศ',
    seconds: 'ครั้งที่',
    download: 'ดาวน์โหลด',
    lineChart: 'แผนภูมิเส้นโค้ง',
    barChart: 'แผนภูมิคอลัมน์',
    lastOfflineTime: 'ครั้งสุดท้ายครับ',
    offlineDays: 'วันออฟไลน์',
    exportTerminalInformation: 'ส่งออกข้อมูล terminal',
    securitySetting: 'การตั้งค่าความปลอดภัย',
    personalData: 'รายละเอียดส่วนบุคคล',
    orderRecord: 'บันทึกการสั่งซื้อ',
    account: 'บัญชีที่เป็นเจ้าของ',
    addTo: 'แถมท้าย',
    moveUp: 'การยกระดับ',
    moveDown: 'ลง',
    callObjectList: 'รายชื่อวัตถุโทร',
    callObjectAddition: 'เพิ่มวัตถุโทร',
    accountNumber: 'อธิบายแจกแจง',
    accountLevel: 'ระดับบัญชี',
    removeUserGroup: 'ย้ายกลุ่มผู้ใช้',
    fullName: 'เนม',
    mailbox: 'ไปรษณียภัณฑ์',
    mobilePhone: 'โทรศัพท์มือถือ',
	requeirPhone: 'กรุณากรอกหมายเลขโทรศัพท์มือถือ',
    sex: 'เพศ',
    role: 'บทบาท',
    userGroup: 'กลุ่มผู้ใช้',
    addToUserGroup: 'เพิ่มกลุ่มผู้ใช้',
    addUserGroupMembers: 'เพิ่มสมาชิกกลุ่มผู้ใช้',
    managementArea: 'การจัดการพื้นที่',
    mapPositioning: 'แผนที่ที่ตั้ง',
    registrationTime: 'เวลาลงทะเบียน',
    yourBasicInformation: 'ข้อมูลพื้นฐานของคุณ',
    yourSecurityServices: 'บริการรักษาความปลอดภัย',
    safetyLevel: 'ระดับความปลอดภัย',
    loginPassword: 'รหัสผ่านเข้าสู่ระบบ',
    bindMailbox: 'ผูกกล่องจดหมาย',
    bindingMobilePhone: 'ผูกโทรศัพท์มือถือ',
    changePassword: 'เปลี่ยนรหัสผ่าน',
    modifyMobilePhone: 'แก้ไขโทรศัพท์มือถือ',
    addEmail: 'เพิ่มกล่องจดหมาย',
    replaceMailbox: 'เปลี่ยนกล่องจดหมาย',
    replacementArea: 'เปลี่ยนพื้นที่',
    low: 'หดหู่ใจ',
    middle: 'ตรงกลาง',
    high: 'โด่ง',
    male: 'มนุษย์',
    female: 'หญิงทำงานบ้าน',
    currentAvatar: 'หัวปัจจุบัน',
    changeTheAvatar: 'เปลี่ยนหัว',
    other: 'อื่นๆ',
    preservation: 'เก็บรักษา',
    registeredAccount: 'ลงทะเบียนบัญชี',
    roleAuthorization: 'บทบาทการอนุญาต',
    fixedLocation: 'ตำแหน่งในทีม',
    createUserGroup: 'สร้างกลุ่มผู้ใช้',
    userGroupName: 'ชื่อกลุ่มผู้ใช้',
    creator: 'สร้างบุคลากร',
    groupMembers: 'สมาชิกกลุ่ม',
    addInitialArea: 'เพิ่มพื้นที่เริ่มต้น',
    areaCode: 'รหัสพื้นที่',
    areaCoding: 'รหัสพื้นที่',
	Superior: '上级',
    areaName: 'ชื่อภูมิภาค',
    regional: {
        fullName: 'ชื่อเต็ม',
    },
    areaFullName: 'ชื่อเต็มของภูมิภาค',
    longitudeAndLatitude: 'ละติจูดลองจิจูด',
    longitude: 'ลองจิจูด',
    currentLongitude: 'เส้นแวงในปัจจุบัน',
    latitude: 'ละติจูด',
    currentLatitude: 'ละติจูดในปัจจุบัน',
    addChildRegion: 'เพิ่มพื้นที่ล่าง',
    newRole: 'บทบาทใหม่',
    modifyRole: 'ปรับเปลี่ยนบทบาท',
    rolePermissions: 'สิทธิ์ในบทบาท',
    roleID: 'รหัสบทบาท',
    roleName: 'ชื่อบทบาท',
    describe: 'อธิบาย',
    firmwareUpload: 'เฟิร์มแวร์',
    firmwareFile: 'แฟ้มเฟิร์มแวร์',
    versionCode: 'รหัสรุ่น',
    versionName: 'ชื่อรุ่น',
    equipmentUpgrade: 'การอัพเกรดอุปกรณ์',
    upgradeRecord: 'บันทึกการอัพเกรด',
    upgradeTarget: 'เป้าหมายการอัพเกรด',
    equipmentName: 'ชื่ออุปกรณ์',
    firmwareVersion: 'เวอร์ชั่นเฟิร์มแวร์',
    firmwarePackageName: 'ชื่อเฟิร์มแวร์',
    forcedUpgrade: 'บังคับให้อัพเกรด',
    upgradeNow: 'อัพเกรดทันที',
    anyPointInTime: 'จุดเวลาใดๆ',
    taskCreationTime: 'เวลาในการสร้างงาน',
    upgradeAccordingToTheSpecifiedTime: 'อัพเกรดในเวลาที่กำหนด',
    deviceSoftwareVersion: 'อุปกรณ์ซอฟต์แวร์รุ่น',
    force: 'บังคับ',
    upgradeTime: 'เวลาอัพเกรด',
    upgradeProgress: 'ความคืบหน้าการอัพเกรด',
    cancelExecution: 'ยกเลิกการดำเนินการ',
    logLevel: 'ระดับเข้าสู่ระบบ',
    logContent: 'เข้าสู่ระบบเนื้อหา',
    logTime: 'เข้าสู่ระบบเวลา',
    news: 'จดหมายสั้น',
    warning: 'เตือนภัย',
    systemName: 'ชื่อระบบ',
    softwareAbbreviation: 'ซอฟต์แวร์ย่อ',
    systemAbbreviation: 'ชื่อย่อของระบบ',
    softwareVersion: 'ฉบับซอฟต์แวร์',
    SoftwareName: 'ชื่อซอฟต์แวร์',
    copyright: 'ลิขสิทธิ์',
    serviceProviderAddress: 'ที่อยู่ผู้ให้บริการ',
    serviceProvider: 'ผู้ให้บริการ',
    modifyPlatformParameters: 'ปรับเปลี่ยนพารามิเตอร์แพลตฟอร์ม',
    retry: 'ลองอีกครั้ง',
    password: 'รหัสผ่าน',
    onTheAir: 'กระจายเสียง',
    notbroadcast: 'เปิดโล่ง',
    auditReceipt: 'ตรวจสอบรายการ',
    inIntercom: 'อินเตอร์คอม',
    notWarehoused: 'พลาด',
    warehoused: 'สินค้าคงคลัง',
    auditFree: 'ยกเว้นการตรวจสอบ',
    notApproved: 'พลาด',
    Approved: 'ตรวจสอบ',
    passTheAudit: 'ผ่านการตรวจสอบ',
    failedToPassTheReview: 'ความล้มเหลวในการตรวจสอบ',
    schemeName: 'ชื่อโครงการ',
    playbackMode: 'โหมดการเล่น',
    broadcastAudio: 'ออกอากาศเสียง',
    broadcastNumbers: 'เวลาออกอากาศ',
    numberOfCycles: 'จำนวนรอบ',
    grouping: 'กลุ่มก้อน',
    partition: 'เขต',
    airDate: 'วันที่ออกอากาศ',
    broadcastTimes: 'เวลาออกอากาศ',
    broadcastCycle: 'วงจรการออกอากาศ',
    Monday: 'วันจันทร์',
    Tuesday: 'วันอังคาร',
    Wednesday: 'วันพุธ',
    Thursday: 'พฤหัสบดี',
    Friday: 'วันศุกร์',
    Saturday: 'เสาร์',
    Sunday: 'อาทิตย์',
    writtenWords: 'ข้อเขียน',
    audioFile: 'ไฟล์เสียง',
    radioStation: 'สถานีวิทยุ',
    radioRelay: 'ออกอากาศทางวิทยุ',
    streamingAddress: 'ที่อยู่กระแส',
    streamingAddressRelay: 'ที่อยู่กระแส',
    triggerEvent: 'เรียกเหตุการณ์',
    stopBroadcastingMode: 'โหมดระงับ',
    fault: 'เสียเหลี่ยม',
    numberOfTerminals: 'จำนวนสถานี',
    terminalList: 'รายชื่อสถานี',
    singleCycle: 'เล่นเดี่ยว',
    loopPlayback: 'วงจรการเล่น',
    remark: 'ข้อคิดเห็น',
    time: 'กาละ',
    addMediaCategory: 'เพิ่มหมวดหมู่สื่อ',
    modifyMediaCategory: 'แก้ไขหมวดสื่อ',
    modifyText: 'แก้ไขข้อความ',
    modifyUserGroup: 'ปรับเปลี่ยนกลุ่มผู้ใช้',
    modifyArea: 'ปรับเปลี่ยนพื้นที่',
    pleaseEnterYourName: 'กรุณาใส่ชื่อ',
    pleaseEnterTheAccountNumber: 'กรุณาใส่หมายเลขบัญชี',
    service: 'รับใช้',
    playing: 'ออกอากาศ',
    playbackSuccessful: 'เล่นสำเร็จ',
    playbackFailed: 'ความล้มเหลวในการเล่น',
    Selectedequipment: 'อุปกรณ์ที่เลือก',
    applicationmanagement: 'การจัดการโปรแกรมประยุกต์',
    Versionnumber: 'หมายเลขรุ่น',
    yes: 'ใช่แล้ว',
	billingmode: '服务计费模式',
	ordermanagement: '订单管理',
	Daterange: 'ช่วงวันที่',
	invoice: 'ทำเครื่องหมาย',
	Gocheck: 'ไปดู',
	second: 'วินาที',
	Price: 'ราคา',
	invoicingmanagement: 'การจัดการใบแจ้งหนี้',
	Invoicingrequisition: 'ถอนเงิน',
	Medialist: 'รายชื่อสื่อมวลชน',
	Broadcastcontrol: 'ควบคุมการออกอากาศ',
	Stopbroadcasting: 'พัก',
	Broadcastcontent: 'เนื้อหาวิทยุ',
	Oldpassword: 'รหัสผ่านเก่า',
	NewPassword: 'รหัสผ่านใหม่',
    account2: 'บัญชีที่เป็นเจ้าของ',
	smsservice: 'บริการ SMS',
	Reset: 'ตั้งค่าใหม่',
	info: 'การเปิดเผยข้อมูล',
	mediaassets: 'การจัดการสินทรัพย์สื่อ',
	picture: 'รูปภาพ',
	video: 'วีดีโอ',
	music: 'ดนตรี',
	smartscreenname: '智慧屏',
	smartscreen: 'การจัดการหน้าจออัจฉริยะ',
	ljqy: '逻辑区域',
	equipmentdetail: 'รายละเอียดอุปกรณ์',
	screenrelease: 'เผยแพร่บนหน้าจอ',
	realtimerelease: '即时发布',
	timedrelease: '定时发布',
	no:'No',
	releasehistory: 'บันทึกการเปิดตัว',
	zdai: 'งาน AI ของเทอร์มินัล',
	Pleaseinputapassword: 'กรุณากรอกรหัสผ่าน',
	Pleaseentertheverificationcode: 'กรุณากรอกรหัสยืนยัน(verification code)',
	ServerAddress: 'ที่อยู่เซิร์ฟเวอร์',
	smdl: 'สแกนรหัสสองขั้นตอนเพื่อเข้าสู่ระบบ',
	zcjbsty: 'ลงทะเบียนเพื่อให้ความยินยอม',
	Theserviceagreement: 'ข้อตกลงการให้บริการ',
	Privacypolicy: 'นโยบายความเป็นส่วนตัว',
	Legalnotices: 'ประกาศทางกฏหมาย',
	Clientserviceprotocol: 'โปรโตคอลการบริการลูกค้า',
	ji: 'และ',
	gxxy: 'โปรดตรวจสอบว่าคุณได้อ่านและยอมรับข้อตกลงข้างต้นแล้ว',
	azapph: 'หลังจากติดตั้งแอพมือถือแล้ว ให้สแกนรหัส QR ด้วยแอพเพื่อรับที่อยู่เซิร์ฟเวอร์',
	syllq: 'โปรดใช้เบราว์เซอร์มือถือ ,Alipay,Taobao,QQ และแอพอื่นๆเพื่อสแกนรหัสเพื่อดาวน์โหลดลูกค้ามือถือ',
	Downloadthepicture: 'ดาวน์โหลดภาพ',
	zwkf: 'รูปแบบนี้ยังไม่ถูกเปิดใช้งาน',
	bnsykg: "คุณไม่สามารถใช้เนื้อที่ได้",
	qsrmm: 'กรุณาใส่รหัสผ่าน 8-20 หลัก',
	qsryzh: 'โปรดป้อนชื่อบัญชีที่คุณต้องการกู้คืนรหัสผ่านเข้าสู่ระบบ',
	Existingaccount: 'บัญชีที่มีอยู่',
	Loginimmediately: 'เข้าสู่ระบบทันที',
	hqyzm: 'การรับรหัสยืนยัน',
	yzdshj: 'ยืนยันหมายเลขโทรศัพท์มือถือ',
	Resend: 'ส่งอีกครั้ง',
	Pleaseenteranewpassword: 'กรุณาใส่รหัสผ่านใหม่',
	qsrszzm: 'กรุณากรอกตัวเลข ตัวอักษร',
	Aboutus: 'เกี่ยวกับเรา',
	qsrzcxm: 'กรุณากรอกชื่อลงทะเบียน',
	aitask: 'งาน AI ของเทอร์มินัล',
	createGroup: '创建分组',
	广播用户权限: 'ออกอากาศสิทธิ์ผู้ใช้',
	超级管理员权限: 'สิทธิ์ของผู้ดูแลระบบขั้นสูง',	
	运维: 'การดำเนินงาน',
	超管: 'ผู้ดูแลระบบขั้นสูง',
	系统维护人员: 'บุคลากรซ่อมบำรุงระบบ',
	播音员: 'ผู้ประกาศ',
	广播用户权限: 'ออกอากาศสิทธิ์ผู้ใช้',
	aiWarning: 'AI预警',
	aiDevice: '监控设备',
	screenView: '分屏预览',
	aiMap: '电子地图',
	aiAlarm: '报警查询',
	Adddevice: 'เพิ่มอุปกรณ์',
	EquipmentNo: 'หมายเลขอุปกรณ์',
	channel: '通道',
	aiLinkage: '广播联动配置',
	operatorManage: '运营商管理',
	customManage: '客户管理',
	receivingManage: '接收终端管理',
	collectingManage: '采集设备管理',
	talkingManage: '对讲终端管理',
	smartScreenManage: '智慧屏管理',
	homeContent: '宣传文案',
	timingLive: '定时直播',
	Preview: '预览',
	create: '新建',
	helpTitle: '帮助文档',
	appSlideTitle: 'APP轮播文档',
	title: '标题',
	author: '作者',
	release: '发布',
	cover: '封面',
	abstract: '摘要',
	fams: '方案描述',
	modify: '修改',
	scheme: '方案',
	jrsd: '节日设定',
	holidayName: '节日名称',
	rwlb: '任务列表',
	broadcastlinkage: '广播联动配置',
	fullscreen: '全屏',
	mobileterminal: '移动端',
	Listeningbackup: '听力备份',
	yjgb: '应急广播',
	wjtsgb: '文件推送广播',
	dsbfgb: '定时播放广播',
	gisdtgb: 'GIS地图广播',
	tjyabj: '添加预案报警',
	yjbj: '应急报警',
	cybj: '常用报警',
	zdybj: '自定义报警',
	qxzyjbj: '请选择应急报警',
	qxztb: '请选择图标',
	Icon: '图标',
	zdytb: '自定义图标',
	xzwj: '选择文件',
	yajc: '预案简称',
	bdyjya: '绑定应急预案',
	qsryajc: '请输入预案简称',
	qxzyjya: '请选择应急预案',
	qsrgjz: '请输入关键字',
	tpcj: '图片裁剪',
	amplify: '放大',
	reduce: '缩小',
	leftrotate: '左旋转',
	rightrotate: '右旋转',
	jingjie: '警戒',
	fangdao: '防盗',
	hongzai: '洪灾',
	gongong: '公共卫生',
	tongyong: '通用图标',
	yjyglb: '应急预案列表',
	huozai: '火灾',
	dizhen: '地震',
	shusan: '疏散',
	fangkong: '防空',
	kongxi: '空袭',
	dizhenyanlian: '地震演练',
	longjuanfeng: '龙卷风',
	jiechu: '解除警报',
	help: '帮助',
	rmwl: '热门问题',
	linqi: '临期/已过期',
	scyabj: '删除预案报警',
	qdscya: '确定要删除此预案报警吗',
	zdzxs: '终端在线数',
	zdzs: '终端总数',
	qdqdya: '确定要启动此预案报警吗',
	xtts: '系统提示',
	gbcs: '广播次数',
	zwsj: '暂无数据',
	tpgsyq: '上传图片只能是JPG或PNG格式',
	loop: '循环',
	qsrnr: '请输入内容',
	qbzd: '全部终端',
	qxzbcyp: '请选择播出音频',
	qgxbcmb: '请勾选播出目标',
	qiege: '切歌',
	shangyiqu: '上一曲',
	xiayiqu: '下一曲',
	zanting: '暂停',
	qsrrwmc: '请输入任务名称',
	qsrxhcs: '请输入循环次数',
	qsryxj: '请输入优先级',
	qsryl: '请输入音量',
	qxzbcrq: '请选择播出日期',
	qgxbczq: '请勾选播出周期',
	qsrldz: '请输入流地址',
	qsrwz: '请输入文字',
	qxzcjsb: '请选择采集设备',
	qxzyytd: '请选择音源通道',
	szyd: '数字越大，优先播出',
	yxgq: '已选歌曲',
	gbmbqbzd: '广播目标为全部终端',
	fzxdzdlb: '分组下的终端列表',
	yytd: '音源通道',
	upan: 'U盘',
	none: 'None',
	ydjc: '移动检测',
	rqqy: '入侵区域',
	rxjc: '人形检测',
	tljc: '停留检测',
	zdtb: '自动停播',
	sdtb: '手动停播',
	qsrmc: '请输入名称',
	sxtj: '生效条件',
	ryzt: '任意状态',
	zxzt: '在线状态',
	cxsc: '持续时长',
	xgssfz: '修改所属分组',
	qxzbcsj: '请选择播出时间',
	jssjbnxykssj: '结束时间不能小于开始时间',
	bcscbnxyypsc: '播出时长不能小于音频时长',
	qdgbgb: '确定要关闭广播吗',
	canceled: '已取消',
	zdwjsl: '最多只能选择50个音频文件',
	sbcj: '设备采集',
	Hour: 'Hour',
	rwxq: '任务详情',
	gblb: '广播列表',
	bcz: '保存中',
	plsc: '批量删除',
	qdsczxrwm: '确定要删除这些任务吗',
	qxzyscdrw: '请选择要删除的任务',
	qdyscgbm: '确定要删除广播吗',
	qsrfamc: '请输入方案名称',
	qxxzfa: '请先选择方案',
	scfahscrw: '删除方案时会删除方案下的所有任务，确定要删除方案吗',
	sxsj: '生效时间',
	qdyscrwm: '确定要删除任务吗',
	qdyqdgbm: '确定要启动广播吗',
	bcmb: '播出目标',
	qidong: '启动',
	sftcdl: '是否退出登录',
	xxbcz: '消息不存在',
	ptjb: '普通级别',
	aqjb: '安全级别',
	mtwjzctips: '仅支持mp3/wav格式，上传单个文件大小不能超过{a}MB，请勿上传违法、违规、不健康的内容，否则您将承担必要的法律责任',
	mtwjzctips: '仅支持mp3/wav格式，请勿上传违法、违规、不健康的内容，否则您将承担必要的法律责任',
	qxmtlb: '请选择媒体类别',
	qxzmtjb: '请选择媒体级别',
	qscypwj: '请上传音频文件',
	share: '共享',
	scypz: '上传音频中',
	xiaofeng: '男声-小峰',
	xiaoyan: '女声-小燕',
	mypzcos: '没有配置腾讯cos信息',
	qxzyscdmtwj: '请选择要删除的媒体文件',
	qdysczxmtwjm: '确定要删除这些媒体文件吗',
	qdycmtwj: '确定移除',
	scdypwjgs: '上传的音频文件格式只能是 mp3/wav 格式',
	cfscwj: '该文件已存在，请勿重复上传文件',
	scddgwjdx: '上传的音频文件单个不能超过{a}MB',
	qdscmtwj: '你确定要删除 {a} 吗',
	aqez: '注：请勿上传违法、违规、不健康的内容，否则您将承担必要的法律责任',
	qsrwbmc: '请输入文本名称',
	qxzyscdwj: '请选择要删除的文件',
	qdysczxwjm: '确定要删除这些文件吗',
	nqdyscwbwjm: '你确定要删除文本文件吗',
	qxzyscdmtlb: '请选择要删除的媒体类别',
	xtmrlbbyxsc: '系统默认类别不允许删除',
	qrscmtlb: '你确定要删除媒体类别 {a} 吗',
	qxxzmtlbzjxxg: '请先选择媒体类别再进行修改',
	xtmrlbbyxxg: '系统默认类别不允许修改',
	lbmcbt: '类别名称必填',
	sftgsh: '是否通过审核',
	sfbtgsh: '是否不通过审核',
	mgchh: '注：每行一个词汇，使用回车键换行',
	qsrmgc: '请输入敏感词',
	nqdyscmgcm: '你确定要删除 {a} 敏感词吗',
	hjjg: '呼叫结果',
	hjcg: '呼叫成功',
	hjsb: '呼叫失败',
	hjflx: '呼叫方类型',
	hjf: '呼叫方',
	bhjflx: '被呼叫方类型',
	bhjf: '被呼叫方',
	hjsj: '呼叫时间',
	thsc: '通话时长',
	hjcs: '呼叫超时',
	dfjj: '对方拒绝',
	dfzm: '对方正忙',
	qxhj: '取消呼叫',
	wjt: '未接通',
	xfxhy: '消防信号源',
	xfxhysz: '消防信号源设置',
	SignalSourceDescription: '信号源描述',
	SignalSourceEquipment: '信号源设备',
	SignalNumber: '信号编号',
	PleaseSelectAChannel: '请选择通道',
	unexpired: '未到期',
	Expired: '已到期',
	Advent: '临期',
	periodOfValidity: '有效期',
	Map: '地图',
	DefaultVolume: '默认音量',
	BroadcastVolume: '广播音量',
	InstallationPosition: '安装位置',
	IntercomVolume: '对讲音量',
	TaskPriority: '任务优先级',
	FrequencySetting: '频率设置',
	Frequency: '频率',
	GSignal: '4G信号',
	qsrssgjc: '请输入搜索关键词',
	qsrwldz: '请输入物理地址',
	qsrzdmc: '请输入终端名称',
	qxztdbh: '请选择通道编号',
	qxzzt: '请选择状态',
	qsrdjyl: '请输入对讲音量',
	qxzrwyxj: '请选择任务优先级',
	UploadSuccessful: '上传成功',
	ReuploadImages: '点击后可重新上传图片',
	GoBack: '返回上一级',
	OptionalReceivingTerminal: '可选接收终端',
	OptionalReceivingDevice: '可选接收设备',
	SelectedReceivingTerminal: '已选接收终端',
	SelectedReceivingDevice: '已选接收设备',
	qsrfzmc: '请输入分组名称',
	qsrfzbh: '请输入分组编号',
	PleaseSelectTheReceivingTerminal: '请选择接收终端',
	PleaseSelectTheReceivingDevice: '请选择接收设备',
	nqdyscfzm: '你确定要删除分组{a}吗',
	SDcardCapacity: 'SD卡容量',
	SDcardStatus: 'SD卡状态',
	NetworkMode: '网络模式',
	Normal: '正常',
	Bbnormal: '异常',
	Unrecognized: '未识别',
	FormatSDCard: '格式化SD卡',
	Record: '记录',
	Wired: '有线',
	yunyingshang: '运营商',
	UsedTraffic: '已使用流量',
	DeviceLog: '设备日志',
	GetLog: '获取日志',
	GSignalRecord: '4G信号记录',
	SignalValue: '信号值',
	FormatSDcardConfirmation: '格式化SD卡将清空SD卡内的所有文件，确认要格式化SD卡吗',
	sending: '发送',
	RetransmissionCycle: '回传周期',
	Instruct: '指令',
	qsrhczq: '请输入回传周期',
	qsrsz: '请输入数字',
	qxzzl: '请选择指令',
	jiaoshi: '校时',
	tongbucanshu: '同步参数',
	jixubofang: '继续播放',
	chongqi: '重启',
	qingchu: '清除消防报警状态',
	nqdysczdm: '你确定要删除终端{a}吗',
	qxzyscdzd: '请选择要删除的终端',
	qdtsczxzdm: '确定要删除这些终端吗',
	Online: '上线',
	Setting: '设置',
	ServiceExtranetAddress: '服务外网地址',
	ServiceIntranetAddress: '服务内网地址',
	Port: '端口',
	tingji: '停机',
	fuji: '复机',
	qiangzhi: '强制复活',
	jingtai: '静态',
	simCardStatus: 'SIM卡状态',
	simCardControl: 'SIM卡控制',
	IPAddress: 'IP地址',
	ziwangyanma: '子网掩码',
	wangguan: '网关',
	MACAddress: 'MAC地址',
	qxzfwqx: '请选择服务期限',
	qsrfwwwdz: '请输入服务外网地址',
	qsrdk: '请输入端口',
	qsrfwnwdz: '请输入服务内网地址',
	qxzwlms: '请选择网络模式',
	qxzkzzl: '请选择控制指令',
	qxzysydh: '请选择要上移的行',
	qxzyxydh: '请选择要下移的行',
	dytbnbsy: '第一条不能被上移',
	zhytbnbxy: '最后一条不能被下移',
	nqdyscghjdxm: '你确定要删除该呼叫对象吗',
	nqdytjwhjdxm: '你确定要添加为呼叫对象吗',
	djyx: '对讲优先',
	gbyx: '广播优先',
	qdgbdgb: '你确定要关闭{a}的广播吗',
	ImportDevice: '导入设备',
	plsx: '批量审核',
	plck: '批量出库',
	ActiveState: '激活状态',
	Active: '已激活',
	NotActive: '未激活',
	nqdyscgsbm: '你确定要删除该设备吗',
	fwzt: '服务状态',
	yyyh: '运营用户',
	qsrybddyyyh: '请输入要绑定的运营用户',
	qsrsbmc: '请输入设备名称',
	qxzyscdsb: '请选择要删除的设备',
	qdysczxsbm: '确定要删除这些设备吗',
	qxzyshdsb: '请选择要审核的设备',
	scdwjstxt: '上传的文件只能是TXT格式',
	scdwjdx: '上传的文件不能大于1MB',
	qianjin: '前进',
	houtui: '后退',
	mygdsjl: '没有更多数据了',
	EquipmentOnlineRate: '设备在线率',
	zdfb: '终端分布',
	zdgbzt: '终端广播状态',
	Unit: '单位',
	qxzdgb: '圈选终端广播',
	yjhh: '一键喊话',
	qsrmccx: '请输入名称查询',
	TerminalView: '终端查看',
	Rectangle: '矩形',
	Circle: '圆形',
	polygon: '多边形',
	zzgb: '正在广播',
	Satellite: '卫星',
	zzhhgb: '正在喊话广播',
	qxzgbzd: '请选择广播终端',
	zcsr: '再次输入',
	jymmts: '安全性高的密码可以使账号更安全。建议您定期更换密码，密码为8-20位数字、字母或特殊符号三种组合。',
	bdyxts: '绑定邮箱可以用于找回登录密码',
	bdsjts: '绑定手机可以用于找回登录密码',
	djtpkgh: '点击图片可更换',
	ddxq: '订单详情',
	fukuanren: '付款人',
	jine: '金额',
	qsryx: '请输入邮箱',
	qsrzqdyxdz: '请输入正确的邮箱地址',
	Tall: '高',
	Centre: '中',
	Low: '低',
	lcsrdmmbyz: '两次输入的密码不一致',
	qsrzqdsjh: '请输入正确手机号',
	qxzxb: '请选择性别',
	qxzqy: '请选择区域',
	nqdyzxczhm: '你确定要注销此账号吗',
	zxzh: '注销账号',
	qgxjs: '请勾选角色',
	wenxintishi: '温馨提示',
	cjfzdwxts: '用户组创建者即为用户组管理人，加入用户组后，您的设备将共享给用户组内其他用户',
	cjfzdwxts2: '每个用户只能创建一个用户组，每个用户只能加入一个用户组',
	qdycyhm: '确定移除{a}吗',
	yyyhtjsb: '{a}该用户已有用户组了,添加失败',
	byxcftj: '不允许重复添加',
	byxycyhcyzs: '不允许移除用户成员自身',
	qsryhzmc: '请输入用户组名称',
	qsrhzzmsz: '请输入汉字、字母或者数字',
	yhzcybnwk: '用户组成员不能为空， 请添加用户组成员',
	nqdyscyhzm: '你确定要删除用户组吗',
	nqdytcyhzm: '你确定要退出用户组吗',
	xzxjqy: '新增下级区域',
	sjqybm: '上级区域编码',
	sjqymc: '上级区域名称',
	xzqybm: '新增区域编码',
	xzqymc: '新增区域名称',
	xzqyqc: '新增区域全称',
	qsrgdsz: '请输入${a}位数',
	qsrqybm: '请输入区域编码',
	qsrqymc: '请输入区域名称',
	jdsryw: '经度输入有误',
	wdsryw: '纬度输入有误',
	tjxgqy: '添加/修改区域',
	nqdyscqym: '你确定要删除区域吗',
	scqy: '删除区域',
	qsrjsmc: '请输入角色名称',
	qxzjsqx: '请选择角色权限',
	qsrms: '请输入描述',
	nqdyscjsm: '你确定要删除角色吗',
	scjs: '删除角色',
	nqdyscgjm: '你确定要删除固件吗',
	qxscgj: '请先上传固件',
	yylx: '应用类型',
	yycxsc: '应用程序上传',
	yylxpz: '应用类型配置',
	gxnr: '更新内容',
	scz: '上传中',
	sfqzsj: '是否强制升级',
	yycxwj: '应用程序文件',
	tzwj: '拖拽文件或是点击图片添加文件，开始上传',
	yylxmc: '应用类型名称',
	lxgjz: '类型关键字',
	qsrlxgjz: '请输入类型关键字',
	qsryylxmc: '请输入应用类型名称',
	qxzyylx: '请选择应用类型',
	qxzscwj: '请选择上传文件',
	scwjmcbgf: '上传文件名称不规范',
	nqdyscyym: '你确定要删除应用吗',
	nqdyscgyylxm: '你确定要删除该应用类型吗',
	xjwa: '新建文案',
	nqdysccwam: '你确定要删除此文案吗',
	tjcc: '推荐尺寸',
	qsrbt: '请输入标题',
	qsrwanr: '请输入文案内容',
	qscfmt: '请上传封面图',
	sucaiku: '素材库',
	bdsc: '本地上传',
	laiyuan: '来源',
	wllj: '网络链接',
	Upload: '上传',
	sctctips: '只能上传.jpg,.png,.mp3,.mp4,.flv文件，且不超过20m',
	Tag: '标签',
	crsc: '插入素材',
	fabuyu: '发布于',
	wzx: '未执行',
	yzx: '已执行',
	qxzx: '取消执行',
	qxzgjbb: '请选择固件版本',
	qgxsb: '请勾选设备',
	nqdyqxzxrwm: '你确定要取消执行升级任务吗',
	ptxx: '平台信息',
	dbzxj: '底部左下角',
	dbjz: '底部居中',
	dbyxj: '底部右下角',
	dibjz: '顶部居中',
	xswz: '显示位置',
	qxzxswz: '请选择显示位置',
	xxfb: '消息发布',
	MessageTitle: '消息标题',
	MessageContent: '消息内容',
	MessageObject: '消息对象',
	fbxx: '发布消息',
	fbTime: '发布时间',
	Object: '对象',
	qgxxdx: '请勾选消息对象',
	nqdyscxxm: '你确定要删除消息吗',
	tjlmtfw: '添加流媒体服务',
	xglmtfw: '修改流媒体服务',
	文件服务器: '文件服务器',
	turn服务器: 'turn服务器',
	kms服务器: 'kms服务器',
	业务服务器: '业务服务器',
	信令服务器: '信令服务器',
	mtfwlx: '媒体服务类型',
	sfqy: '是否启用',
	mtfwmc: '媒体服务名称',
	mtfwdz: '媒体服务地址',
	qsrmtfwmc: '请输入媒体服务名称',
	qsrmtfwdz: '请输入媒体服务地址',
	fwqcc: '服务器存储',
	coscc: '腾讯云对象存储',
	qsrcctmc: '请输入存储桶名称',
	qsrcctqy: '请输入存储桶区域',
	qsrid: '请输入id',
	qsrmy: '请输入密钥',
	dymc: '地域名称',
	dxyyid: '短信应用ID',
	dxqm: '短信签名',
	zhmmmbid: '找回密码模板ID',
	khzh: '客户账号',
	bdkh: '绑定客户',
	jcbd: '解除绑定',
	xgzhxx: '修改账号信息',
	nqdyjcbdczhm: '你确定要解除绑定此账号吗',
	Back: 'กลับ',
	Play: 'เล่น',
	jypfxgyhzncy: '将音频分享给用户组内成员',
	jsbfxgyhzncy: '将设备分享给用户组内成员',
	AddTask: '添加任务',
	EditScheme: '编辑方案',
	dlsx: '登录失效',
	xjxcxcz: '先进行查询操作',
	ExportDevice: '导出设备',
	Agree: '同意',
	Refuse: '拒绝',
	InviteToJoin: '邀请加入',
	InvitedPleaseWait: '已邀请，等待对方同意',
	PlayedTask: '已播任务',
	PlayAgain: '再播一次',
	SureToPlayAgain: '确定要再播一次吗',
	NumberOfTaskStarts: '任务启动次数',
	startInterval: '启动间隔',
	minute: '分钟',
	BadRequest: '错误请求',
	LoginAgain: '未授权，请重新登录',
	NoAccess: '拒绝访问',
	NotFound: '请求错误,未找到该资源',
	RequestMethodNotAllowed: '请求方法未允许',
	RequestTimeout: '请求超时',
	ServerSideError: '服务器端出错',
	NetworkNotImplemented: '网络未实现',
	NetworkError: '网络错误',
	ServiceUnavailable: '服务不可用',
	NetworkTimeout: '网络超时',
	DoesNotSupport: 'http版本不支持该请求',
	ConnectionError: '连接错误',
	RequestCanceled: '请求已取消',
	ConnectionFailed: '连接到服务器失败',
	Loading: '加载中',
	LoginFirst: '请先登录',
	DefaultLanguage: '默认语言',
	LoggingOut: '退出登录中',
	SelectDate: '选择日期',
	PleaseSelectDate: '请选择日期',
	qiyonghou: '启用后，定时任务在节日日期不启动',
	PleaseEnterHolidayName: '请输入节日名称',
	AreYouSureToDeleteHoliday: '确定要删除该节日吗',
	AdvancedSetting: '高级设置',
	lianxiguanliyuan: '您没有当前操作的权限,请联系管理员',
	APPDownload: '安卓APP下载',
	Shares: '分享',
	MenuLoading: '菜单加载中',
	CurrentVersion: '当前版本',
	ServiceMonitoring: '服务监控',
	ServerMonitor: '服务器监控',
	ServeName: '服务名称',
	RunningState: '运行状态',
	NormalOperation: '运行正常',
	cpuper: 'CPU使用率',
	memoryper: '内存使用率',
	diskper: '磁盘使用率',
	bandwidthper: '宽带使用',
	Usage: '使用',
	OutputBandwidth: '出带宽',
	IncomingBandwidth: '入带宽',
	DisplayInterval: '显示间隔',
	DeleteCopy: '删除文案',
	audioRecord: '音频录制',
	RecordingAudio: '录制音频',
	SoundRecording: '录音',
	Microphone: '麦克风',
	StartRecording: '开始录制',
	StopRecording: '停止录制',
	Recording: '正在录制',
	RecordUpload: '录制上传',
	ReRecord: '重新录制',
	ConfirmUpload: '确认上传',
	NotSupported: '暂不支持',
	NotSupportedForDisplayingContent: '暂不支持显示内容',
	RealTime: '实时',
	Nearly24hours: '近24小时',
	InThePastThreeDays: '近三天',
	Everyday: '每天',
	Mondays: '每周一',
	FirstFayOfEachMonth: '每月一日',
	BackupTime: '备份时间',
	BackedUpTimes: '已备份次数',
	BackupObject: '备份对象',
	Timing: '选择时间',
	Database: '数据库',
	PleaseSelectTime: '请选择时间',
	CreateScheduledBbackupTask: '创建定时备份任务',
	BackupNow: '立即备份',
	PleaseSelectBackupObject: '请选择备份对象',
	AreYouSureToDeleteTheBackupTask: '你确定要删除备份任务吗',
	BackupFiles: '备份文件',
	Size: '大小',
	AreYouSureToDeleteTheBackupFiles: '你确定要删除备份文件吗',
	RecordNeedHttps: '录制功能需要以https方式访问',
	Export: '导出',
	timedCalibration: '全网定时校时',
	Horn: '喇叭',
	LoadExternalNetworkResources: '是否加载外网资源',
	HTTPSAccessAddress: 'https访问地址',
	ConstantVoltageSwitching: '定压切换',
	ReplicationTask: '复制任务',
	SchemeBelongs: '所属方案',
	OpenRegistration: '开放注册',
	ssylbfsc: '实时预览播放时长',
	SavePreview: '保存预览记录',
	bcscdyypsc: '建议播出时长大于音频时长',
	RefreshDeviceStatus: '刷新设备状态',
	BroadcastNeedHttps: '喊话广播功能',
	NeedHttps: '需要以https方式访问',
	cjqgb: '采集器广播',
	transcodeStatus: '转码状态',
	untranscode: '未转码',
	transcoded: '转码完成',
	transcodefail: '转码失败',
	transcodewait: '等待转码',
	transcodeing: '正在转码',
	UserAgreement: 'ข้อตกลงผู้ใช้',
	TriggerChannel: 'ช่องทริกเกอร์',
	TaskType: '任务类型',
	HongWaiChuFa: '红外触发',
	XiaoFangChuFa: '消防触发',
	Terminal: "终端",
	SmartHost: '智能主机',
	PowerSupplyStatus: '供电状态',
	NormalMainsPowerSupply: '市电正常',
	UtilityFailure: '市电停电',
	PowerAmplifierPartitionNotes: '功放分区备注',
	NoteName: '备注名称',
	Liaison: '联系人',
	Telephone: '联系电话',
	PowerAmplifierOn: '开启功放电源',
	PowerAmplifierOff: '关闭功放电源',
	TurnOnTheAmplifierInAdvance: '提前开启功放',
	PowerAmplifierPartition: '功放分区',
	SelectPowerAmplifierPartition: '请选择功放分区',
	AnjianRenwu: '音乐广播转终端按键任务',
	QuanQuKaiQi: '功放全区开启',
	QiJinYongSheBei: '启/禁用设备',
	TriggerMode: '触发模式',
	ImportTip1: '拖到文件到此处，或点击上传',
	DownloadTemplate: '下载模板',
	InvalidFileSuffix: '文件格式仅支持：{0}',
	ContentQuery: '内容查询',
	Played: '已播',
	Unplayed: '未播',
	DeleteShortcutMenu: '删除快捷菜单',
	SureToDeleteShortcutMenu: '确定要删除此快捷菜单吗',
	AddShortcutMenu: '删除快捷菜单',
	ShortcutMenuList: '快捷菜单列表',
	UploadFileSizeLimit: '上传文件大小限制',
	BroadcastDefaultParameters: '广播默认参数',
	BroadcastingTargetType: '默认广播目标类型',
	DefaultbroadcastMode: '默认播出方式',
	Modulation: '音量调节',
	NewSchemeName: '新方案名称',
	TodayBroadcastPlan: '今日播出计划',
	BatchModifyTasks: '批量修改任务',
	BatchModify: '批量修改',
	NumberOfSelectedTasks: '已选任务个数',
	PleaseCheckTheParameters: '请勾选需要修改的参数',
	NoPermissionTemporarily: '暂无权限',
	bcscbnxyypsc2: '播出时长不能小于音频时长*播出次数',
	CurrentlyDoesNotSupportDisplayingContent: '暂不支持显示内容',
	AreYouSureToSaveIfThereIsAConflict: '以下任务执行时间有冲突，确定要保存吗',
    ...thLocale
}