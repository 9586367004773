// 登录路由模块
import Content from '@/components/Content/Content'
import Login from '@/views/login/Login'
import Agree from '@/views/login/Agreement'
export default [
    {
        path: '',
        redirect: '/index/login',
    },
    {
        path: '/index',
        component: Content,
        redirect: '/index/login',
        children: [
            {
                name: 'login',
                path: 'login/:lang?/:account?/:password?/:token?',
                component: Login,
                meta: {
                    title: 'login',
                    // 不显示左侧菜单,导航条, 面包屑
                    showBread: false,
                    showNav: false,
                    showLeftMenu: false,
                    module: 'login'
                }
            },
            {
                name: 'autologin',
                path: 'autologin/:account?/:password?',
                component: () => import(/*webpackChunkName: 'download'*/'@/views/login/autoLogin'),
				props: true,
                meta: {
                    title: 'login',
                    // 不显示左侧菜单,导航条, 面包屑
                    showBread: false,
                    showNav: false,
                    showLeftMenu: false,
                    module: 'login'
                }
            },
            {
                name: 'downloadCenter',
                path: 'download',
                component: () => import(/*webpackChunkName: 'download'*/'@/views/login/Download'),
                meta: {
                    title: 'downloadCenter',
                    showBread: false,
                    showNav: false,
                    showLeftMenu: false,
                    module: 'login'
                }
            },
            {
                name: 'downloaddetail',
                path: 'downloaddetail/:code',
                component: () => import(/*webpackChunkName: 'download'*/'@/views/login/downloadDetail'),
				props: true,
                meta: {
                    title: 'downloaddetail',
                    showBread: false,
                    showNav: false,
                    showLeftMenu: false,
                    module: 'login'
                }
            },
            {
                name: 'agreement',
                path: 'agreement',
                component: Agree,
                meta: {
                    title: 'agreement',
                    showBread: false,
                    showNav: false,
                    showLeftMenu: false,
                    module: 'login'
                }
            },
            {
                name: 'commonProblem',
                path: 'commonproblem',
                component: () => import(/*webpackChunkName: 'commonproblem'*/'@/views/login/CommonProblem'),
                meta: {
                    title: 'commonProblem',
                    showBread: false,
                    showNav: false,
                    showLeftMenu: false,
                    module: 'login'
                }
            },
            {
                name: 'findPassword',
                path: 'findpassword',
                component: () => import(/*webpackChunkName: 'findpassword'*/'@/views/login/FindPassword'),
                meta: {
                    title: 'findPassword',
                    showBread: false,
                    showNav: false,
                    showLeftMenu: false,
                    module: 'login'
                }
            },
            {
                name: 'helpCenter',
                path: 'helpcenter',
                component: () => import(/*webpackChunkName: 'findpassword'*/'@/views/login/helpCenter'),
                meta: {
                    title: 'commonProblem',
                    showBread: false,
                    showNav: false,
                    showLeftMenu: false,
                    module: 'login'
                }
            },
        ]
    },
	{
	    name: 'helpdetail',
	    path: '/helpdetail/:id',
	    component: () => import(/*webpackChunkName: 'findpassword'*/'@/views/login/helpDetail'),
		props: true,
	    meta: {
	        title: 'commonProblem',
	        showBread: false,
	        showNav: false,
	        showLeftMenu: false,
	        module: 'login'
	    }
	}
]