// 保存需要取消请求对象 防止用户疯狂点击 造成服务器压力大
export const cancelBus = {
    cancelMediaList: null,  //取消媒体文件列表
    cancelLogList: null,    //取消请求操作日志列表
    cancelAuditList: null,  //取消请求媒体审核列表
    cancelRoleList: null,   //取消请求角色列表
    cancelWordsList: null,  //取消请求敏感词列表
    cancelSpeechList: null, //取消请求媒体文件列表
    cancelUserList: null,   //取消请求用户列表
    cancelAreaList: null,   //取消请求区域列表
    cancelWareList: null,   //取消请求设备列表
}