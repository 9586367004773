<template>
	<div class="login-section">
		<div class="login flex jsce alce">
			<el-image class="bg-pic" :src="require('../../assets/img/login/bg.jpg')" fit="cover"></el-image>
			<section class="login-box flex" :class="[{en: $i18n.locale == 'en'},{little: $t('smartCloudBroadcastingSystem').length >= 20}]">
				<div class="login-pic">
					<img class="pic" src="../../assets/img/login/login.png" alt="">
					<img class="logo" :src="platformInfo.logo" alt="" v-if="platformInfo.logo">
				</div>
				<template v-if="templateCur < 2">				
					<div class="login-info flex jsce">
						<!-- 标题 -->
						<h1 class="title">
							<span :class="{little: $t('smartCloudBroadcastingSystem').length >= 20}" v-if="platformInfo.systemName == '智慧云广播平台'">{{$t('smartCloudBroadcastingSystem')}}</span>
							<span v-else>{{platformInfo.systemName}}</span>
						</h1>
						<!-- 登录 -->
						<el-form key="loginForm" v-if="templateCur == 0" :model="loginData" :rules="loginRules" ref="loginForm" class="login-form" hide-required-asterisk>
							<!-- 账号 -->
							<el-form-item prop="account">
								<el-input v-model="loginData.account" :placeholder="$t('pleaseEnterTheAccountNumber')" autocomplete="new-account"><i slot="prefix" class="iconfont icon-a-39"></i></el-input>
							</el-form-item>
							<!-- 密码 -->
							<el-form-item prop="password" class="item-password">
								<el-input :type="showpassword ? 'text' : 'password'" v-model="loginData.password" :placeholder="$t('Pleaseinputapassword')" autocomplete="new-password"><i slot="prefix" class="iconfont icon-a-42"></i></el-input>
								<span class="showpassword iconfont" :class="showpassword ? 'icon-a-40' : 'icon-a-41'" @click="showpassword = !showpassword"></span>
							</el-form-item>
							<!-- 验证码 -->
							<el-form-item prop="code">
								<section class="flex spbe">
									<el-input class="code-input flex-item" v-model="loginData.code" :placeholder="$t('Pleaseentertheverificationcode')"></el-input>
									<div class="right-code" @click="loginGetCode">
										<div v-loading="codeLoading" element-loading-spinner="el-icon-loading"><img class="code-img" :src="loginData.img" :alt="$t('verificationCode')" /></div>
										<!-- <i @click="loginGetCode" class="el-icon-refresh green ml10"></i> -->
									</div>
								</section>
							</el-form-item>
							<!-- 登录按钮 -->
							<el-button class="w100 submit-btn bluebtn" type="primary" @click="loginSubmitForm('loginForm')" :disabled="!loginData.account || !loginData.password || !loginData.code">
								{{$t('secureLogin')}}
							</el-button>
						</el-form>
						<!-- 注册 -->
						<el-form key="registerForm" v-else-if="templateCur == 1" :model="registerData" :rules="registerRules" ref="registerForm" class="login-form register-form" hide-required-asterisk>
							<!-- 注册账号 -->
							<el-form-item prop="account">
								<el-input v-model="registerData.account" :placeholder="`${$t('pleaseEnterTheAccountNumber')}`" maxlength="20" show-word-limit><i slot="prefix" class="iconfont icon-a-44"></i></el-input>
							</el-form-item>
							<!-- 注册密码 -->
							<el-form-item prop="password" class="item-password">
								<el-input :type="showpassword ? 'text' : 'password'" maxlength="20" v-model="registerData.password" :placeholder="$t('Pleaseinputapassword')" autocomplete="new-password"><i slot="prefix" class="iconfont icon-a-42"></i></el-input>
								<span class="showpassword iconfont" :class="showpassword ? 'icon-a-40' : 'icon-a-41'" @click="showpassword = !showpassword"></span>
							</el-form-item>
							<!-- 注册名字 -->
							<el-form-item prop="name">
								<el-input v-model="registerData.name" maxlength="20" show-word-limit :placeholder="$t('qsrzcxm')"><i slot="prefix" class="iconfont icon-a-39"></i></el-input>
							</el-form-item>
							<!-- 服务协议 -->
							<el-form-item prop="agreeChecked" class="agree-box">
								<el-checkbox class="check-box" v-model="registerData.agreeChecked">{{$t('zcjbsty')}}{{platformInfo.systemName == '智慧云广播平台' ? $t('smartCloudBroadcastingSystem') : platformInfo.systemName}}<el-link class="aview" @click="$router.push('/index/agreement')" type="primary" :underline="false">{{$t('Theserviceagreement')}}</el-link><em class="em">、</em><el-link class="aview" @click="$router.push('/index/agreement')" type="primary" :underline="false">{{$t('Privacypolicy')}}</el-link><em class="em">、</em><el-link class="aview" @click="$router.push('/index/agreement')" type="primary" :underline="false">{{$t('Legalnotices')}}</el-link>{{$t('ji')}}<el-link class="aview" @click="$router.push('/index/agreement')" type="primary" :underline="false">{{$t('Clientserviceprotocol')}}</el-link>
								</el-checkbox>
							</el-form-item>
							<!-- 注册按钮 -->
							<el-button class="w100 submit-btn bluebtn" type="primary" @click="registerSubmitForm('registerForm')" :disabled="!registerData.account || !registerData.password || !registerData.name || !registerData.agreeChecked ">
								{{$t('register')}}
							</el-button>
						</el-form>
						<span class="hide">{{platformInfo}}</span>
						<section class="footer">
							<!-- 免费注册 platformInfo -->
							<template v-if="platformInfo.openRegistration == '1'">
								<el-link v-if="templateCur == 0" @click="loginAndRegisterClick('register')" type="primary" :underline="false">{{$t('freeRegistration')}}</el-link>
								<!-- 返回登录 -->
								<el-link v-else @click="loginAndRegisterClick('login')" type="primary" :underline="false">{{$t('returnToLogin')}}</el-link>
							</template>
							<!-- 找回密码 -->
							<el-link @click="$router.push('/index/findpassword')" type="primary" :underline="false">{{$t('findPassword')}}</el-link>
							<!-- 常见问题 -->
							<el-link @click="toHelpCenter" type="primary">{{$t('commonProblem')}}</el-link>
							<!-- <el-link @click="notOpen" type="primary" :underline="false">{{$t('commonProblem')}}</el-link> -->
							<!-- <el-link @click="notOpen" type="primary">{{$t('findPassword')}}</el-link> -->
							<!-- 下载中心 -->
							<el-link @click="downloadCenterVisble = true" type="primary" :underline="false">{{$t('downloadCenter')}}</el-link>
						</section>
					</div>
					<div class="flex qrcode" :class="{en: $i18n.locale == 'en'}">
						<div>
							<!-- <el-button class="fwdz-btn blue1 mart1" @click="$store.commit('publicOptions/SAVE_CODEVISBLE', true)">服务器地址</el-button> -->
							<el-button class="fwdz-btn blue1 mart1" @click="templateCur = 2">{{$t('ServerAddress')}}</el-button>
							<el-dropdown class="dropdown fwdz-btn blue1 mart1" trigger="click" @command="changeLangClick" v-if="true">
							  <span class="el-dropdown-link">
								{{$t('switchLanguage')}}
							    <i class="el-icon-arrow-down el-icon--right"></i>
							  </span>
							  <el-dropdown-menu slot="dropdown">
								<el-dropdown-item :command="item.value" v-for="(item, index) in languageList" :key="index">{{item.name}}</el-dropdown-item>
							    <!-- <el-dropdown-item command="zh">简体中文</el-dropdown-item>
							    <el-dropdown-item command="en">English</el-dropdown-item>
							    <el-dropdown-item command="th">ภาษาไทย</el-dropdown-item>
								<el-dropdown-item command="vi">Tiếng Việt</el-dropdown-item> -->
							  </el-dropdown-menu>
							</el-dropdown>
						</div>
						<div class="qrcode-right flex">
							<el-button class="fwdz-btn blue2 bor4 barrow" @click="notOpen">{{$t('smdl')}}</el-button>
							<img class="pic" src="../../assets/img/login/img2.png" alt="" @click="notOpen">
						</div>
					</div>
				</template>
				<!-- 服务器地址 -->
				<div class="login-info flex jsce" v-if="templateCur == 2">
					<div class="login-code">
						<div class="other-title flex spbe alce">
							<p class="ptitle">{{$t('ServerAddress')}}</p>
							<el-button class="fwdz-btn blue1 down-btn" @click="templateCur = 0">{{$t('returnToLogin')}}</el-button>
						</div>
						<div class="code-image-box">
							<codeForm ref="codeform" :size="codewidth"></codeForm>
						</div>
						<div class="back-btn-box">
							<el-button class="submit-btn bluebtn" type="primary" @click="$refs.codeform.toShare()"><i class="iconfont icon-a-43"></i>{{$t('Downloadthepicture')}}</el-button>
						</div>
					</div>
				</div>
			</section>
		</div>
		<FooterForm></FooterForm>
		<!-- 下载中心弹窗 -->
		<el-dialog class="download-dialog" :visible.sync="downloadCenterVisble" center width="49%">
			<downloadCenter></downloadCenter>
		</el-dialog>
	</div>
</template>

<script>
	import { Login, getCode } from "@/request/api/auth"
	import { userRegister } from "@/request/api/user"
	import { localSet, localGet } from "@/configs/storage"
	import md5 from "js-md5"
	import { mapState } from 'vuex'
	import { showNotOpen } from '@/mixin'
	import codeForm from "@/components/codeForm/codeForm"
	import downloadCenter from "@/components/downloadCenter/downloadCenter"
	import { accountValid, validPassword } from '@/configs/validator'
	import FooterForm from "@/components/FooterForm/FooterForm"
	import { menuList } from '@/request/api/role'
	
	export default {
		name: "Login",
		data() {
			const isAgree = (rule, value, callback) => {
				if (!value) {
					callback(new Error(this.$t('gxxy')));
				} else {
					callback();
				}
			}
			return {
				lang: localGet('lang'),
				languageList: this.$store.state.publicOptions.languageList,
				codeLoading: false,
				isActive: true, //true 登录 false注册
				registerData: { //注册表单
					account: '',
					password: '',
					name: '',
					agreeChecked: false,
				},
				registerRules: { //注册表单验证
					account: [{
						validator: (rule, value, callback) => {
							accountValid(value, callback)
						},
						trigger: ['blur', 'change']
					}],
					password: [{
						validator: (rule, value, callback) => {
							validPassword(value, callback)
						},
						trigger: ['blur', 'change']
					}],
					name: [{
						required: true,
						message: this.$t('qsrzcxm'),
						trigger: ['blur', 'change']
					}],
					agreeChecked: [{
						validator: isAgree,
						trigger: 'change'
					}],
				},
				loginData: { //登录表单
					account: '',
					password: '',
					loginType: '',
					code: '',
					uuid: '',
					img: '',
				},
				loginRules: { //登录表单验证
					account: [{
						required: true,
						message: this.$t('pleaseEnterTheAccountNumber'),
						trigger: ['blur', 'change']
					}],
					password: [{
						required: true,
						message: this.$t('Pleaseinputapassword'),
						trigger: ['blur', 'change']
					}],
					code: [{
						required: true,
						message: this.$t('Pleaseentertheverificationcode'),
						trigger: ['blur', 'change']
					}],
				},
				showpassword: false, //密码是否显示
				agreeChecked: false, //同意协议
				isYunshiPlay: false,
				wanganbei: '',
				templateCur: 0, //0登录界面，1注册界面，2服务器地址界面
				codewidth: document.body.scrollWidth <= 1440 ? 200 : 300,
				downloadCenterVisble: false, //显示下载中心弹窗
			}
		},
		mixins: [showNotOpen],
		components: {
			codeForm,
			downloadCenter,
			FooterForm,
		},
		watch: {
			templateCur(val){
				if(val == 0){
					this.loginGetCode()
				}
			}
		},
		computed: {
			...mapState('publicOptions', ['platformInfo', 'menuList']),
		},
		methods: {
			toHelpCenter(){
				const { origin } = location
				window.open(`${origin}/#/index/helpcenter`)
			},
			//切换语言
			changeLangClick(lang) {
				if (lang === this.$i18n.locale) return
				this.$i18n.locale = lang
				localSet('lang', lang)
				this.lang = lang
				document.title = this.$t(this.$route.meta.title)
			},
			//点击关于我们
			aboutSubmit() {
				this.$elconfirm('当前版本：V1.0.0.101406').then(() => {}).catch(() => {})
			},
			agreeChange(e) {
				console.log(22, e)
			},
			// 注册 / 登录切换
			loginAndRegisterClick(action) {
				switch (action) {
					case 'register': //注册
						this.templateCur = 1
						break
					case 'login': //登录
						this.templateCur = 0
						break
				}
			},
			// 回车登录 / 注册
			keyUpLoginSubmit({
				keyCode
			}) {
				if (keyCode === 13) {
					if (this.isActive) {
						this.loginSubmitForm('loginForm')
					} else {
						this.registerSubmitForm('registerForm')
					}
				}
			},
			// 移除回车登录 / 注册键盘事件
			removeKeyUpLogin() {
				document.removeEventListener('keyup', this.keyUpLoginSubmit)
			},
			// 登录表单提交
			loginSubmitForm(formName) {
				this.$refs[formName]?.validate(async valid => {
					if (!valid) return
					try {
						if (navigator.userAgent.match(
								/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
								)) {
							//移动端
							this.loginData.loginType = 'mobile'
						} else {
							//pc端
							this.loginData.loginType = 'web'
						}
						const loginUserForm = {
							...this.loginData
						}
						loginUserForm.password = md5(loginUserForm.password) //使用MD5加密密码
						const {
							code,
							message,
							data: {
								token,
								avatar,
								userId
							}
						} = await Login({
							...loginUserForm
						})
						if (code === 200) {
							//登录成功
							const {
								account,
								password,
							} = this.loginData
							localSet("token", token)
							this.$store.commit("publicOptions/SAVE_TOKEN", token)
							localSet("account", account)
							this.$store.commit("publicOptions/SAVE_ACCOUNT", account)
							localSet("password", password)
							this.$store.commit("publicOptions/SAVE_PASSWORD", password)
							localSet("userId", userId)
							this.$store.commit("publicOptions/SAVE_USERID", userId)
							if (avatar) {
								localSet("avatar", avatar)
								this.$store.commit("publicOptions/SAVE_AVATAR", avatar)
							}
							this.$message.success(message)
							this.removeKeyUpLogin()
							this.getMenuList()							
						} else {
							this.loginData.code = ''
						}
					} catch (error) {
						this.loginData.code = ''
						this.$nextTick(() => {
							this.$refs[formName]?.clearValidate()
						})
						this.loginGetCode()
						error?.status && console.error(error?.status)
					}
				})
			},
			async getMenuList(){
				try {
				  const { code, data } = await menuList()
					if(code == 200){
						this.$store.commit('publicOptions/SAVE_MENULLIST', data)
						for(let key in data){
							if(data[key].url){
								this.$router.replace(data[key].path)
								return false
							}
						}
					}			
				} catch (error) {
					error && console.error(error)
				}
			},
			// 注册表单提交
			registerSubmitForm(formName) {
				this.$refs[formName]?.validate(async valid => {
					if (!valid) return
					try {
						const registerUserForm = {
							...this.registerData
						}
						registerUserForm.password = md5(registerUserForm.password) //使用MD5加密密码
						const {
							code,
							message
						} = await userRegister({
							...registerUserForm
						})
						if (code === 200) {
							this.$message.success(message)
							this.loginAndRegisterClick('login')
						}
					} catch (error) {
						error?.status && console.error(error?.status)
					}
				})
			},
			// 验证码
			async loginGetCode() {
				try {
					this.codeLoading = true
					const {
						data: {
							uuid,
							img
						}
					} = await getCode()
					this.loginData.uuid = uuid
					this.loginData.img = `data:image/jpeg;base64,${img}`
					setTimeout(() => {
						this.codeLoading = false
					}, 300)
				} catch (error) {
					setTimeout(() => {
						this.codeLoading = false
					}, 300)
					error && console.error(error)
				}
			},
			// 页面初始化
			init() {
				this.loginData.code = ''
				this.$nextTick(() => {
					this.$refs.loginForm.clearValidate('code')
				})
				// if (localGet("token")) return this.$router.push("/home/audiohome")
				this.loginGetCode()
				document.addEventListener('keyup', this.keyUpLoginSubmit)
				if (localGet("account")) this.loginData.account = localGet("account")
				if (localGet("password")) this.loginData.password = localGet("password")
			}
		},
		mounted() {
			this.init()
			// window.location.reload()
		},
		beforeDestroy() {
			this.removeKeyUpLogin()
		},
		created() {
			this.isYunshiPlay = window.location.hostname.indexOf('yunshiplay') > -1
			this.wanganbei = this.isYunshiPlay ? 35020602002469 : 35020602002542
		}
	}
</script>

<style lang="less" scoped>
	@import '@/assets/css/less/login.less';
</style>
