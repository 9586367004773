import axios from 'axios'
import { secondaryPackagingRequest } from '@/request'
import { cancelBus } from '@/configs'
//获取媒体类别(列表)
export const mediaTypeList = params => secondaryPackagingRequest({
    url: '/sm/audioMedia/mediaTypeList',
    params,
    isLoading: false,
    needToken: true
})
//获取媒体类别(返回树型)
export const mediaTypeTree = () => secondaryPackagingRequest({
    url: '/sm/audioMedia/mediaTypeTree',
    isLoading: false,
    needToken: true
})
//新增媒体类别
export const saveType = data => secondaryPackagingRequest({
    url: '/sm/audioMedia/saveType',
    method: 'POST',
    headers: {
        'Content-Type': 'application/json'
    },
    data,
    isLoading: false,
    needToken: true
})
//修改媒体类别
export const updateType = data => secondaryPackagingRequest({
    url: '/sm/audioMedia/updateType',
    method: 'POST',
    headers: {
        'Content-Type': 'application/json'
    },
    data,
    isLoading: false,
    needToken: true
})
//删除媒体类别
export const deleteType = params => secondaryPackagingRequest({
    url: '/sm/audioMedia/deleteType',
    method: 'POST',
    params,
    isLoading: false,
    needToken: true
})
//获取媒体文件列表
export const mediaList = params => secondaryPackagingRequest({
    url: '/sm/audioMedia/list',
    params,
    isLoading: false,
    needToken: true,
    cancelToken: new axios.CancelToken(function executor(c) {
        cancelBus.cancelMediaList = c
    })
})
//获取文本文件信息
export const txtInfo = params => secondaryPackagingRequest({
    url: '/sm/audioMedia/txtInfo',
    params,
    isLoading: false,
    needToken: true
})
//删除媒体文件
export const deleteFile = params => secondaryPackagingRequest({
    url: '/sm/audioMedia/delete',
    method: 'POST',
    params,
    isLoading: false,
    needToken: true
})
//上传音频
export const uploadMedia = (data, onUploadProgress) => secondaryPackagingRequest({
    url: '/sm/audioMedia/uploadMedia',
    method: 'POST',
    headers: {
        'Content-Type': 'multipart/form-data'
    },
    data,
    timeout: 200000,
    isLoading: false,
    needToken: true,
    onUploadProgress,
    // loadingText: '上传音频中...'
})
//获取媒体文件(根据媒体类别树形展示)
export const mediaFileTree = params => secondaryPackagingRequest({
    url: '/sm/audioMedia/mediaFileTree',
    params,
    isLoading: false,
    needToken: true
})
//新增媒体文件(腾讯cos上传后请求的接口)
export const newUploadMedia = (data, onUploadProgress) => secondaryPackagingRequest({
    url: '/sm/audioMedia/save',
    method: 'POST',
    headers: {
        'Content-Type': 'application/json'
    },
    data,
    timeout: 200000,
    isLoading: false,
    needToken: true,
})
//批量删除媒体文件
export const batchDelete = data => secondaryPackagingRequest({
    url: '/sm/audioMedia/batchDelete',
    method: 'POST',
    headers: {
        'Content-Type': 'application/json'
    },
    data,
    isLoading: false,
    needToken: true
})
//文件校验
export const checkFiles = data => secondaryPackagingRequest({
    url: '/sm/audioMedia/checkFile',
    method: 'POST',
    headers: {
        'Content-Type': 'application/json'
    },
    data,
    isLoading: false,
    needToken: true
})
//修改共享状态
export const shareStatus = params => secondaryPackagingRequest({
    url: '/sm/audioMedia/shareStatus',
    method: 'POST',
    headers: {
        'Content-Type': 'application/json'
    },
    params,
    isLoading: false,
    needToken: true
})
//获取音频录制列表
export const audioRecordList = params => secondaryPackagingRequest({
    url: '/sm/audioRecord/list',
	params,
    isLoading: false,
    needToken: true
})