//中文
import zh from "./file/zh"
// 英文
import en from "./file/en"
// 泰文
import th from "./file/th"
// 越南
import vi from "./file/vi"

export default {
    zh,
    en,
    th,
	vi
}