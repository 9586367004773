import { Message } from 'element-ui'
import { mapState } from 'vuex'
import i18n from '@/i18n'
import { signalRecord, commandControl, } from '@/request/api/warehousing'
import { useEventSource, closeEventSource } from '@/eventSource'
import { cosTempToken } from '@/request/api/cos'
import { uploadMedia, newUploadMedia } from '@/request/api/audioMedia'

// 分页参数配置
export const pageOptions = {
    data() {
        return {
            pageNum: 1,
            pageTotal: 0,
        }
    },
	computed: {
		...mapState('publicOptions', ['pageSize']),
	}
}

// 监听i18n变化，让用到echarts的页面重载
export const watchI18n = {
    computed: {
        locale() {
            return this.$i18n.locale
        }
    },
    watch: {
        locale() {
            // 切换语言时, 用到echarts的页面需要做局部刷新才能切换图表中的文字
            this.$bus.$emit('switchLang', true)
        }
    }
}
// 生成二维码配置
export const createCode = {
    data() {
        return {
			codeVisble: false,
        }
    },
	methods: {
		showCode(){
			this.codeVisble = true
		},
	},
	created(){
	}
}
// 提示暂未开放
export const showNotOpen = {
	methods: {
		notOpen(){
			Message.error(i18n.t('zwkf'))
		},
	},
}
// 时间格式/秒格式互相转换
export const timeSwitch = {
	methods: {
		//时间格式数值转化为秒
		getTime(val){
			let item = val.split(':')
			let count = parseInt(item[0]) * 3600 + parseInt(item[1]) * 60 + parseInt(item[2])
			return count
		},
		//秒转化为时分秒格式
		timeFromat(val){
			if(!val){
				return ''
			}else{
				let hour = this.numFromat(parseInt(val / 3600))
				let hourYu = this.numFromat(val % 3600)
				let min = this.numFromat(parseInt(hourYu / 60))
				let sec = this.numFromat(hourYu % 60)
				return hour + ':' + min + ':' + sec
			}
		},
		numFromat(val){
			return val >= 10 ? val : '0'+ val
		},
	},
}

// 日期格式化
export const formatDates = {
	methods: {
		formatDate(cellValue, format = 'yyyy-MM-dd HH:mm:ss'){
			if (cellValue == null || cellValue == "") return "";
			var date = cellValue
			let o = {
			    "M+" : date.getMonth()+1,                 //月份
			    "d+" : date.getDate(),                    //日
			    "H+" : date.getHours(),                   //小时
			    "m+" : date.getMinutes(),                 //分
			    "s+" : date.getSeconds(),                 //秒
			    "q+" : Math.floor((date.getMonth()+3)/3), //季度
			    "S"  : date.getMilliseconds()             //毫秒
			  };
			  if(/(y+)/.test(format)) {
			    format=format.replace(RegExp.$1, (date.getFullYear()+"").substr(4 - RegExp.$1.length));
			  }
			  for(let k in o) {
			    if(new RegExp("("+ k +")").test(format)){
			      format = format.replace(RegExp.$1, (RegExp.$1.length==1) ? (o[k]) : (("00"+ o[k]).substr((""+ o[k]).length)));
			    }
			  }
			  return format;
		},
	},
}

// 终端分组
export const deviceGroup = {
	data() {
	    return {
			deviceGroupVisble: false, //显示设备分组弹窗
	    }
	},
	methods: {
		// 显示设备分组弹窗
		showgroup(){
			this.deviceGroupVisble = true
		},
	},
}

// 4g信号格式化
export const signalFormatter = {
	methods: {
		// 显示设备分组弹窗
		signalFormat(signalQuality, status){
			const graySpan = () => {
				let span = ``
				for( let i = 0; i < 5; i++){
					span = span + `<span class="item"></span>`
				}
				return `${span}`
			}
			if(!signalQuality || status == 0){
				return graySpan()
			}else{
				const { rsrp } = JSON.parse(signalQuality)
				let index = 0
				if(rsrp == 0){
					return ''
				}else if(rsrp >= -90){
					index = 5
				}else if(rsrp >= -100){
					index = 4
				}else if(rsrp >= -115){
					index = 3
				}else{
					index = 1
				}
				let span = ``
				let className = ' blue'
				if(index < 3) className = ' red'
				for( let i = 0; i < 5; i++){
					let nameItem = ''
					if(i < index) nameItem = className
					span = span + `<span class="item${nameItem}"></span>`
				}
				return `${span}`
			}
		},
	},
}

//获取当前时分秒
export const getTimeMin = {
	data() {
	    return {
			deviceGroupVisble: false, //显示设备分组弹窗
	    }
	},
	methods: {
		// 显示设备分组弹窗
		getTimes(){
			const date = new Date()
			return `${this.timesFormat(date.getHours())}${this.timesFormat(date.getMinutes())}${this.timesFormat(date.getSeconds())}`
		},
		timesFormat(val){
			if(val < 10){
				return `0${val}`
			}else{
				return val
			}
		}
	},
}

//获取4g信号记录
export const signalRecords = {
	data() {
	    return {
			signalRecordVisle: false, //显示4g信号弹窗
			tableLoading: false,
			signalList: [],
	    }
	},
	watch: {
		signalRecordVisle(val){
			if(val){
				this.$nextTick(() => {
					this.onQuerySignalRecord()
				})
			}
		}
	},
	methods: {
		pageChange(pageNum){
			this.pageNum = pageNum
			this.getSignalRecord(pageNum)
		},
		onQuerySignalRecord(){
			this.pageNum = 1
			this.$refs.pagination.pageNum = 1
			this.getSignalRecord(this.pageNum)
		},
		async getSignalRecord(pageNum){
			try {
				this.tableLoading = true
				const { data: { pageList, total, adminRole } } = await signalRecord({
					pageNum,
					pageSize: this.pageSize,
					id: this.windowData.id
				})
				this.signalList = pageList
				this.pageTotal = total
				setTimeout(() => { this.tableLoading = false }, 300)
			} catch (error) {
				setTimeout(() => { this.tableLoading = false }, 300)
				error && console.error(error)
			}
		}
	},
}

//发送指令
export const commandControls = {
	data() {
	    return {
			downloading: false, //正在下载文件
			logList: [],
			logVisble: false, //显示设备日志
			logLoading: false, //日志加载中
	    }
	},
	watch: {
		logVisble(val){
			if(!val){
				this.logLoading = false
				this.downloading = false
			}
		}
	},
	methods: {
		// 关闭连接
		toCloseEventSource(){
			if(this.source){
				this.source.close()
				closeEventSource()
			}
		},
		downloadFile(fileName, content) {
			const blob = this.base64ToBlob(content); // new Blob([content]);
			if (window.navigator.msSaveOrOpenBlob) {
				navigator.msSaveBlob(blob, fileName);
			} else {
				const link = document.createElement('a');
				link.href = window.URL.createObjectURL(blob);
				link.download = fileName;
		
				//此写法兼容可火狐浏览器
				document.body.appendChild(link);
				const evt = document.createEvent("MouseEvents");
				evt.initEvent("click", false, false);
				link.dispatchEvent(evt);
				document.body.removeChild(link);
			}
		},
		base64ToBlob(code) {
			const raw = window.atob(code);
			const rawLength = raw.length;
			const uInt8Array = new Uint8Array(rawLength);
			for (let i = 0; i < rawLength; ++i) {
				uInt8Array[i] = raw.charCodeAt(i);
			}
			return new Blob([uInt8Array], {
				type: 'application/octet-stream'
			});
		},
		toFormatSD(){
			this.$elconfirm(`格式化SD卡将清空SD卡内的所有文件，确认要格式化SD卡吗？`, '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'error',
			}).then(async () => {
				this.toCommandControl(8)
			}).catch(() => {
				Message.info('已取消')
			})
		},
		// 设备发送指令/格式化sd卡，获取日志，下载日志
		async toCommandControl(commandType, row){
			const { id, physicalAddress } = this.windowData
			try {
				let pramas = {
					id, physicalAddress, commandType: commandType
				}
				if(commandType == 9){
					this.logLoading = true
				}else if(commandType == 10){
					this.downloading = true
					pramas.fileName = row.fileName
				}
				const { code, message } = await commandControl(pramas)
				if (code === 200) {
					Message.success(message)
				}else{
					this.logLoading = false
					this.downloading = false
				}
			} catch (error) {
				this.logLoading = false
				this.downloading = false
				error && console.error(error)
			}
		},
	},
}
//下载
export const comDownload = {
	data() {
	    return {
	    }
	},
	methods: {
		// 下载
		comDownload(res){
			const {data, headers} = res
			const blob = new Blob([data], { type: 'application/vnd.ms-excel' })
			const a = document.createElement('a')
			const src = window.URL.createObjectURL(blob)
			a.href = src
			a.download = decodeURIComponent(headers['content-disposition'].split(';')[1].split('=')[1])
			a.click()
			window.URL.revokeObjectURL(src)
		},
	},
}
//录音
export const comRecording = {
	data() {
	    return {
			RecordingAudioVisble: false,
			recordingStatus: -1, //当前录音状态，-1不能使用录音功能，0未开始录音，1录音中,2录音结束
			blob: null, //录音文件
			uploadAudioVisible: false,
			uploadAudioForm: { //创建 / 修改表单文本表单
			  level: 0,
			  name: '',
			  typeId: null,
			  duration: 0,
			  audioFileList: []
			},
			audioFormData: {},
			percentageStatus: 0,
			fileStorageForm: {},
			cos: null,
			ajaxing: false,
			result: [],
			starting: false,
	    }
	},
	watch:{
		uploadAudioVisible(val){
			if(!val){
				this.resetUploadData()
			}
		},
	},
	methods: {
		resetUploadData(){
			this.percentageStatus = 0
			this.result = []
			this.audioFormData = {}
			this.recordInit()
		},
		getFile(){
			const {blob, uploadAudioForm:{name}} = this
			const file = new File([blob], `${name}.wav`, { type: 'audio/wav' })
			this.uploadAudioForm.audioFileList = [file]
			return file
		},
		async toUploadAudioSubmit(resList, notCos){
			// notCos为true的时候不进行腾讯云存储判断
			this.result = resList
			if(notCos){
				this.uploadAudioServe()
			}else{
				const { code, message, data } = await cosTempToken()
				if(code == 200){
					if(data && data.bucketName){
						this.fileStorageForm = data
						this.cosUpload()
					}else{
						this.uploadAudioServe()
					}
				}else{
					this.ajaxing = false
				}
			}
		},
		//音频文件本地服务器上传
		async uploadAudioServe(){
			this.audioFormData = new FormData()
			const { level, typeId } = this.uploadAudioForm
			this.uploadAudioForm.audioFileList.forEach(audioFile => {
			  this.audioFormData.append('files', audioFile)
			})
			this.audioFormData.append('level', level)
			this.audioFormData.append('typeId', typeId)
			try {
			  const { code, message, data } = await uploadMedia(this.audioFormData, ({ loaded, total }) => {
			    this.percentageStatus = parseInt((loaded / total) * 100)
			  })
			  
			  if (code === 200) {
				this.getMediaFileList(this.pageNum)
				this.ajaxing = false
			    this.uploadAudioVisible = false
			  }else{
				this.ajaxing = false
			  }
			} catch (error) {
			  this.ajaxing = false
			  error && console.log(error)
			}
		},
		//腾讯云cos上传
		async cosUpload(){
			let files = await this.getaudioFileList()
			this.cos.uploadFiles({
				files,
				SliceSize: 1024 * 1024 * 50,
				onProgress: (progressData) => {
					const { loaded, total } = progressData
					this.percentageStatus = parseInt((loaded / total) * 100)
				},
				// onFileFinish: (err, data, options) => {
				// 	console.log(options.Key + '上传' + (err ? '失败' : '完成'));
				// }
			}, (err, data) => {
				// console.log(111, err)
				// console.log(222, data)
				if(err){
					this.ajaxing = false
					this.$message.error(err)
					this.uploadAudioVisible = false
				}else{
					this.setList(data.files)
				}
			})
		},
		//设置上传后返回的数据
		async setList(list){
			let item = []
			for(let i in list){
				if(list[i].data) item.push(list[i])
			}
			if(item.length){
				const { level, typeId } = this.uploadAudioForm
				//newUploadMedia
				const { cdnUrl } = this.fileStorageForm
				const files = item.map((v, i) => {
					let location = v.data.Location
					if(cdnUrl){
						location = `${cdnUrl}${location.split('.com')[1]}`
					}
					const {result} = this
					const temp = result.find( vv => vv.fileName == v.options.name)
					return {
						fileName: v.options.name,
						time: v.options.time,
						path: `https://${location}`,
						md5: temp.md5,
						duration: v.options.duration,
					}
				})
				try{
					const { code, message, data } = await newUploadMedia({level, typeId, files})
					if(code == 200){
						this.ajaxing = false
						this.getMediaFileList(this.pageNum)
						this.$message.success(message)
						this.uploadAudioVisible = false
					}else{
						this.ajaxing = false
						this.uploadAudioVisible = false
					}
				}catch (error) {
				  this.ajaxing = false
				  error?.status && console.error(error?.status)
				}
			}else{
				this.ajaxing = false
				this.$message.error(list[0].error.message)
				this.uploadAudioVisible = false
			}
		},
		//秒转化为时分秒格式
		timeFromat(val){
			if(!val){
				return ''
			}else{
				let hour = this.numFromat(parseInt(val / 3600))
				let hourYu = this.numFromat(val % 3600)
				let min = this.numFromat(parseInt(hourYu / 60))
				let sec = this.numFromat(hourYu % 60)
				return hour + ':' + min + ':' + sec
			}
		},
		numFromat(val){
			return val >= 10 ? val : '0'+ val
		},
		getaudioFileList(){
			var promises = this.uploadAudioForm.audioFileList.map(async (v, i) => {
				const {duration} = this.uploadAudioForm
				const time = this.timeFromat(parseInt(duration / 1000))
				const splittext = v.name.split('.')
				const zhui = splittext[splittext.length - 1]
				const { bucketName, regionName } = this.fileStorageForm
				const item = {
					Bucket: bucketName,
					Region: regionName,
					Key: `voice/${this.account}/${new Date().getTime()}${i}.${zhui}`,
					Body: v,
					name: v.name,
					time: time,
					duration: duration,
					onTaskReady: (taskId) => {}
				}
				return item
			})
			return Promise.all(promises).then((list)=>{
				return list
			})
		},
		recordInit(){
			this.uploadAudioForm = {
				level: 0,
				name: '',
				typeId: null,
				duration: 0,
				audioFileList: []
			}
			this.blob = null
			this.recordingStatus = 0
		},
		toReRecord(){
			this.uploadAudioVisible = false
			this.$nextTick(() => {
				this.RecordingAudioVisble = true
			})
		},
		startRecord(){
			this.$refs.RecordingAudioRef.startRecord()
		},
		stopRecord(){
			this.$refs.RecordingAudioRef.stopRecord(({blob,duration}) => {
				if(blob){
					this.blob = blob
					this.uploadAudioVisible = true
					this.uploadAudioForm.name = new Date().getTime()
					this.$nextTick(() => {
						this.RecordingAudioVisble = false
						let audioURL = URL.createObjectURL(blob)
						this.$refs.audioPlayerRef.$refs.audioRef.src = audioURL
						this.uploadAudioForm.duration = parseInt(duration * 1000)
					})
				}
			})
		},
		setRecordingStatus(data){
			this.recordingStatus = data
		},
		setStarting(data){
			this.starting = data
		}
	},
}

// 实时预览时长统计
export const viewDuration = {
	data() {
	    return {
			durationTimer: null, 
			viewDuration: 0,
			durationAll: 0,
			needCheck: false,
			refName: '',
	    }
	},
	methods: {
		startTimer(){
			if(this.durationTimer){
				return false
			}
			this.durationTimer = setInterval(() => {
				if(this.viewDuration < this.durationAll){
					this.viewDuration++
				}else{
					clearInterval(this.durationTimer)
					this.closeView()
					this.clearTimer()
				}
			}, 1000)
		},
		pauseTimer(){
			clearInterval(this.durationTimer)
		},
		clearTimer(){
			this.viewDuration = 0
			clearInterval(this.durationTimer)
			this.$nextTick(() => {
				this.durationTimer = null
			})
		},
		closeView(){
			const {refName} = this
			if(refName){
				if(refName.indexOf('webrtc') > -1){
					this.$refs[refName]?.closeConnect()
				}else{
					this.$refs[refName]?.destroy()
				}
			}else{
				if(this.$refs?.jessibuca){
					this.$refs?.jessibuca.destroy()
				}else{
					this.$refs?.webrtc.closeConnect()
				}
			}
		},
	},
}
// 当前是否支持获取媒体设备
export const checkSurpport = {
	data() {
	    return {
			unsurpport: false,
			isHttps: location.protocol == 'https' || location.hostname == 'localhost',
	    }
	},
	computed: {
		fwUrl(){
			return this.$store.state.publicOptions.platformInfo.accessAddress
		}
	},
	methods: {
		getDevicesInfos(){
			try{
				navigator.mediaDevices.enumerateDevices().then(devicesInfos => {
					let deviceList = devicesInfos.filter(v => v.kind == 'audioinput')
					if(!deviceList.length){
						this.unsurpport = true
					}
				}).catch(error => {
					console.log('error', error)
					this.unsurpport = true
				})
			}catch(e){
				console.log(e)
				this.unsurpport = true
			}
		},
	},
}