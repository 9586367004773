import axios from 'axios'
import { cancelBus } from '@/configs'
import { secondaryPackagingRequest } from '@/request'
//获取角色列表
export const getRoleList = params => secondaryPackagingRequest({
    url: '/sys/role/list',
    params,
    isLoading: false,
    needToken: true,
    cancelToken: new axios.CancelToken(function executor(c) {
        cancelBus.cancelRoleList = c
    })
})
//获取所有的角色
export const roleAll = () => secondaryPackagingRequest({
    url: '/sys/role/all',
    isLoading: false,
    needToken: true
})
//新增角色
export const roleSave = data => secondaryPackagingRequest({
    url: '/sys/role/save',
    method: 'POST',
    headers: {
        'Content-Type': 'application/json'
    },
    data,
    isLoading: false,
    needToken: true
})
//修改角色
export const roleUpdate = data => secondaryPackagingRequest({
    url: '/sys/role/update',
    method: 'POST',
    headers: {
        'Content-Type': 'application/json'
    },
    data,
    isLoading: false,
    needToken: true
})
//删除角色
export const roleDelete = params => secondaryPackagingRequest({
    url: '/sys/role/delete',
    method: 'POST',
    params,
    isLoading: false,
    needToken: true
})
//启用角色
export const roleEnable = params => secondaryPackagingRequest({
    url: '/sys/user/enable',
    method: 'POST',
    headers: {
        'Content-Type': 'application/json'
    },
    params,
    isLoading: false,
    needToken: true
})
//禁用角色
export const roleDisable = params => secondaryPackagingRequest({
    url: '/sys/user/disable',
    method: 'POST',
    headers: {
        'Content-Type': 'application/json'
    },
    params,
    isLoading: false,
    needToken: true
})
//禁用角色
export const menuList = params => secondaryPackagingRequest({
    url: '/auth/menuList',
    params,
    isLoading: false,
    needToken: true
})