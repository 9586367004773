import { secondaryPackagingRequest } from '@/request'
//消息发布
export const messageSave = data => secondaryPackagingRequest({
    url: '/sys/message/save',
    method: 'POST',
    headers: {
        'Content-Type': 'application/json'
    },
    data,
    isLoading: false,
    needToken: true
})
//发布记录
export const messageList = params => secondaryPackagingRequest({
    url: '/sys/message/list',
    params,
    isLoading: false,
    needToken: true
})
//获取消息记录详情
export const messageInfo = params => secondaryPackagingRequest({
    url: '/sys/message/info',
    params,
    isLoading: false,
    needToken: true
})
//删除记录
export const messageDelete = params => secondaryPackagingRequest({
    url: '/sys/message/delete',
    method: 'POST',
    params,
    isLoading: false,
    needToken: true
})
//主页-获取用户未读消息列表
export const messageListOfUnRead = () => secondaryPackagingRequest({
    url: '/sys/message/listOfUnRead',
    isLoading: false,
    needToken: true
})
//查看消息(置为已读)
export const messageReadMsg = params => secondaryPackagingRequest({
    url: '/sys/message/readMsg',
    method: 'POST',
    params,
    isLoading: false,
    needToken: true
})
//获取听力备份开关
export const listeningBackupQuery = () => secondaryPackagingRequest({
    url: '/sm/realTimeBroadcast/listeningBackup/query',
    isLoading: false,
    needToken: true
})
// 更新听力备份开关
export const listeningBackupUpdate = params => secondaryPackagingRequest({
    url: '/sm/realTimeBroadcast/listeningBackup/update',
    method: 'POST',
    headers: {
        'Content-Type': 'application/json'
    },
    params,
    isLoading: false,
    needToken: true
})
export const msgConfirm = data => secondaryPackagingRequest({
    url: '/sys/group/msg/confirm',
    method: 'POST',
    headers: {
        'Content-Type': 'application/json'
    },
    data,
    isLoading: false,
    needToken: true
})